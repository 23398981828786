import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

/* Styled Components */
import {
  StyledAccordionHeader,
  StyledAccordionTitle,
  StyledDetailsListItem
} from '../../paymentsDetails/templateTwo/paymentsDetails.styled';
import {StyledFlexContainerDynamic} from '../../paymentBonusesList/paymentBonusesListWeb.styled';
import {
  StyledItemLabelTemplateTwo,
  StyledItemValueTemplateTwo
} from '../../paymentBalances/templateTwo/paymentBalances.styled';

function PaymentLimitsTemplateTwo({
  currency,
  playerCurrentLimit,
  playerLimit
}) {
  const {t} = useTranslation();

  return (
    <>
      <StyledAccordionHeader>
        <StyledAccordionTitle>
          {t(('limits')?.toLowerCase())}
        </StyledAccordionTitle>
      </StyledAccordionHeader>
      <StyledFlexContainerDynamic
        as="ul"
      >
        {(playerCurrentLimit?.dailyLimit || playerLimit?.dailyLimit) && (
        <StyledDetailsListItem>
          <StyledItemLabelTemplateTwo>{`${t(('daily')?.toLowerCase())} :`}</StyledItemLabelTemplateTwo>
          <StyledItemValueTemplateTwo>{`${playerCurrentLimit?.dailyLimit} ${currency} of ${playerLimit?.dailyLimit} ${currency}`}</StyledItemValueTemplateTwo>
        </StyledDetailsListItem>
        )}
        {(playerCurrentLimit?.weeklyLimit || playerLimit?.weeklyLimit) && (
        <StyledDetailsListItem>
          <StyledItemLabelTemplateTwo>{`${t(('weekly')?.toLowerCase())} :`}</StyledItemLabelTemplateTwo>
          <StyledItemValueTemplateTwo>{`${playerCurrentLimit?.weeklyLimit} ${currency} of ${playerLimit?.weeklyLimit} ${currency}`}</StyledItemValueTemplateTwo>
        </StyledDetailsListItem>
        )}
        {(playerCurrentLimit?.monthlyLimit || playerLimit?.monthlyLimit) && (
        <StyledDetailsListItem>
          <StyledItemLabelTemplateTwo>{`${t(('monthly')?.toLowerCase())} :`}</StyledItemLabelTemplateTwo>
          <StyledItemValueTemplateTwo>{`${playerCurrentLimit?.monthlyLimit} ${currency} of ${playerLimit?.monthlyLimit} ${currency}`}</StyledItemValueTemplateTwo>
        </StyledDetailsListItem>
        )}
        {(playerCurrentLimit?.yearlyLimit || playerLimit?.yearlyLimit) && (
        <StyledDetailsListItem>
          <StyledItemLabelTemplateTwo>{`${t(('yearly')?.toLowerCase())} :`}</StyledItemLabelTemplateTwo>
          <StyledItemValueTemplateTwo>{`${playerCurrentLimit?.yearlyLimit} ${currency} of ${playerLimit?.yearlyLimit} ${currency}`}</StyledItemValueTemplateTwo>
        </StyledDetailsListItem>
        )}
      </StyledFlexContainerDynamic>
    </>
  );
}

PaymentLimitsTemplateTwo.propTypes = {
  currency: PropTypes.string,
  playerCurrentLimit: PropTypes.object,
  playerLimit: PropTypes.object
};
PaymentLimitsTemplateTwo.defaultProps = {
  currency: '',
  playerCurrentLimit: null,
  playerLimit: null
};
export default PaymentLimitsTemplateTwo;
