import styled, {css} from 'styled-components';
import {flexContainer, fontStyle, getBorderRadius} from '../../../../helpers/styled';
import {StyledTabWebTwoWrapper} from '../navigationTabsWebTwo/navigationTabsWebTwo.styled';

const StyledTabWebThreeWrapper = styled(StyledTabWebTwoWrapper)`
  justify-content: flex-start;
  padding: 0;
  border-bottom: ${({theme}) => (theme.navigationVersionThreeGeneralHasBorder
    ? `${theme.navigationVersionThreeGeneralBorderWidth}px solid ${theme.navigationVersionThreeGeneralBorderColor}`
    : '0')};
  background: ${({theme}) => theme.navigationVersionThreeGeneralBGColor};
`;

const StyledTabThreeButton = styled.button`
  position: relative;
  height: 100%;
  ${fontStyle('semiBold')};
  margin: 0 24px;
  border: 0;
  background: unset;
  
  ${(props) => props.isMobile && css`
    flex: 1;
    margin: 0;
  `}
`;

const StyledTabButtonContent = styled.div`
  position: relative;
  height: inherit;
  ${flexContainer()};
  display: inline-flex;
  grid-gap: 8px;
  line-height: 52px;
  color: ${({theme}) => theme.navigationVersionThreeGeneralTextColor};
  
  svg path {
    fill: ${({theme}) => theme.navigationVersionThreeGeneralIconColor};
  }

  ${(props) => props.activeTab && css`
    color: ${({theme}) => theme.navigationVersionThreeSelectedTextColor};

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: ${() => getBorderRadius(props, 'navigationVersionThreeSelectedSelector')}; // Handles border-radius for all corners
      background: ${({theme}) => theme.navigationVersionThreeSelectedBGColor};
    }
    
    svg path {
      fill: ${({theme}) => theme.navigationVersionThreeSelectedIconColor};
    }
  `}
`;

export {
  StyledTabWebThreeWrapper,
  StyledTabThreeButton,
  StyledTabButtonContent
};
