import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {DeleteIcon, DocsIconMedium, UploadedPdfPlaceholder} from '../../../assets/icons';

// styled
import {StyledButtonWithoutBg} from '../../../styledComponents/button/button.styled';

function UploadedFileFieldForTemplateTwo({
  htmlFor,
  isMobile,
  fileName,
  fileIndex,
  fileFormat,
  filePreviewUrl,
  deleteFileHandler
}) {
  const {t} = useTranslation();

  const onDeleteHandler = () => {
    deleteFileHandler((prev) => ({...prev, [fileIndex]: null}));
  };

  return (
    <label
      style={{
        width: isMobile ? '172px' : '198px',
        height: '82px',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
      htmlFor={htmlFor}
    >
      {filePreviewUrl && fileName ? (
        <>
          <div
            style={{
              width: '110px',
              height: '66px',
              borderRadius: '5px',
              overflow: 'hidden'
            }}
          >
            {
              fileFormat === 'pdf' ? (
                <UploadedPdfPlaceholder />
              ) : (
                <img
                  src={filePreviewUrl}
                  alt="fileName"
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                />
              )
            }
          </div>
          <div
            style={{
              position: 'absolute',
              right: '12px',
              top: '12px'
            }}
          >
            <StyledButtonWithoutBg type="button" onClick={onDeleteHandler}>
              <DeleteIcon />
            </StyledButtonWithoutBg>
          </div>
        </>
      ) : (
        <>
          <DocsIconMedium />
          <p style={{color: 'white'}}>{`${t('document')} ${fileIndex + 1}`}</p>
        </>
      )}
    </label>
  );
}

UploadedFileFieldForTemplateTwo.propTypes = {
  fileName: PropTypes.string,
  fileFormat: PropTypes.string,
  filePreviewUrl: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  htmlFor: PropTypes.string.isRequired,
  fileIndex: PropTypes.number.isRequired,
  deleteFileHandler: PropTypes.func.isRequired
};

UploadedFileFieldForTemplateTwo.defaultProps = {
  fileName: undefined,
  fileFormat: undefined,
  filePreviewUrl: undefined
};

export default memo(UploadedFileFieldForTemplateTwo);
