import styled, {css} from 'styled-components';
import {
  disable, flexColumnContainer, flexContainer, fontStyle, getBorderRadius
} from '../../helpers/styled';
import {StyledCardHeaderWrapper} from '../../components/paymentsForm/templateOne/web/formWeb.styled';

const StyledSelectWrapper = styled.div`
  width: 100%;
  ${flexContainer()};
  grid-gap: 16px;
`;

const StyledSelect = styled.div`
    width: inherit;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;
    padding: 0 12px 0 6px;
    border: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
    border-top: ${({theme}) => theme.inputBorderTop};
    border-bottom: ${({theme}) => theme.inputBorderBottom};
    border-left: ${({theme}) => theme.inputBorderLeft};
    border-right: ${({theme}) => theme.inputBorderRight};
    border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners
    color: ${({theme}) => theme.inputTextColor};
  
  ${(props) => props.hasNoCardIcon && css`
    padding-left: 16px;
  `}
  
  ${(props) => props.isNew && css`
    justify-content: center;
  `}
`;

const StyledSelectText = styled.span`
  ${fontStyle('regular')};
  flex: 1;
`;
const StyledSelectOptionsContainer = styled.div`
  width: 66.5%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  ${flexColumnContainer()};
  //padding: 16px;
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
  border-left: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  z-index: 9999;
  }
`;

const StyledSelectOptionsWrapper = styled.ul`
  height: 100%;
  ${flexColumnContainer()};
  grid-gap: 24px;
  padding: 0 16px 16px;
  overflow: auto;
`;

const StyledSelectOptionsHeader = styled(StyledCardHeaderWrapper)`
  margin-bottom: 16px;
  padding: 16px 12px;
`;

const StyledSelectOptionMobileWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  
  &.withoutPaddingLeft {
    padding-left: 0;
  }
`;

const StyledSelectOption = styled.li`
  height: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  padding: 0 12px 0 6px;
  justify-content: ${({isNew}) => (isNew ? 'center' : 'space-between')};
  border: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  border-top: ${({theme}) => theme.inputBorderTop};
  border-bottom: ${({theme}) => theme.inputBorderBottom};
  border-left: ${({theme}) => theme.inputBorderLeft};
  border-right: ${({theme}) => theme.inputBorderRight};
  border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners
  color: ${({theme}) => theme.inputTextColor};
  cursor: pointer;

  &:hover {
    border: 1px solid ${({theme}) => theme.mainButtonBackgroundColor};
  }

  ${(props) => props.isSelected && css`
    border: 1px solid ${({theme}) => theme.mainButtonBackgroundColor};
  `}

  ${(props) => props.hasNoCardIcon && css`
    padding-left: 16px;
  `}
  
  ${(props) => props.isNew && css`
    height: 24px;
    min-height: 24px;
    padding: 0;
    border-radius: 0;
    ${fontStyle('semiBold')};
    font-size: ${() => props.theme.typographyFontSizeMiddle};
    color: ${() => props.theme.textButtonBackgroundColor};
    border: 0;

    &:hover {
      border: 0;
    }
  `}

  ${(props) => (props.isNew && props.isSelected) && css`
    ${disable()};
  `}
`;

const StyledSelectAccountStatusLabel = styled.label`
  height: 16px;
  padding: 0 8px;
  ${fontStyle('semiBold')};
  font-size: 10px;
  line-height: 16px;
  text-transform: capitalize;
  border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners
  color: ${({color}) => color};
  background-color: ${({backgroundColor}) => backgroundColor};
`;

const StyledCardInfoWrapper = styled.div`
  ${flexContainer()};
  grid-gap: 8px;
`;

const StyledCardIconWrapper = styled.span`
  ${flexContainer()};
  width: 50px;
  height: 24px;
  border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners  background-color:  
  background-color:  ${({theme}) => theme.generalColorNeutralMinimum};
  
  svg {
    width: 100%;
    height: 100%;
  }

  ${(props) => props.hasMarginLeft && css`
    margin-left: 8px;
  `}
`;

const StyledIconsContainer = styled(StyledCardIconWrapper)`
  width: 24px;
  height: 24px;
  border-radius: unset;
  background-color: unset;
`;

const StyledCardPositionIconWrapper = styled(StyledCardIconWrapper)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;

const StyledSelectOptionsItem = styled.div`
  height: 40px;
  ${flexContainer({justifyContent: 'space-between', gap: '12px'})};
  padding: 0 8px;
  border-radius: ${(props) => getBorderRadius(props, 'mainButton')};
  ${fontStyle('semiBold')};
  background-color: ${({theme}) => theme.surface.surfaceSecondary};
`;

export {
  StyledSelectWrapper,
  StyledSelect,
  StyledSelectOptionsContainer,
  StyledSelectOptionsWrapper,
  StyledSelectOptionsHeader,
  StyledSelectOption,
  StyledSelectText,
  StyledCardIconWrapper,
  StyledCardInfoWrapper,
  StyledSelectOptionMobileWrapper,
  StyledSelectAccountStatusLabel,
  StyledCardPositionIconWrapper,
  StyledSelectOptionsItem,
  StyledIconsContainer
};
