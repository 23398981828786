import styled from 'styled-components';
import {flexContainer} from '../../../../helpers/styled';

const StyledPaymentDropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const StyledPaymentCardsList = styled.div`
  position: absolute;
  top: 86px;
  width: 100%;
  ${flexContainer({
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '16px'
  })};
  border: 1px solid ${({theme}) => theme.border.border};
  border-radius: 8px;
  background-color: ${({theme}) => theme.surface.surfaceTertiary};
  z-index: ${({theme}) => theme.zIndex999};
  overflow: hidden;
`;

const StyledCardGroupsWrapper = styled.div`
  max-height: 376px;
  overflow: auto;
  padding: 8px;
`;

export default StyledPaymentDropdownWrapper;
export {
  StyledPaymentCardsList,
  StyledCardGroupsWrapper
};
