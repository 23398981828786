import styled from 'styled-components';

const StyledFilterWrapper = styled.div`
  display: flex;
  grid-gap: 16px;
  margin-bottom: 24px;
  max-width: 260px;
  
  div {
    width: 100%;
  }
`;

export {
  StyledFilterWrapper
};
