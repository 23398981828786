import React, {
  memo, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {PaymentFormCallbacksContext} from '../../contexts/paymentFormCallbacksContext';

function ConektaCardPaymentForm({checkoutRequestId, closePaymentHandler, alertContext}) {
  const {t} = useTranslation();

  const {setIsLoading} = useContext(PaymentFormCallbacksContext);

  const initialize = () => {
    const options = {
      backgroundMode: 'lightMode',
      colorPrimary: '#081133',
      colorText: '#585987',
      colorLabel: '#585987',
      inputType: 'minimalMode'
    };

    const config = {
      locale: 'es',
      publicKey: 'key_LXXMLUATNCQJci51aq4Ve2D',
      targetIFrame: '#example',
      checkoutRequestId
    };

    const callbacks = {
      onGetInfoSuccess(loadingTime) {
        // console.log('loading time en milisegundos', loadingTime.initLoadTime);
        setIsLoading(false);
      },
      onFinalizePayment(order) {
        // console.log('success: ', JSON.stringify(order));
        alertContext.success(t('transactionSuccessfullyAccepted'.toLowerCase()));
        closePaymentHandler();
      },
      onErrorPayment(error) {
        // console.log('error en pago: ', error);
        alertContext.error(error.message);
        closePaymentHandler();
      }
    };

    if (window.ConektaCheckoutComponents) {
      try {
        window.ConektaCheckoutComponents.Integration({
          config,
          callbacks,
          options
        });
      } catch (error) {
        console.error('Error initializing Conekta:', error);
      }
    }
  };

  const loadScript = () => {
    if (!document.querySelector('script[src="https://pay.conekta.com/v1.0/js/conekta-checkout.min.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js';
      script.crossOrigin = 'anonymous';
      script.onload = initialize;
      document.body.appendChild(script);
    } else {
      initialize();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadScript();

    return () => {
      if (window.ConektaCheckoutComponents?.destroy) {
        window.ConektaCheckoutComponents.destroy();
      }
    };
  }, []);

  return (
    <div id="example" style={{height: '500px'}} />
  );
}

ConektaCardPaymentForm.propTypes = {
  checkoutRequestId: PropTypes.string.isRequired,
  closePaymentHandler: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired
};

export default memo(ConektaCardPaymentForm);
