import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

/**
 * Styled Components
 */
import StyledBannerWrapper, {
  StyledCardInfoDescription,
  StyledBannerInfoTitle,
  StyledBannerInfoTitleKey,
  StyledBannerInfoWrapper,
  StyledBannerImageWrapper
} from './bonusBanner.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledFlexContainerColumn} from '../paymentsForm/templateOne/web/formWeb.styled';

function BonusBanner({
  bannerUrl,
  goToBonusHandler
}) {
  const {t} = useTranslation();

  return (
    <StyledBannerWrapper onClick={goToBonusHandler}>
      <StyledBannerImageWrapper>
        <img src={bannerUrl} alt="banner" />
      </StyledBannerImageWrapper>
      <StyledBannerInfoWrapper>
        <StyledFlexContainerColumn gap="2px">
          <StyledBannerInfoTitle>
            {t(('seeAll').toLowerCase())}
            {' '}
            <StyledBannerInfoTitleKey>
              {t(('bonuses').toLowerCase())}
            </StyledBannerInfoTitleKey>
          </StyledBannerInfoTitle>
          <StyledCardInfoDescription>
            Deposit Bonuses Available
          </StyledCardInfoDescription>
        </StyledFlexContainerColumn>
        <StyledIconSmall icon="arrowRight" fontSize="11px" />
      </StyledBannerInfoWrapper>
    </StyledBannerWrapper>
  );
}

BonusBanner.propTypes = {
  bannerUrl: PropTypes.string,
  goToBonusHandler: PropTypes.func.isRequired
};

BonusBanner.defaultProps = {
  bannerUrl: null
};

export default memo(BonusBanner);
