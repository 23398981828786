import styled from 'styled-components';
import {
  flexColumnContainer,
  flexContainer,
  fontStyle
} from '../../../../helpers/styled';

const StyledBonusInfoWrapper = styled.section`
  display: flex;
  align-items: baseline;
  gap: 40px;
  margin-top: 16px;
  padding-bottom: 16px;
`;

const StyledBonusInfoContent = styled.div`
  flex: 1;
`;

const StyledBonusInfoDescription = styled.div`
  border-bottom: 1px dashed ${({theme}) => theme.border.borderSecondary};
  ${fontStyle('regular')};
  color: ${({theme}) => theme.text.text};

  ul {
    list-style-type: disc;
    padding-left: 16px;
  }
`;

const StyledBonusInfoList = styled.ul`
  ${flexColumnContainer()};
  gap: 12px;
  width: 304px;
  padding: 24px;
  border-radius: 12px;
  background-color: ${({theme}) => theme.surface.surfaceTertiary}
`;

const StyledBonusInfoListItem = styled.li`
  min-height: 30px;
  ${flexContainer()};
  justify-content: flex-start;
  gap: 12px;
  
  svg path {
    fill: ${({theme}) => theme.surface.surfaceIcon};
  }
`;

const StyledBonusInfoListItemKey = styled.h3`
  ${fontStyle('regular')};
  color: ${({theme}) => theme.text.textTertiary};
`;

const StyledBonusInfoListItemValue = styled.span`
  color: ${({theme}) => theme.text.textSecondary};
  margin-left: 8px;
  font-weight: ${({theme}) => theme.typographyFontWeightSemiBold};
`;

export default StyledBonusInfoWrapper;
export {
  StyledBonusInfoDescription,
  StyledBonusInfoList,
  StyledBonusInfoListItem,
  StyledBonusInfoListItemKey,
  StyledBonusInfoListItemValue,
  StyledBonusInfoContent
};
