import React, {
  memo, useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {PaymentBonusesContext} from '../../contexts/paymentBonusesContext';
import {findMinMaxAmountsForBonus, getCurrencySymbolOrCode} from '../../helpers/common';
import {AMOUNT_MESSAGE_STATUSES} from '../../constants/common';
import PaymentBonusesListMobile from '../paymentBonusesList/paymentBonusesListMobile';

/* Assets*/
import {BonusIcon, DeleteIcon, PromoCode} from '../../assets/icons';

/* Styled Components */
import {
  StyledButtonWithBorderTemplateTwo,
  StyledButtonWithoutBg
} from '../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledFlexContainerDynamic} from '../paymentBonusesList/paymentBonusesListWeb.styled';
import {
  StyledChooseBonusText,
  StyledSelectedBonusIconWrapper,
  StyledSelectedBonusInfoWrapper,
  StyledSelectedBonusWrapper,
  StyledTextButton
} from './paymentBonusField.styled';
import {
  StyledInput,
  StyledInputContainer,
  StyledInputError,
  StyledInputIconWrapper,
  StyledMessageText,
  StyledStatusMessageWrapper
} from '../../styledComponents/input/input.styled';
import {
  StyledCardFigcaptionDescriptionMobile,
  StyledCardFigcaptionTitleMobile
} from '../bonusesList/templateOne/mobile/bonusesListMobile.styled';

let promoCodeInputTimout = null;

function PaymentBonusField({
  isMobile,
  amount,
  currency,
  isCurrencySymbol,
  openPaymentFormBonusesForWeb
}) {
  const {t} = useTranslation();

  const {
    selectedBonusId,
    paymentBonuses,
    setSelectedBonusId
  } = useContext(PaymentBonusesContext);

  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [showInvalidPromoCodeError, setShowInvalidPromoCodeError] = useState(false);
  const [showAllBonuses, setShowAllBonuses] = useState(false);

  const selectedBonusConditions = !!selectedBonusId ? paymentBonuses.find(({id}) => id === selectedBonusId)?.conditions : null;
  const [minAmount, maxAmount] = !!selectedBonusConditions ? findMinMaxAmountsForBonus(selectedBonusConditions) : [];

  const [amountMessage, messageStatus] = useMemo(() => {
    if (amount === 0) {
      return [t(('correctamountmessage')?.toLowerCase()), AMOUNT_MESSAGE_STATUSES.DEFAULT];
    }
    if (amount < minAmount) {
      return [t(('lessamountmessage')?.toLowerCase()), AMOUNT_MESSAGE_STATUSES.ERROR];
    }
    if (amount > maxAmount) {
      return [t(('moreamountmessage')?.toLowerCase()), AMOUNT_MESSAGE_STATUSES.ERROR];
    }

    const {
      bonusAmount,
      bonusPercent
    } = selectedBonusConditions?.find(({
      minDeposit,
      maxDeposit
    }) => (amount >= minDeposit && amount <= maxDeposit)) || {};
    // eslint-disable-next-line no-mixed-operators
    return [`${t('recive')}: ${bonusAmount || (amount * bonusPercent / 100)}${getCurrencySymbolOrCode(currency, isCurrencySymbol)}`, AMOUNT_MESSAGE_STATUSES.SUCCESS];
  }, [amount, minAmount, maxAmount, selectedBonusConditions]);

  const {
    shortDescription,
    name
  } = useMemo(() => {
    if (selectedBonusId && paymentBonuses?.length > 0) {
      return paymentBonuses.find((bonus) => bonus.id === selectedBonusId);
    }
    return {};
  }, [selectedBonusId, paymentBonuses]);

  const promoCodeButton = (
    <StyledTextButton type="button" onClick={() => setShowPromoCodeInput(true)}>{t(('IHavePromoCode')?.toLowerCase())}</StyledTextButton>
  );

  const onPromoCodeInputHandler = (e) => {
    setPromoCode(e.target.value);
    if (showInvalidPromoCodeError) setShowInvalidPromoCodeError(false);
  };

  const onClosePromoCodeHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPromoCodeInput(false);
    setPromoCode('');
    if (showInvalidPromoCodeError) setShowInvalidPromoCodeError(false);
  };

  const onOpenAllBonusesHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isMobile) {
      setShowAllBonuses(true);
    } else if (typeof openPaymentFormBonusesForWeb === 'function') {
      openPaymentFormBonusesForWeb();
    }
  };

  const onCloseAllBonusesHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAllBonuses(false);
  }, []);

  const onSelectBonusHandler = useCallback((bonusId) => () => {
    setSelectedBonusId(bonusId);
  }, [setSelectedBonusId]);

  const onCancelBonusHandler = useCallback(() => {
    setSelectedBonusId(null);
  }, [setSelectedBonusId]);

  useEffect(() => () => {
    clearTimeout(promoCodeInputTimout);
  }, []);

  useEffect(() => {
    clearTimeout(promoCodeInputTimout);
    promoCodeInputTimout = setTimeout(() => {
      if (!!promoCode) {
        const numericPromoCode = Number(promoCode);
        if (paymentBonuses?.find((bonus) => bonus.id === numericPromoCode)) {
          setSelectedBonusId(numericPromoCode);
          setShowPromoCodeInput(false);
          setPromoCode('');
        } else {
          setShowInvalidPromoCodeError(true);
        }
      }
    }, 1000);
  }, [promoCode]);

  return paymentBonuses?.length > 0 ? (
    <StyledFlexContainerDynamic direction="column" gap="20px" mb="20px">
      {/* for first row */}
      {showPromoCodeInput ? (
        <div>
          <StyledFlexContainerDynamic gap="8px" hasDivider>
            <StyledInputContainer>
              <StyledInput
                type="number"
                value={promoCode}
                placeholder={t(('enterPromocode')?.toLowerCase())}
                onChange={onPromoCodeInputHandler}
              />
              <StyledInputIconWrapper>
                <PromoCode />
              </StyledInputIconWrapper>
            </StyledInputContainer>
            <StyledButtonWithoutBg type="button" onClick={onClosePromoCodeHandler}>
              <StyledIconSmall icon="close" fontSize="11px" />
            </StyledButtonWithoutBg>
          </StyledFlexContainerDynamic>
          {showInvalidPromoCodeError ? <StyledInputError>{t('invalidPromoCode')}</StyledInputError> : null}
        </div>
      ) : (
        <StyledFlexContainerDynamic
          justify="space-between"
          alignItem="center"
          gap="16px"
          hasDivider
        >
          {selectedBonusId ? (
            <StyledSelectedBonusWrapper>
              <StyledSelectedBonusIconWrapper>
                <img src="https://cdn-payments.apidigi.com/Cashire/images/bonusImage.svg" alt={name} />
              </StyledSelectedBonusIconWrapper>
              <StyledSelectedBonusInfoWrapper>
                <StyledCardFigcaptionTitleMobile>{name}</StyledCardFigcaptionTitleMobile>
                <StyledCardFigcaptionDescriptionMobile
                  dangerouslySetInnerHTML={{__html: shortDescription}}
                />
              </StyledSelectedBonusInfoWrapper>
              <StyledButtonWithoutBg type="button" onClick={() => setSelectedBonusId(null)}>
                <DeleteIcon />
              </StyledButtonWithoutBg>
            </StyledSelectedBonusWrapper>
          ) : promoCodeButton}
          <StyledFlexContainerDynamic
            justify="center"
            alignItem="center"
            gap="8px"
            flex="initial"
          >
            {selectedBonusId ? null : <StyledChooseBonusText>{t('chooseyourbonus')}</StyledChooseBonusText>}
            <StyledButtonWithBorderTemplateTwo
              type="button"
              onClick={onOpenAllBonusesHandler}
            >
              <BonusIcon />
            </StyledButtonWithBorderTemplateTwo>
          </StyledFlexContainerDynamic>
        </StyledFlexContainerDynamic>
      )}
      {/* for amount message */}
      {!!selectedBonusId && !!selectedBonusConditions ? (
        <StyledStatusMessageWrapper
          state={messageStatus}
          bonusField
        >
          <StyledMessageText bonusField>
            {amountMessage}
          </StyledMessageText>
        </StyledStatusMessageWrapper>
      ) : null}
      {!!selectedBonusId && !showPromoCodeInput ? promoCodeButton : null}
      {isMobile ? (
        <PaymentBonusesListMobile
          showInfo={showAllBonuses}
          bonusesList={paymentBonuses}
          selectedBonusId={selectedBonusId}
          onSelectBonusHandler={onSelectBonusHandler}
          onCancelBonusHandler={onCancelBonusHandler}
          onCloseListHandler={onCloseAllBonusesHandler}
        />
      ) : null}
    </StyledFlexContainerDynamic>
  ) : null;
}

PaymentBonusField.propTypes = {
  amount: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  openPaymentFormBonusesForWeb: PropTypes.func
};

PaymentBonusField.defaultProps = {
  openPaymentFormBonusesForWeb: null
};

export default memo(PaymentBonusField);
