import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

/* Styled Components */
import {
  StyledAccordionHeader,
  StyledAccordionTitle,
  StyledDetailsListItem
} from '../../paymentsDetails/templateTwo/paymentsDetails.styled';
import {StyledFlexContainerDynamic} from '../../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledItemLabelTemplateTwo, StyledItemValueTemplateTwo} from './paymentBalances.styled';

function PaymentBalancesTemplateTwo({
  currencyISO,
  usedBalance,
  usedBalanceFee,
  unUsedBalance,
  unUsedBalanceFee,
  withdrawalBalance
}) {
  const {t} = useTranslation();

  return (
    <>
      <StyledAccordionHeader>
        <StyledAccordionTitle>
          {t(('balances')?.toLowerCase())}
        </StyledAccordionTitle>
      </StyledAccordionHeader>
      <StyledFlexContainerDynamic
        as="ul"
        direction="column"
      >
        <StyledDetailsListItem>
          <StyledItemLabelTemplateTwo>{`${t(('usedBalance')?.toLowerCase())} / ${t(('usedBalanceFee').toLowerCase())}`}</StyledItemLabelTemplateTwo>
          <StyledItemValueTemplateTwo>{`${usedBalance} ${currencyISO} / ${usedBalanceFee}%`}</StyledItemValueTemplateTwo>
        </StyledDetailsListItem>
        <StyledDetailsListItem>
          <StyledItemLabelTemplateTwo>{`${t(('unUsedBalance')?.toLowerCase())} / ${t(('unUsedBalanceFee').toLowerCase())}`}</StyledItemLabelTemplateTwo>
          <StyledItemValueTemplateTwo>{`${unUsedBalance} ${currencyISO} / ${unUsedBalanceFee}%`}</StyledItemValueTemplateTwo>
        </StyledDetailsListItem>
        <StyledDetailsListItem>
          <StyledItemLabelTemplateTwo>{`${t(('withdrawableAmount')?.toLowerCase())}`}</StyledItemLabelTemplateTwo>
          <StyledItemValueTemplateTwo>{`${withdrawalBalance} ${currencyISO}`}</StyledItemValueTemplateTwo>
        </StyledDetailsListItem>
      </StyledFlexContainerDynamic>
    </>
  );
}

PaymentBalancesTemplateTwo.propTypes = {
  currencyISO: PropTypes.string,
  usedBalance: PropTypes.number,
  usedBalanceFee: PropTypes.number,
  unUsedBalance: PropTypes.number,
  unUsedBalanceFee: PropTypes.number,
  withdrawalBalance: PropTypes.number
};

PaymentBalancesTemplateTwo.defaultProps = {
  currencyISO: '',
  usedBalance: 0,
  usedBalanceFee: 0,
  unUsedBalance: 0,
  unUsedBalanceFee: 0,
  withdrawalBalance: 0
};

export default PaymentBalancesTemplateTwo;
