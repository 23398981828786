import styled, {css} from 'styled-components';

const StyledFormBonusWrapper = styled.section`
  width: ${(props) => (props?.fullWidth ? '100%' : '40%')};
  padding: 16px 8.5px;
  border-left: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
`;

const StyledFlexContainerDynamic = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.alignItem || 'stretch'};
  gap: ${(props) => props.gap || '0'};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  overflow: ${(props) => props.overflow || 'auto'};
  flex: ${(props) => (props.flex || 'auto')};
  margin-bottom: ${(props) => (props.mb || '0')};
  
  ${(props) => props.hasDivider && css`
    padding-top: 24px;
    border-top: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  `}
`;

export {
  StyledFlexContainerDynamic,
  StyledFormBonusWrapper
};
