import axios from 'axios';
import {computeSha256HashFromString} from '../helpers/common';

export const getDropdownListOptions = async({
  url,
  token,
  amount,
  siteId,
  userId,
  country,
  currency,
  isOffline,
  languageId,
  alertContext,
  paymentSystemId,
  platformPaymentSystemId
}) => {
  if (isOffline) {
    const concatenatedString = `${siteId}${platformPaymentSystemId}${userId}${languageId}${token}`;
    const inputHash = computeSha256HashFromString(concatenatedString);

    return axios
      .get(`${url}/${siteId}/${platformPaymentSystemId}/${userId}/${languageId}/${token}`, {
        headers: {
          token,
          Authorization: inputHash
        }
      })
      .then((response) => {
        const formattedOptions = response?.data?.map((option) => ({
          ...option,
          value: option?.id || option?.code,
          label: option.name,
          forVerificationFormControls: option.forVerificationFormControls
        })) || [];

        if (formattedOptions?.length === 0) {
          alertContext?.error('No Options Available');
        }

        return formattedOptions;
      })
      .catch((error) => {
        alertContext?.error(error);

        return [];
      });
  }
  const updatedUrl = url.includes('/{0}') ? url.replace('/{0}', `/${amount}`) : url;

  return axios
    .get(updatedUrl, {
      headers: {
        siteId,
        userId,
        paymentSystemId
      },
      params: {
        siteId,
        paymentSystem: paymentSystemId,
        userId,
        countryCode: country,
        currency,
        languageISO: languageId,
        token
      }
    })
    .then((response) => {
      const formattedOptions = response?.data?.map((option) => ({
        ...option,
        value: option?.id || option?.code,
        label: option.name
      })) || [];

      if (formattedOptions?.length === 0) {
        alertContext?.error('No Options Available');
      }

      return formattedOptions;
    })
    .catch((error) => {
      alertContext?.error(error);

      return [];
    });

//   setOptions(formattedOptions);
//
//   if (typeof setSelected === 'function') {
//     setSelected(formattedOptions[0]?.value);
//   }
// })
// .catch((error) => alertContext?.error(error));
};
