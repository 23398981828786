import styled from 'styled-components';
import StyledBonusMainWrapper, {
  StyledBonusList,
  StyledCardFigcaption,
  StyledCardFigcaptionDescription,
  StyledCardFigcaptionTitle,
  StyledPicture
} from '../web/bonusListWeb.styled';
import {truncate} from '../../../../helpers/styled';

const StyledBonusListMobile = styled(StyledBonusList)`
    grid-template-columns: repeat(1, 1fr);
`;

const StyledPictureMobile = styled(StyledPicture)`
    height: 120px;
`;

const StyledCardFigcaptionMobile = styled(StyledCardFigcaption)`
    padding: 8px;
`;

const StyledInfoCardFigcaptionMobile = styled(StyledCardFigcaption)`
    padding: 0 0 16px 0;
`;

const StyledMainWrapperMobile = styled(StyledBonusMainWrapper)`
    padding: 16px 12px;
`;

/* Extend base styles and override only font-size */
const StyledCardFigcaptionTitleMobile = styled(StyledCardFigcaptionTitle)`
  max-width: 100%;
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  line-height: 16px;
  ${truncate};
`;

const StyledCardFigcaptionDescriptionMobile = styled(StyledCardFigcaptionDescription)`
  max-width: 100%;
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  line-height: 16px;
  ${truncate};
`;

export default StyledMainWrapperMobile;
export {
  StyledBonusListMobile,
  StyledPictureMobile,
  StyledCardFigcaptionMobile,
  StyledCardFigcaptionTitleMobile,
  StyledCardFigcaptionDescriptionMobile,
  StyledInfoCardFigcaptionMobile
};
