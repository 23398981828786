import styled from 'styled-components';
import {flexContainer} from '../../../../helpers/styled';
import {StyledPaymentItemLabel} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';

const StyledPaymentFormContainer = styled.main`
  width: 100%;
  height: ${({theme}) => (theme.adminWrapper ? '100%' : 'auto')};
  display: grid;
  grid-template-columns: 1fr 380px;
  background-color: ${(props) => props.theme.generalBackgroundColor};
`;

const StyledSectionHeaderWrapper = styled.div`
  ${flexContainer};
  height: 44px;
  padding: 0 32px;
  border-bottom: 1px solid ${({theme}) => theme.surface.surfaceTertiary};
  cursor: pointer;
  
  button {
    width: 100%;
  }
  
  span {
    flex: 1;
    margin-left: -24px;
  }
`;

const StyledPaymentFormInfoWrapper = styled.div`
  padding: 40px 90px 24px;
`;

const StyledPaymentFormDefaultWrapper = styled.div`
  margin-top: 24px;
  ${flexContainer({justifyContent: 'space-between'})};
`;

const StyledPaymentItemLabelTwoTemplate = styled(StyledPaymentItemLabel)`
  gap: 4px;
  color: ${({theme}) => theme.textTitleColor};
`;

const StyledGenerateCodeWrapperTwoTemplate = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export default StyledPaymentFormContainer;
export {
  StyledSectionHeaderWrapper,
  StyledPaymentFormInfoWrapper,
  StyledPaymentFormDefaultWrapper,
  StyledPaymentItemLabelTwoTemplate,
  StyledGenerateCodeWrapperTwoTemplate
};
