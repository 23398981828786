import styled from 'styled-components';

/**
 * The main wrapper, with a gray background
 * which contains all the components
 * */
const StyledContentWrapper = styled.main`
  position: relative;
  display: flex;
  flex: 1;
  min-height: 100vh;
  max-width: 1024px;
  margin: 0 auto;
  
  strong, b {
    font-weight: bold;
  }
  
  em {
    font-style: italic;
  }
`;

export {
  StyledContentWrapper
};
