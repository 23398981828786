import React, {
  memo, useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {DeleteIcon} from '../../../../assets/icons';
import {arrayBufferToBase64, sortArrayByKeyValue} from '../../../../helpers/common';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {AccountVerificationHistoryContext} from '../../../../contexts/accountVerificationHistoryContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {UploadedFilesContext} from '../../../../contexts/uploadedFilesContext';
import {useOutsideClick} from '../../../../hooks/useOutSideClick';
import {getDropdownListOptions} from '../../../../services/getDropdownListOptions';
import {
  ADD_NEW_OPTION_VALUE,
  CARD_TYPE_ICONS,
  DROPDOWN_ICONS,
  EXPIRATION_DATE_FIELD_NAME
} from '../../../../constants/common';
import {deleteSingleOption} from '../../../../services/deleteSingleOption';
import UploadedFileFieldForTemplateOne from '../../../uploadedFileField/templateOne/uploadedFileField';
import {
  SelectOptionsContainer,
  SelectOptionsContainerMobile,
  SelectSingleOption,
  VerificationHistoryContainerMobile,
  VerificationHistoryContainerWeb
} from '../commonControlsType';

/* Styled Components */
import StyledInputWrapper, {
  StyledInputError,
  StyledInputLabel,
  StyledUploadDocumentLabel,
  StyledUploadDocumentWrapper,
  StyledUploadedDocumentList
} from '../../../../styledComponents/input/input.styled';
import {
  StyledButtonWithBorder,
  StyledButtonWithoutBg
} from '../../../../styledComponents/button/button.styled';
import {
  StyledCardIconWrapper,
  StyledSelect,
  StyledSelectOptionMobileWrapper,
  StyledSelectOptionsWrapper,
  StyledSelectText,
  StyledSelectWrapper
} from '../../../../styledComponents/select/select.styled';
import StyledLabelInfoWrapper, {
  StyledLabelInfo
} from '../../../../styledComponents/label/label.styled';

/**
 * New DynamicDropdown Field
 */
const NewDynamicDropdownField = memo(({
  name,
  value,
  keyName,
  error,
  stepId,
  stepUrl,
  isMobile,
  isOffline,
  alertContext,
  fieldControl,
  setFieldValue,
  hasVerificationHistory,
  showVerificationHistoryIcon,
  getControlPropertyDescriptions
}) => {
  const optionsRef = useRef();
  const showOptionsBtnRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState([]);

  const identifiedByFieldKey = useMemo(
    () => JSON.parse(fieldControl?.identifiedbyfiledkey?.value || 'false'),
    [fieldControl?.identifiedbyfiledkey?.value]
  );

  const [selectedValue, selectedName, selectedCardScheme] = useMemo(() => {
    if (value && options?.length > 0) {
      const parsedValue = JSON.parse(value);

      if (identifiedByFieldKey) {
        const {value: optionValue, name: optionName, cardScheme} = options.find((option) => option?.value === parsedValue[keyName]) || {};

        return [optionValue, optionName, cardScheme];
      }

      return [parsedValue.value, parsedValue.name, parsedValue.cardScheme];
    }

    return [null, ''];
  }, [value, options, keyName, identifiedByFieldKey]);

  const sortedOptions = useMemo(() => sortArrayByKeyValue({
    array: options,
    key: 'value',
    value: selectedValue
  }), [options, selectedValue]);

  const isNew = selectedValue === ADD_NEW_OPTION_VALUE;

  const {
    siteId, userId, currency, country, language, token, operationType
  } = useContext(
    CashierDetailsContext
  );
  const {paymentSystemId, platformPaymentSystemId} = useContext(
    SelectedPaymentDetailsContext
  );
  const {clearVerificationHistoryHandler, verificationHistory} = useContext(
    AccountVerificationHistoryContext
  );
  const {getVerificationFormControls, getFormInitialStepControls} = useContext(
    PaymentFormCallbacksContext
  );

  useOutsideClick(
    () => {
      setShowOptions(false);
    },
    [optionsRef, showOptionsBtnRef]
  );

  useEffect(() => {
    if (options?.length > 0) {
      let option = options[0];
      if (!!selectedValue) {
        option = options.find((item) => item.value === selectedValue);
      }
      const {cardExpirationDate, value: optionValue} = option;

      /**
       * Added a check for the multi-select case to send only the keyName and code to the backend.
       * For single-select, the entire selected option object is sent
       * */
      if (identifiedByFieldKey) {
        setFieldValue(name, JSON.stringify({[keyName]: option?.code}));
      } else {
        setFieldValue(name, JSON.stringify(option));
      }
      if (cardExpirationDate && optionValue !== '-1') {
        setFieldValue(EXPIRATION_DATE_FIELD_NAME, cardExpirationDate);
      } else if (optionValue === '-1') {
        setFieldValue(EXPIRATION_DATE_FIELD_NAME, '');
      }
    }
  }, [name, options, keyName, selectedValue, setFieldValue, identifiedByFieldKey]);

  /**
   * Fetch data for the first dropdown when the component mounts
   */
  useEffect(() => {
    if (fieldControl?.url?.value) {
      getDropdownListOptions({
        token,
        siteId,
        userId,
        country,
        currency,
        isOffline,
        alertContext,
        paymentSystemId,
        languageId: language,
        platformPaymentSystemId,
        url: fieldControl.url.value
      }).then((response) => {
        if (response?.length) {
          setOptions(response);
        }
      });
    }
  }, [siteId]);

  const Icon = useMemo(
    () => DROPDOWN_ICONS[fieldControl?.type?.value] ?? null,
    [fieldControl]
  );

  const SelectedOptionIcon = useMemo(() => {
    const iconId = selectedCardScheme;
    return typeof iconId === 'number' ? CARD_TYPE_ICONS[iconId] : null;
  }, [selectedCardScheme]);

  const onOptionsClose = useCallback(() => {
    setShowOptions(false);
  }, []);

  const onSelect = useCallback((selected, forVerificationFormControls) => {
    setShowOptions(false);
    if (forVerificationFormControls) {
      getVerificationFormControls();
    } else if (stepUrl && stepId && selected === ADD_NEW_OPTION_VALUE) {
      getFormInitialStepControls(stepUrl, {
        siteId, paymentSystemId, paymentMethodType: operationType, stepId
      });
    } else {
      const selectedOption = options.find((option) => option?.value === selected);
      if (identifiedByFieldKey) {
        setFieldValue(name, JSON.stringify({[keyName]: selectedOption?.code}));
      } else {
        setFieldValue(name, JSON.stringify(selectedOption));
      }
    }
  }, [name, stepId, stepUrl, keyName, options, identifiedByFieldKey]);

  const onDeleteOption = useCallback(
    (option) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      const sanitizedOption = {...option};
      delete sanitizedOption.label;
      delete sanitizedOption.value;
      const unbindProcessData = JSON.stringify(sanitizedOption);

      const url = fieldControl?.deleteactionurl?.value;
      if (url) {
        deleteSingleOption({
          url,
          siteId,
          platformPaymentSystemId,
          paymentSystemId,
          unbindProcessData,
          userId,
          languageId: language
        }).then(() => {
          if (fieldControl?.url?.value) {
            setShowOptions(false);
            getDropdownListOptions({
              token,
              siteId,
              userId,
              country,
              currency,
              isOffline,
              alertContext,
              paymentSystemId,
              languageId: language,
              platformPaymentSystemId,
              url: fieldControl.url.value
            }).then((response) => {
              if (response?.length) {
                setOptions(response);
              }
            });
          }
        });
      }
    },
    [fieldControl]
  );

  const closeModalsHandler = useCallback(() => {
    setShowOptions(false);
    clearVerificationHistoryHandler();
  }, [clearVerificationHistoryHandler]);

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledInputWrapper isNotRelative>
      {!isNew && options?.length === 1 && !hasVerificationHistory && !showVerificationHistoryIcon ? (
        <StyledInputLabel>
          {getControlPropertyDescriptions.name}
        </StyledInputLabel>
      ) : null}
      <StyledSelectWrapper spaceBetween>
        <StyledSelect isNew={isNew} hasNoCardIcon={!SelectedOptionIcon}>
          {!isNew && SelectedOptionIcon && (
            <StyledCardIconWrapper>
              <SelectedOptionIcon />
            </StyledCardIconWrapper>
          )}
          <StyledSelectText>
            {selectedName}
          </StyledSelectText>
          {(!isNew && options?.length === 1 && fieldControl?.withdeletableoptions?.value) ? (
            <StyledButtonWithoutBg
              type="button"
              onClick={onDeleteOption(options[0])}
            >
              <DeleteIcon />
            </StyledButtonWithoutBg>
          ) : null}
        </StyledSelect>
        {(options?.length > 1 || isNew || (hasVerificationHistory && showVerificationHistoryIcon)) ? (
          <StyledButtonWithBorder
            type="button"
            ref={showOptionsBtnRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowOptions((prev) => !prev);
            }}
          >
            {Icon ? <Icon /> : 'I'}
          </StyledButtonWithBorder>
        ) : null}
        {/* eslint-disable-next-line no-nested-ternary */}
        {isMobile ? (
          <>
            <SelectOptionsContainerMobile
              showInfo={showOptions && verificationHistory?.length === 0}
              optionsType={getControlPropertyDescriptions?.name}
              componentRef={optionsRef}
              onClose={onOptionsClose}
              showVerificationHistoryIcon={showVerificationHistoryIcon}
              hasVerificationHistory={hasVerificationHistory}
            >
              <StyledSelectOptionMobileWrapper className="withoutPaddingLeft">
                {sortedOptions?.map(
                  (option) => {
                    const {
                      label, value: optionValue, forVerificationFormControls, cardScheme
                    } = option;

                    return (
                      <SelectSingleOption
                        key={optionValue}
                        label={label}
                        value={optionValue}
                        iconId={cardScheme}
                        isSelected={optionValue === selectedValue}
                        onSelect={onSelect}
                        onDelete={fieldControl?.withdeletableoptions?.value && optionValue !== ADD_NEW_OPTION_VALUE ? onDeleteOption(option) : null}
                        forVerificationFormControls={forVerificationFormControls}
                      />
                    );
                  }
                )}
              </StyledSelectOptionMobileWrapper>
            </SelectOptionsContainerMobile>
            <VerificationHistoryContainerMobile
              historyData={verificationHistory}
              onClose={closeModalsHandler}
              onGoBack={clearVerificationHistoryHandler}
            />
          </>
        ) // eslint-disable-next-line no-nested-ternary
          : verificationHistory?.length > 0 ? (
            <VerificationHistoryContainerWeb
              historyData={verificationHistory}
              onClose={clearVerificationHistoryHandler}
              iconType="arrowLeft"
            />
          ) : showOptions ? (
            <SelectOptionsContainer
              optionsType={getControlPropertyDescriptions.name}
              componentRef={optionsRef}
              onClose={onOptionsClose}
              showVerificationHistoryIcon={showVerificationHistoryIcon}
              hasVerificationHistory={hasVerificationHistory}
            >
              <StyledSelectOptionsWrapper>
                {sortedOptions?.map(
                  (option) => {
                    const {
                      label, value: optionValue, forVerificationFormControls, cardScheme
                    } = option;

                    return (
                      <SelectSingleOption
                        key={optionValue}
                        label={label}
                        value={optionValue}
                        iconId={cardScheme}
                        isSelected={optionValue === selectedValue}
                        onSelect={onSelect}
                        onDelete={fieldControl?.withdeletableoptions?.value && optionValue !== ADD_NEW_OPTION_VALUE ? onDeleteOption(option) : null}
                        forVerificationFormControls={forVerificationFormControls}
                      />
                    );
                  }
                )}
              </StyledSelectOptionsWrapper>
            </SelectOptionsContainer>
          ) : null}
      </StyledSelectWrapper>
      {error ? <StyledInputError>{error}</StyledInputError> : null}
    </StyledInputWrapper>
    );
});

NewDynamicDropdownField.propTypes = {
  error: PropTypes.string,
  stepId: PropTypes.string,
  stepUrl: PropTypes.string,
  fieldControl: PropTypes.object,
  hasVerificationHistory: PropTypes.bool,
  showVerificationHistoryIcon: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  keyName: PropTypes.string.isRequired
};

NewDynamicDropdownField.defaultProps = {
  error: '',
  stepId: null,
  stepUrl: null,
  fieldControl: null,
  hasVerificationHistory: false,
  showVerificationHistoryIcon: true
};

/**
 * Document Upload Field
 */
const DocumentUploadField = memo(
  ({
    name,
    alertContext,
    controlProperties
  }) => {
    const {
      fileformat,
      filesize,
      placeholder,
      uploaddocumentcount,
      validationfileformatmessage,
      validationfilesizemessage
    } = controlProperties;

    const {uploadedFiles, setUploadedFiles} = useContext(UploadedFilesContext);

    const isUploadAvailable = useMemo(
      () => uploadedFiles.length < Number(uploaddocumentcount),
      [uploadedFiles.length, uploaddocumentcount]
    );

    const onChange = async(e) => {
      e.preventDefault();
      const file = e.target.files[0];
      const buffer = await file?.arrayBuffer();
      const base64String = arrayBufferToBase64(buffer);

      const fileName = file?.name?.split('.')[0];
      const fileFormat = file?.name?.split('.')
        ?.pop()
        ?.toLowerCase();

      const fileSize = Number(filesize.split(/(\d+)/)
        .filter(Boolean)[0]);

      if (!!fileName && !!fileFormat) {
        if (!fileformat.includes(fileFormat)) {
          alertContext.error(validationfileformatmessage);
        } else if (file.size / 1024 / 1024 > Number(fileSize)) {
          alertContext.error(validationfilesizemessage);
        } else {
          const transformedData = {
            imageName: fileName,
            imageFormat: fileFormat,
            imageBase64Format: base64String
          };
          setUploadedFiles((prev) => [...prev, transformedData]);
        }
      }
    };

    return (
      <StyledUploadDocumentWrapper>
        {isUploadAvailable && (
          <>
            <StyledUploadDocumentLabel htmlFor={name}>
              {placeholder}
            </StyledUploadDocumentLabel>
            <input
              type="file"
              name={name}
              id={name}
              onChange={onChange}
              accept={fileformat}
              hidden
            />
          </>
        )}
        {uploadedFiles.length > 0 && (
          <StyledUploadedDocumentList>
            {uploadedFiles.map((file, index) => (
              <UploadedFileFieldForTemplateOne
                // eslint-disable-next-line react/no-array-index-key
                key={`${file.imageName}-${index}`}
                fileName={file.imageName}
                fileIndex={index}
                deleteFileHandler={setUploadedFiles}
              />
            ))}
          </StyledUploadedDocumentList>
        )}
      </StyledUploadDocumentWrapper>
    );
  }
);

DocumentUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  alertContext: PropTypes.object.isRequired,
  controlProperties: PropTypes.object.isRequired
};

const LabelField = function({
  getControlPropertyDescriptions,
  fieldControl,
  ...props
}) {
  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledLabelInfoWrapper {...props}>
      <StyledLabelInfo
        dangerouslySetInnerHTML={{
          __html: getControlPropertyDescriptions.text
        }}
      />
    </StyledLabelInfoWrapper>
    );
};

LabelField.propTypes = {
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  fieldControl: PropTypes.object.isRequired
};

export const templateOneSpecificFields = {
  NewDynamicDropdownField,
  DocumentUploadField,
  LabelField
};
