import styled, {css} from 'styled-components';
import {
  flexColumnContainer, flexContainer, fontStyle, getBorder, getBorderRadius
} from '../../../../helpers/styled';
import {HOVER_MOTIONS_LIST} from '../../../../constants/common';

/**
 * Styled Main Wrapper
 * */
const StyledMainWrapper = styled.main`
  position: relative;
  min-height: 100%;
  width: 100%;
  ${flexColumnContainer()};
  padding: 24px;
  background-color: ${(props) => props.theme.generalBackgroundColor};
  overflow: hidden;
  
  ${(props) => props.scrollHidden && css`
    height: 100vh;
  `}
`;

/**
 * Styled Group Name Wrapper
 * */
const StyledGroupNameWrapper = styled.div`
  min-height: 32px;
  padding: 4px 16px;
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.groupHeaderBackgroundColor};
  border: ${(props) => getBorder(props, 'groupHeader')}; // Automatically handles border width, color, and style
  border-radius: ${(props) => getBorderRadius(props, 'groupHeader')}; // Handles border-radius for all corners
`;

const StyledGroupNameWrapperMobile = styled(StyledGroupNameWrapper)`
  margin-bottom: 8px;
`;

const StyledPaymentItemSection = styled.section`
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

/**
 * Styled Group Name Message
 * */
const StyledGroupName = styled.h2`
  ${flexContainer({
    justifyContent: 'space-between'
  })};
  ${fontStyle('semiBold')};
  color: ${(props) => props.theme.groupHeaderTitleColor};
  text-transform: uppercase;
`;

/**
 * Styled Payment System
 * List Wrapper
 */
const StyledPsWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-items: stretch;
  grid-gap: 16px;
`;

/**
 * Styled Payment System List Items
 */
const StyledPaymentItem = styled.figure`
  position: relative;
  width: 180px;
  ${flexColumnContainer()};
  padding: 12px;
  grid-gap: 12px;
  border: ${(props) => (props.theme.cardHasBorder
    ? `${props.theme.cardBorderWidth}px solid ${props.theme.cardBorderColor}`
    : props.theme.defaultCardBorderWidth)};
  border-top: ${({theme}) => theme.cardDesignBorderTop};
  border-bottom: ${({theme}) => theme.cardDesignBorderBottom};
  border-left: ${({theme}) => theme.cardDesignBorderLeft};
  border-right: ${({theme}) => theme.cardDesignBorderRight};
  border-radius: ${(props) => getBorderRadius(props, 'card')}; // Handles border-radius for all corners
  box-shadow: ${(props) => (props.theme.cardHasShadow ? `0 2px 4px ${props.theme.cardShadowColor}` : 'none')};
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
  transition: 400ms;
  cursor: pointer;
  overflow: hidden;

  ${(props) => props.disabled && css`
    background-color: rgba(185, 38, 38, 0.5);
  `};

  ${(props) => props.unavailable && css`
    background-color: rgba(0, 0, 0, 0.2);
  `};
  
  /* Conditional styling for card hover motion */
  ${({theme}) => theme.cardHoverMotion === HOVER_MOTIONS_LIST.ZOOM && css`
    &:hover {
      /* Add styles for zoom effect */
    }
  `}

  ${(props) => props.cardHoverMotion === 'button' && css`
    &:hover {
      /* Add styles for button effect */
    }
  `}
`;

/**
 * Payment New / Under Maintenance
 * Label
 */
const StyledPaymentHint = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  height: auto;
  max-width: 116px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 8px;
  background-color: ${({theme}) => theme.cardNewLabelBackgroundColor};
  color: ${({theme}) => theme.cardNewLabelTextColor};
  ${fontStyle('semiBold')};
  font-size: 10px;
  line-height: normal;
  border-radius: ${({theme}) => `0 0
    ${theme.labelBorderBottomLeftRadius}
    ${theme.labelBorderBottomRightRadius}
  `};
  z-index: ${({theme}) => theme.zIndex999};
  white-space: nowrap;
  
  ${(props) => props.unavailable && css`
    background-color: ${() => props.theme.cardUnderMaintenanceLabelBackgroundColor};
    color: ${() => props.theme.cardUnderMaintenanceLabelTextColor};
  `};

  ${(props) => props.cardHint && css`
    top: -12px;
  `};
`;

/**
 * Payment Item Mobile Logo
 * Container
 */
const StyledPaymentItemLogoWrapper = styled.div`
  position: relative;
  ${flexContainer()};
  height: 70px;
  background-color:  ${({theme}) => theme.generalColorNeutralMinimum};
  border-radius: ${(props) => getBorderRadius(props, 'cardLogoContainer')}; // Handles border-radius for all corners

  img {
    max-width: 100%;
    height: calc(60 / 70 * 100%);
    width: auto;
  }
`;

/**
 * Payment Item List
 * Container
 */
const StyledPaymentItemFigcaptionList = styled.ul`
  ${flexColumnContainer()};
  grid-gap: 4px;
  margin: 0;
`;

/** *
 * Styled Payment Item Label Colors
 */
const StyledPaymentItemLabel = styled.h3`
  ${flexContainer};
  justify-content: space-between;
  grid-gap: 8px;
  color: ${({theme}) => theme.cardTextTitleColor};
  ${fontStyle('regular')};
  line-height: 24px;
  width: 100%;
`;

/** *
 * Styled Payment Item Value Colors
 */
const StyledPaymentItemValue = styled.span`
  ${fontStyle('semiBold')};
  color: ${(props) => props.theme.cardTextValueColor};
`;

/** *
 * Styled QR CODE wrapper
 */
const StyledQrCodeWrapper = styled.div`
  width: 260px;
  height: auto;
  margin: 0 auto 24px;
  background: white;
  padding: 12px;
  border-radius: 6px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default StyledPaymentItem;
export {
  StyledMainWrapper,
  StyledPsWrapper,
  StyledGroupNameWrapper,
  StyledGroupNameWrapperMobile,
  StyledPaymentItemSection,
  StyledGroupName,
  StyledPaymentHint,
  StyledPaymentItemFigcaptionList,
  StyledPaymentItemLabel,
  StyledPaymentItemValue,
  StyledPaymentItemLogoWrapper,
  StyledQrCodeWrapper
};
