import styled from 'styled-components';
import {flexContainer, fontStyle} from '../../../helpers/styled';
import {StyledListItem} from '../../../styledComponents/list/list.styled';

const StyledPaymentDetailsItemWrapper = styled.div`
  background-color: ${({theme}) => theme.surface.surfaceTertiary};
  border-radius: 8px;
`;

const StyledAccordionHeader = styled.header` 
  ${flexContainer({justifyContent: 'space-between'})};
  height: 40px;
  padding: 8px 12px;
`;

const StyledAccordionTitle = styled.h3` 
  ${fontStyle('regular')};
  text-transform: uppercase;
  color: ${(props) => props.theme.text.text};
  cursor: pointer;
`;

const StyledAccordionContent = styled.div` 
  padding: 8px 12px;
  border-top: 1px solid ${({theme}) => theme.border.border};
`;

const StyledDetailsListItem = styled(StyledListItem)`  
  height: 40px;
  border-top: 1px solid ${({theme}) => theme.border.border};
  padding: 8px 12px;
`;

const StyledBackToText = styled.span`
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeLarge};
  color: ${(props) => props.theme.text.text};
`;

export {
  StyledPaymentDetailsItemWrapper,
  StyledAccordionHeader,
  StyledAccordionTitle,
  StyledAccordionContent,
  StyledDetailsListItem,
  StyledBackToText
};
