import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getControlPropertyDescriptions, getControlPropertyValues} from '../../../helpers/common';
import {CashierDetailsContext} from '../../../contexts/cashierDetailsContext';
import {PaymentFormCallbacksContext} from '../../../contexts/paymentFormCallbacksContext';
import {SelectedPaymentDetailsContext} from '../../../contexts/selectedPaymentDetailsContext';
import {PAYMENT_CONTROL_TYPE} from '../../../constants/formControlType';
import {
  DEVICE_TYPE, FIELDS_CUSTOM_ERROR_NAMES, FIELDS_DEFAULT_CUSTOM_ERRORS, SAFETY_PAY_IDS, TEMPLATE_TYPE
} from '../../../constants/common';
import {
  TextBoxField,
  DynamicLabelField,
  ButtonWithMultiUrl,
  DynamicDropdownField,
  BankAmountListField,
  StatusMessageField,
  CheckboxField,
  ButtonField,
  QrCodeField,
  ApplePayButtonField,
  GooglePayButtonField,
  PayPalButtonField,
  BackToFormButton,
  SelectField,
  NewSelectField,
  HiddenField,
  SafetyPayGuideAndButtonField,
  DoubleField,
  CardNumberInputField,
  GeneratedCodesField,
  ButtonOptionsDropdown, ExpirationDateField, CVVField
} from '../controlsType/commonControlsType';
import {templateOneSpecificFields} from '../controlsType/templateOneSpecific/templateOneSpecific';
import {templateTwoSpecificFields} from '../controlsType/templateTwoSpecific/templateTwoSpecific';
import {BonusCancellationIcon} from '../../../assets/icons';
import {SafetyPayPaymentContext} from '../../../contexts/safetyPayPaymentContext';
import PaymentBonusField from '../../paymentBonusField/paymentBonusField';

/* Styled Components*/
import {renderFieldWithCustomWidth, StyledWrapperMapping} from '../../../helpers/styled';
import StyledButton from '../../../styledComponents/button/button.styled';
import {
  StyledBonusSectionWrapper,
  StyledButtonWrapper,
  StyledSectionHeading,
  StyledSectionIconWrapper,
  StyledSectionInfoWrapper,
  StyledSectionParagraph
} from '../templateOne/web/formWeb.styled';
import {StyledFlexContainerDynamic} from '../../paymentBonusesList/paymentBonusesListWeb.styled';

const specificFieldsByTemplates = {
  [TEMPLATE_TYPE.TEMPLATE_ONE]: templateOneSpecificFields,
  [TEMPLATE_TYPE.TEMPLATE_TWO]: templateTwoSpecificFields
};

const {INVALID_CARD_NUMBER, INVALID_EXPIRATION_DATE} = FIELDS_CUSTOM_ERROR_NAMES;

const RenderPaymentForm = function(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
    controls,
    formattedControls,
    setFieldValue,
    alertContext,
    setSelectedValue,
    selectedValue,
    handlePreviousValue,
    favoriteAmounts,
    autoGeneratedForm,
    isOffline,
    payPalTransactionData,
    googlePayTransactionData,
    deviceType,
    isFileUploaded,
    foundDynamicControlGroupItems,
    currency,
    isCurrencySymbol,
    hasVerificationHistory,
    showBonusCancellationWarning,
    onCancelWithdrawalHandler,
    cancelBonusAndProceedWithdrawalHandler,
    templateType,
    openPaymentFormBonusesForWeb
  } = props;

  const {
    DocumentUploadField,
    NewDynamicDropdownField,
    LabelField
  } = specificFieldsByTemplates[templateType];

  const {t} = useTranslation();

  // const getControlsMapping = () => (deviceType === DEVICE_TYPE.MOBILE ? MOBILE_CONTROLS_MAPPING : WEB_CONTROLS_MAPPING);

  const isMobile = deviceType === DEVICE_TYPE.MOBILE;

  const [selectedOption, setSelectedOption] = useState(null);
  const [fieldsCustomErrors, setFieldsCustomErrors] = useState(FIELDS_DEFAULT_CUSTOM_ERRORS);

  const {
    handleApplePayClick,
    merchantUserMultistepControls,
    setPaymentControlsData,
    setIsLoading
  } = useContext(PaymentFormCallbacksContext);
  const {language} = useContext(CashierDetailsContext);
  const paymentSystemDetail = useContext(SelectedPaymentDetailsContext);
  const {resetTransactionDataHandler} = useContext(SafetyPayPaymentContext);

  const minMax = paymentSystemDetail?.min && paymentSystemDetail?.max ? {
    min: paymentSystemDetail.min,
    max: paymentSystemDetail.max
  } : null;

  const requiredFields = useMemo(
    () => controls
      ?.filter(({fieldControls}) => fieldControls?.some(({controlPropertyTypeName, value}) => controlPropertyTypeName === 'required' && value === 'true')
          && !fieldControls?.some(({controlPropertyTypeName, value}) => controlPropertyTypeName === 'hidden' && value === 'true'))
      ?.map(({key}) => key),
    [controls]
  );

  const disableSubmit = useMemo(
    () => requiredFields?.some((field) => {
      if (field === 'UploadDocument ') {
        return !isFileUploaded;
      } if (field === 'amount' && minMax) {
        return (!values[field] || values[field] < minMax.min || values[field] > minMax.max);
      }
      return !values[field];
    })
      || Object.keys(errors)?.length > 0
    || Object.values(fieldsCustomErrors).some((customError) => customError === true),
    [requiredFields, values, isFileUploaded, minMax, errors, fieldsCustomErrors]
  );

  const groupedControls = useMemo(() => {
    if (!controls?.length) return [];

    const grouped = controls.reduce(
      (acc, control) => {
        const {order, controlGroupId} = control;

        if (controlGroupId) {
          if (!acc.groups[controlGroupId]) {
            acc.groups[controlGroupId] = {order, list: []};
          }
          acc.groups[controlGroupId].list.push(control);
        } else {
          acc.ungrouped.push([control]);
        }

        return acc;
      },
      {groups: {}, ungrouped: []}
    );

    const result = [...grouped.ungrouped];
    Object.values(grouped.groups)
      .sort((a, b) => a.order - b.order)
      .forEach(({order, list}) => {
        result.splice(order - 1, 0, list);
      });

    return result;
  }, [controls]);

  // const controlsMapping = getControlsMapping();

  const renderField = (control) => {
    // const ControlComponent = controlsMapping[control.controlTypeId];
    // if (!ControlComponent) return null;

    const commonProps = {
      id: control?.id,
      name: control?.key,
      keyName: control?.key,
      error: touched[control.key] && errors[control.key],
      value: values[control.key] || '',
      onChange: handleChange,
      onBlur: handleBlur,
      fieldControl: formattedControls[control.key],
      getControlPropertyDescriptions: getControlPropertyDescriptions(
        control.fieldControls,
        language
      ),
      templateType
    };

    const {onChange, onBlur, ...dynamicDropdownProps} = commonProps;

    const showVerificationHistoryIcon = getControlPropertyValues(control.fieldControls, language).withverificationhistory ?? false;
    const isBonusLinked = getControlPropertyValues(control.fieldControls, language).isbonuslinked ?? false;
    const stepUrl = getControlPropertyValues(control.fieldControls)?.stepurl || null;
    const stepId = getControlPropertyValues(control.fieldControls)?.stepId || null;

    // const additionalProps = {};

    switch (control.controlTypeId) {
      case PAYMENT_CONTROL_TYPE.TEXT_BOX:
        return (
          <TextBoxField
            {...commonProps}
            minMax={minMax}
            favoriteAmounts={favoriteAmounts}
            isMobile={isMobile}
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
            hasVerificationHistory={hasVerificationHistory}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.CARD_NUMBER_INPUT:
        return (
          <CardNumberInputField
            {...commonProps}
            showValidationError={fieldsCustomErrors[INVALID_CARD_NUMBER]}
            setShowValidationError={setFieldsCustomErrors}
          />
        );
      case PAYMENT_CONTROL_TYPE.EXPIRATION_DATE:
        return (
          <ExpirationDateField
            {...commonProps}
            showValidationError={fieldsCustomErrors[INVALID_EXPIRATION_DATE]}
            setShowValidationError={setFieldsCustomErrors}
          />
        );
      case PAYMENT_CONTROL_TYPE.CVV:
        return (
          <CVVField {...commonProps} />
        );
      case PAYMENT_CONTROL_TYPE.LABEL:
        return (
          <LabelField
            {...commonProps}
            label={control.name}
          />
        );
      case PAYMENT_CONTROL_TYPE.DYNAMIC_LABEL:
        return (
          <DynamicLabelField
            {...commonProps}
            type={control.type}
            handleApplePayClick={handleApplePayClick}
          />
        );
      case PAYMENT_CONTROL_TYPE.DYNAMIC_DROPDOWN:
        return (
          <DynamicDropdownField
            {...dynamicDropdownProps}
            setFieldValue={setFieldValue}
            isOffline={isOffline}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.NEW_DYNAMIC_LIST:
        return (
          <NewDynamicDropdownField
            {...dynamicDropdownProps}
            setFieldValue={setFieldValue}
            isOffline={isOffline}
            alertContext={alertContext}
            isMobile={isMobile}
            showVerificationHistoryIcon={showVerificationHistoryIcon}
            hasVerificationHistory={hasVerificationHistory}
            templateType={templateType}
            stepUrl={stepUrl}
            stepId={stepId}
          />
        );
      case PAYMENT_CONTROL_TYPE.SELECT:
        return (
          <SelectField
            {...dynamicDropdownProps}
            setFieldValue={setFieldValue}
            selectedValue={selectedValue}
            getSelectedValue={(sanitizedOption, value) => {
              setSelectedOption(value);
              handlePreviousValue({[control.key]: JSON.stringify(sanitizedOption)});
            }}
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.NEW_STATIC_LIST:
        return (
          <NewSelectField
            {...dynamicDropdownProps}
            setFieldValue={setFieldValue}
            getSelectedValue={(sanitizedOption, value) => {
              setSelectedOption(value);
              handlePreviousValue({[control.key]: JSON.stringify(sanitizedOption)});
              setFieldValue(control.key, selectedValue);
            }}
            selectedValue={selectedValue}
            isMobile={isMobile}
            hasVerificationHistory={hasVerificationHistory}
          />
        );
      case PAYMENT_CONTROL_TYPE.BANK_AMOUNT_LIST:
        return (
          <BankAmountListField
            {...commonProps}
            type="text"
            label={control.name}
            placeholder={control.name}
            validationError={touched[control.key] && errors[control.key]}
            alertContext={alertContext}
            isCurrencySymbol={isCurrencySymbol}
          />
        );
      case PAYMENT_CONTROL_TYPE.BUTTON_OPTIONS_DROP_DOWN:
        return (
          <ButtonOptionsDropdown
            id={control.key}
            name={control.key}
            error={touched[control.key] && errors[control.key]}
            value={values[control.key]}
            currency={currency}
            setFieldValue={setFieldValue}
            fieldControl={formattedControls[control.key]}
            isCurrencySymbol={isCurrencySymbol}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.BUTTON:
      case PAYMENT_CONTROL_TYPE.BUTTON_WIDTH_URL:
        return (
          <>
            {isBonusLinked ? (
              <PaymentBonusField
                isMobile={isMobile}
                amount={!!values.amount ? Number(values.amount) : 0}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                openPaymentFormBonusesForWeb={openPaymentFormBonusesForWeb}
              />
            ) : null}
            <ButtonField
              {...commonProps}
              type={control.type}
              disabled={disableSubmit}
            />
          </>
        );
      case PAYMENT_CONTROL_TYPE.APPLE_PAY_BUTTON:
        return (
          <ApplePayButtonField
            {...commonProps}
            type={control.type}
            handleApplePayClick={handleApplePayClick}
          />
        );
      case PAYMENT_CONTROL_TYPE.BUTTON_WITH_MULTI_URL:
        return (
          <ButtonWithMultiUrl
            {...commonProps}
            type={control.type}
            handleApplePayClick={handleApplePayClick}
            disabled={disableSubmit}
            merchantUserMultistepControls={merchantUserMultistepControls}
            setPaymentControlsData={setPaymentControlsData}
            alertContext={alertContext}
            setIsLoading={setIsLoading}
          />
        );
      case PAYMENT_CONTROL_TYPE.STATUS_MESSAGE:
        return (
          <StatusMessageField
            {...commonProps}
            label={control.name}
            isMobile={isMobile}
          />
        );
      case PAYMENT_CONTROL_TYPE.QRCODE:
        return <QrCodeField {...commonProps} />;
      case PAYMENT_CONTROL_TYPE.CHECKBOX:
        return (
          <CheckboxField
            {...commonProps}
            type="checkbox"
          />
        );
      case PAYMENT_CONTROL_TYPE.PAYPAL_BUTTON:
        return (
          <PayPalButtonField
            {...commonProps}
            resetForm={resetForm}
            amountValue={values?.amount}
            alertContext={alertContext}
            payPalTransactionData={payPalTransactionData}
            merchantUserMultistepControls={merchantUserMultistepControls}
            setPaymentControlsData={setPaymentControlsData}
            setIsLoading={setIsLoading}
          />
        );
      case PAYMENT_CONTROL_TYPE.GOOGLE_PAY_BUTTON:
        return (
          <GooglePayButtonField
            {...commonProps}
            amountValue={values?.amount}
            resetForm={resetForm}
            alertContext={alertContext}
            googlePayTransactionData={googlePayTransactionData}
            merchantUserMultistepControls={merchantUserMultistepControls}
            setPaymentControlsData={setPaymentControlsData}
            setIsLoading={setIsLoading}
          />
        );
      case PAYMENT_CONTROL_TYPE.UPLOAD_DOCUMENT:
        return (
          <DocumentUploadField
            {...commonProps}
            isMobile={isMobile}
            name={control.name}
            controlProperties={getControlPropertyValues(control.fieldControls, language)}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.BACK_TO_FORM:
        return (
          <BackToFormButton additionalCallback={SAFETY_PAY_IDS.indexOf(paymentSystemDetail.paymentSystemId) > -1 ? resetTransactionDataHandler : null} />
        );
      case PAYMENT_CONTROL_TYPE.HIDDEN:
        return (
          <HiddenField
            {...commonProps}
            type={control.type}
            handleSubmit={handleSubmit}
          />
        );
      case PAYMENT_CONTROL_TYPE.SAFETY_PAY_GUIDE_AND_BUTTON:
        return (
          <SafetyPayGuideAndButtonField
            {...commonProps}
            isMobile={isMobile}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.DOUBLE_FIELD:
        return (
          <DoubleField
            {...commonProps}
            getControlPropertyDescriptions={getControlPropertyDescriptions(
              control.fieldControls,
              language
            )}
          />
        );
      case PAYMENT_CONTROL_TYPE.GENERATED_CODES_FIELD:
        return (
          <GeneratedCodesField />
        );
      default:
        return null;
    }

    // return <ControlComponent {...commonProps} {...additionalProps} />;
  };

  useEffect(() => {
    if (selectedOption && Object.keys(selectedOption).length && foundDynamicControlGroupItems) {
      setSelectedValue(selectedOption);
    }
  }, [selectedOption, foundDynamicControlGroupItems]);

  const StyledWrapper = StyledWrapperMapping[deviceType];

  return (
    <StyledWrapper
      autoGeneratedForm={autoGeneratedForm}
      onSubmit={handleSubmit}
    >
      {
        showBonusCancellationWarning ? (
          <StyledBonusSectionWrapper
            isMobile={isMobile}
          >
            <StyledSectionIconWrapper>
              <BonusCancellationIcon />
            </StyledSectionIconWrapper>
            <StyledSectionInfoWrapper>
              <StyledSectionHeading>
                {t('bonuscancellationheding')}
              </StyledSectionHeading>
              <StyledSectionParagraph>
                {t('bonuscancellationexplanation')}
              </StyledSectionParagraph>
            </StyledSectionInfoWrapper>
            <StyledButtonWrapper spaceBetween>
              <StyledButton
                isMobile={isMobile}
                secondary
                type="button"
                onClick={onCancelWithdrawalHandler}
              >
                {t('cancel')}
              </StyledButton>
              <StyledButton type="button" onClick={cancelBonusAndProceedWithdrawalHandler}>{t('proceedandcancel')}</StyledButton>
            </StyledButtonWrapper>
          </StyledBonusSectionWrapper>
        ) : groupedControls.map((controlsGroup) => (
          <StyledFlexContainerDynamic
            justify="space-between"
            alignItem="flex-start"
            overflow="initial"
          >
            {controlsGroup?.map((control) => renderFieldWithCustomWidth(renderField, control, controlsGroup.length))}
          </StyledFlexContainerDynamic>
        ))
      }
    </StyledWrapper>
  );
};
RenderPaymentForm.defaultProps = {
  autoGeneratedForm: false,
  isOffline: true,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  isFileUploaded: false,
  hasVerificationHistory: false,
  showBonusCancellationWarning: false,
  onCancelWithdrawalHandler: null,
  openPaymentFormBonusesForWeb: null,
  cancelBonusAndProceedWithdrawalHandler: null
};

RenderPaymentForm.propTypes = {
  openPaymentFormBonusesForWeb: PropTypes.func,
  deviceType: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  controls: PropTypes.array.isRequired,
  formattedControls: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  handlePreviousValue: PropTypes.func.isRequired,
  favoriteAmounts: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  autoGeneratedForm: PropTypes.bool,
  isOffline: PropTypes.bool,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  isFileUploaded: PropTypes.bool,
  foundDynamicControlGroupItems: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  hasVerificationHistory: PropTypes.bool,
  showBonusCancellationWarning: PropTypes.bool,
  onCancelWithdrawalHandler: PropTypes.func,
  cancelBonusAndProceedWithdrawalHandler: PropTypes.func,
  templateType: PropTypes.number.isRequired
};

export default memo(RenderPaymentForm);
