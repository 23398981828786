import React, {
  memo, useCallback, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PaymentBonusesListItem from './paymentBonusesListItem';

// styled components
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';
import {PaymentBonusesContext} from '../../contexts/paymentBonusesContext';
import {StyledFlexContainerDynamic, StyledFormBonusWrapper} from './paymentBonusesListWeb.styled';
import {StyledCardHeaderTitle, StyledCardHeaderWrapper} from '../paymentsForm/templateOne/web/formWeb.styled';


function PaymentBonusesListWeb({forTempTwo, onCloseListHandler}) {
  const {t} = useTranslation();
  const {
    selectedBonusId,
    paymentBonuses,
    setSelectedBonusId
  } = useContext(PaymentBonusesContext);

  const onSelectBonusHandler = useCallback((bonusId) => () => {
    setSelectedBonusId(bonusId);
  }, [setSelectedBonusId]);
  const onCancelBonusHandler = useCallback(() => {
    setSelectedBonusId(null);
  }, [setSelectedBonusId]);

  useEffect(() => () => {
    if (forTempTwo) onCloseListHandler(forTempTwo);
  }, []);

  return (
    <StyledFormBonusWrapper fullWidth={forTempTwo}>
      {forTempTwo ? null : (
        <StyledCardHeaderWrapper>
          <StyledButtonWithoutBg type="button" onClick={onCloseListHandler}>
            <StyledIconSmall icon="close" fontSize="11px" />
          </StyledButtonWithoutBg>
          <StyledCardHeaderTitle>{t('chooseyourbonus')}</StyledCardHeaderTitle>
        </StyledCardHeaderWrapper>
      )}
      <StyledFlexContainerDynamic direction="column" gap="8px">
        {paymentBonuses.map(({
          id,
          shortDescription,
          name,
          webImage
        }) => {
          const isSelected = selectedBonusId === id;
          return (
            <PaymentBonusesListItem
              key={id}
              name={name}
              imageUrl={webImage}
              description={shortDescription}
              isSelected={isSelected}
              onSelectHandler={onSelectBonusHandler(id)}
              onCancelHandler={onCancelBonusHandler}
            />
          );
        })}
      </StyledFlexContainerDynamic>
    </StyledFormBonusWrapper>
  );
}

PaymentBonusesListWeb.propTypes = {
  forTempTwo: PropTypes.bool,
  onCloseListHandler: PropTypes.func.isRequired
};

PaymentBonusesListWeb.defaultProps = {
  forTempTwo: false
};

export default memo(PaymentBonusesListWeb);
