import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

/* Styled Components */
import {
  StyledItemValue,
  StyledItemLabel
} from '../../paymentsHistory/templateOne/web/historyWeb.styled';
import {StyledButtonWithoutBg} from '../../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../../styledComponents/icon/icon.styled';
import StyledSectionDivider, {
  StyledListItem,
  StyledItemsTitle,
  StyledListItemsWrapper,
  StyledItemTitleWrapper
} from '../../../styledComponents/list/list.styled';


function PaymentLimitsTemplateOne({
  currency,
  playerCurrentLimit,
  playerLimit,
  isMobile
}) {
  const {t} = useTranslation();
  const [expand, setExpand] = useState(isMobile);

  return (
    <StyledSectionDivider>
      <StyledItemTitleWrapper
        isExpanded={expand}
        isMobile={isMobile}
      >
        <StyledItemsTitle isMobile={isMobile}>
          {t(('limits')?.toLowerCase())}
        </StyledItemsTitle>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {isMobile || (
        <StyledButtonWithoutBg
          type="button"
          onClick={() => {
            setExpand((prev) => !prev);
          }}
        >
          <StyledIconSmall icon={expand ? 'arrowUp' : 'arrowDown'} fontSize="11px" />
        </StyledButtonWithoutBg>
        )}
      </StyledItemTitleWrapper>
      {expand && (
      <StyledListItemsWrapper isMobile={isMobile} className="withoutPaddingLeft">
        {(playerCurrentLimit?.dailyLimit || playerLimit?.dailyLimit) && (
          <StyledListItem>
            <StyledItemLabel>{`${t(('daily')?.toLowerCase())} :`}</StyledItemLabel>
            <StyledItemValue>{`${playerCurrentLimit?.dailyLimit} ${currency} of ${playerLimit?.dailyLimit} ${currency}`}</StyledItemValue>
          </StyledListItem>
        )}
        {(playerCurrentLimit?.weeklyLimit || playerLimit?.weeklyLimit) && (
        <StyledListItem>
          <StyledItemLabel>{`${t(('weekly')?.toLowerCase())} :`}</StyledItemLabel>
          <StyledItemValue>{`${playerCurrentLimit?.weeklyLimit} ${currency} of ${playerLimit?.weeklyLimit} ${currency}`}</StyledItemValue>
        </StyledListItem>
        )}
        {(playerCurrentLimit?.monthlyLimit || playerLimit?.monthlyLimit) && (
        <StyledListItem>
          <StyledItemLabel>{`${t(('monthly')?.toLowerCase())} :`}</StyledItemLabel>
          <StyledItemValue>{`${playerCurrentLimit?.monthlyLimit} ${currency} of ${playerLimit?.monthlyLimit} ${currency}`}</StyledItemValue>
        </StyledListItem>
        )}
        {(playerCurrentLimit?.yearlyLimit || playerLimit?.yearlyLimit) && (
        <StyledListItem>
          <StyledItemLabel>{`${t(('yearly')?.toLowerCase())} :`}</StyledItemLabel>
          <StyledItemValue>{`${playerCurrentLimit?.yearlyLimit} ${currency} of ${playerLimit?.yearlyLimit} ${currency}`}</StyledItemValue>
        </StyledListItem>
        )}
      </StyledListItemsWrapper>
      )}
    </StyledSectionDivider>
  );
}

PaymentLimitsTemplateOne.propTypes = {
  currency: PropTypes.string,
  playerCurrentLimit: PropTypes.object,
  playerLimit: PropTypes.object,
  isMobile: PropTypes.bool
};

PaymentLimitsTemplateOne.defaultProps = {
  currency: '',
  playerCurrentLimit: null,
  playerLimit: null,
  isMobile: false
};

export default PaymentLimitsTemplateOne;
