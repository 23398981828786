import styled from 'styled-components';
import {
  StyledModalHeaderTemplateOne,
  StyledModalBodyTemplateOne,
  StyledModalContentTemplateOne
} from './templateOneMobileModal.styled';

const StyledModalContentTemplateTwo = styled(StyledModalContentTemplateOne)`
  padding: 0;
  
  ul {
    padding: 0;
  }
`;

const StyledModalHeaderTemplateTwo = styled(StyledModalHeaderTemplateOne)`
  h2 {
    flex: inherit;
  }
`;

const StyledModalBodyTemplateTwo = styled(StyledModalBodyTemplateOne)`
  top: 20px;
`;

export {
  StyledModalContentTemplateTwo,
  StyledModalBodyTemplateTwo,
  StyledModalHeaderTemplateTwo
};
