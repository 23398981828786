import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

/* Styled Components */
import {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledPaymentInfoTitle} from '../paymentsForm/templateOne/web/formWeb.styled';
import {componentMapping} from '../../helpers/styled';
import {StyledModalOverlay} from './templateOneMobileModal.styled';

function MobileModal({
  title,
  children,
  showInfo,
  handleCloseInfo,
  handleGoBack,
  additionalIcon,
  templateType
}) {
  useEffect(() => {
    if (showInfo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showInfo]);

  // Get the components based on the templateType
  const {
    StyledModalHeader,
    StyledModalBody,
    StyledModalContent
  } = componentMapping[templateType] || componentMapping.templateOne;

  return (
    <StyledModalOverlay showInfo={showInfo}>
      <StyledModalBody showInfo={showInfo}>
        <StyledModalHeader>
          {additionalIcon || (handleGoBack && (
            <StyledButtonWithoutBg
              isPositionLeft
              type="button"
              onClick={handleGoBack}
            >
              <StyledIconSmall icon="arrowLeft" fontSize="11px" />
            </StyledButtonWithoutBg>
          ))}
          <StyledPaymentInfoTitle>
            {title}
          </StyledPaymentInfoTitle>
          <StyledButtonWithoutBg
            isPositionRight
            type="button"
            onClick={handleCloseInfo}
          >
            <StyledIconSmall
              icon="close"
              fontSize="11px"
            />
          </StyledButtonWithoutBg>
        </StyledModalHeader>
        <StyledModalContent>
          {children}
        </StyledModalContent>
      </StyledModalBody>
    </StyledModalOverlay>
  );
}

MobileModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showInfo: PropTypes.bool.isRequired,
  handleCloseInfo: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func,
  additionalIcon: PropTypes.node,
  templateType: PropTypes.number
};

MobileModal.defaultProps = {
  handleGoBack: null,
  additionalIcon: null,
  templateType: 1
};

export default MobileModal;
