import React, {memo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import BonusPaymentItem from '../bonusPaymentItem/bonusPaymentItem';
import {ADDITIONAL_PAYMENTS_SHOW_VALUE} from '../../constants/bonusPayments';

/* Styled Components */
import {
  StyledCardListIContainer,
  StyledCardListItem,
  StyledOptionsContainer, StyledOverflowContainer
} from '../bonusPaymentItem/bonusPaymentItem.styled';
import {detectBonusAdditionalPaymentsContainerOpenDirection} from '../../helpers/common';

function BonusSecondaryPaymentSystems({
  isMobile,
  secondaryPayments,
  onPaymentClickHandler,
  navigateToBonusInfoHandler
}) {
  const ref = useRef();
  const [showOptions, setShowOptions] = useState(ADDITIONAL_PAYMENTS_SHOW_VALUE.CLOSE);

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (showOptions === ADDITIONAL_PAYMENTS_SHOW_VALUE.CLOSE) {
      const {bottom} = ref?.current?.getBoundingClientRect() || {};
      setShowOptions(detectBonusAdditionalPaymentsContainerOpenDirection(bottom, secondaryPayments.length));
    } else {
      setShowOptions(ADDITIONAL_PAYMENTS_SHOW_VALUE.CLOSE);
    }
  };

  return (
    <StyledCardListIContainer ref={ref} as="li">
      <StyledCardListItem
        as="p"
        onClick={isMobile ? navigateToBonusInfoHandler : onClickHandler}
      >
        +
        {secondaryPayments.length}
      </StyledCardListItem>
      {showOptions !== ADDITIONAL_PAYMENTS_SHOW_VALUE.CLOSE && !isMobile ? (
        <StyledOptionsContainer openPosition={showOptions}>
          <StyledOverflowContainer>
            {secondaryPayments.map(({
              id,
              name,
              image,
              disableMethod,
              unavailable,
              hasRedirect,
              hasVerifiedAccount
            }) => (
              <BonusPaymentItem
                key={id}
                id={id}
                name={name}
                image={image}
                disableMethod={disableMethod}
                unavailable={unavailable}
                hasRedirect={hasRedirect}
                hasVerifiedAccount={hasVerifiedAccount}
                onPaymentClickHandler={onPaymentClickHandler}
              />
            ))}
          </StyledOverflowContainer>
        </StyledOptionsContainer>
      ) : null}
    </StyledCardListIContainer>
  );
}

BonusSecondaryPaymentSystems.propTypes = {
  isMobile: PropTypes.bool,
  navigateToBonusInfoHandler: PropTypes.func,
  secondaryPayments: PropTypes.array.isRequired,
  onPaymentClickHandler: PropTypes.func.isRequired
};

BonusSecondaryPaymentSystems.defaultProps = {
  isMobile: false,
  navigateToBonusInfoHandler: null
};

export default memo(BonusSecondaryPaymentSystems);
