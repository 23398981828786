import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import HistoryTabContentMobile from './historyTabContentMobile';
import {CODE_TYPE, HISTORY_TAB, SAFETY_PAY_IDS} from '../../../../constants/common';
import Slider from '../../../slider/slider';
import MobileModal from '../../../mobileModal/mobileModal';
import GenerateCode from '../../../generateCode/generateCode';
import SafetyPayHistoryDetails from '../../../safetyPayHistoryDetails/safetyPayHistoryDetails';

/* Styled Components*/
import {
  StyledFlexContainerForPsForm,
  StyledNoDataMessage,
  StyledPaymentDetailWrapper,
  StyledPaymentName
} from '../../../paymentsForm/templateOne/web/formWeb.styled';
import {StyledMainWrapper} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledFormWrapperMobile} from '../../../paymentsForm/templateOne/mobile/formMobile.styled';
import {
  StyledButtonWithoutBg,
  StyledFlexContainer,
  StyledLinkButton
} from '../../../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import StyledTabLinkWrapper, {StyledTabButton} from '../../../../styledComponents/tab/tab.styled';
import {StyledFilterWrapper} from '../../../search/search.styled';
import StyledInputWrapper, {StyledInput, StyledInputLabel} from '../../../../styledComponents/input/input.styled';
import {StyledMainFormAndInfoWrapperMobile} from '../../../paymentsForm/templateOne/mobile/paymentInfoMobile.styled';

function HistoryMobile({
  paymentControlsData,
  historyData,
  getSelectedTab,
  navigate,
  translations,
  isCurrencySymbol,
  alertContext,
  onSafetyPayHelper
}) {
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useState(HISTORY_TAB.NEW);
  const [historyDetailsData, setHistoryDetailsData] = useState([]);
  const [filteredHistoryData, setFilteredHistoryData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dataType, setDataType] = useState('');
  const [dataName, setDataName] = useState('');
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    setFilteredHistoryData(historyData);
  }, [historyData]);

  useEffect(() => {
    getSelectedTab(selectedTab);
  }, [selectedTab]);

  const isSafetyPay = useMemo(() => SAFETY_PAY_IDS.indexOf(paymentControlsData?.paymentSystemId) > -1, [paymentControlsData]);

  /**
   * Handles onClick function of templates tabs
   *
   * @param {number} tab
   */
  const onTabClickHandler = (tab) => () => {
    getSelectedTab(tab);
    setSelectedTab(tab);
    setHistoryDetailsData([]);
  };

  const getSelectedHistoryDetails = (historyDetails) => {
    const sortedData = [...historyDetails].sort((a, b) => a.order - b.order);
    setHistoryDetailsData(sortedData);
    setShowModal(true);
    if (isSafetyPay) {
      setDataName('order');
    }
  };
  const filterByAmount = (e) => {
    const inputValue = e.target.value.trim(); // Get the user input and remove leading/trailing spaces
    if (inputValue === '') {
      setFilteredHistoryData(historyData);
      return;
    }

    const filterValue = parseFloat(inputValue);

    const filteredData = historyData.filter((item) => item.amount === filterValue);

    setFilteredHistoryData(filteredData);
  };

  const handleBackToDetails = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const foundData = filteredHistoryData[0]?.data.find((el) => el.dataName === CODE_TYPE.BAR_CODE
      || el.dataName === CODE_TYPE.QR_CODE);
    if (foundData) {
      setDataType(foundData.dataName === CODE_TYPE.BAR_CODE ? 'BarCode' : 'QrCode');
    }
  }, [filteredHistoryData]);

  return (
    <StyledMainWrapper scrollHidden>
      <Slider isHistory />
      <StyledFlexContainerForPsForm minHeight>
        <StyledFormWrapperMobile key={paymentControlsData?.paymentSystemId}>
          <StyledPaymentDetailWrapper>
            <StyledButtonWithoutBg
              isPositionLeft
              type="button"
              onClick={() => navigate('/paymentsList')}
            >
              <StyledIconSmall icon="arrowLeft" fontSize="11px" />
            </StyledButtonWithoutBg>
            <StyledFlexContainer fullWidth>
              <StyledPaymentName>
                {dataType}
                {' '}
                {t(('History')?.toLowerCase())}
              </StyledPaymentName>
            </StyledFlexContainer>
          </StyledPaymentDetailWrapper>
          <StyledTabLinkWrapper>
            <StyledTabButton
              type="button"
              isActive={selectedTab === HISTORY_TAB.NEW}
              onClick={onTabClickHandler(HISTORY_TAB.NEW)}
            >
              {t(('new')?.toLowerCase())}
            </StyledTabButton>
            <StyledTabButton
              type="button"
              isActive={selectedTab === HISTORY_TAB.PAID}
              onClick={onTabClickHandler(HISTORY_TAB.PAID)}
            >
              {t(('paid')?.toLowerCase())}
            </StyledTabButton>
            <StyledTabButton
              type="button"
              isActive={selectedTab === HISTORY_TAB.EXPIRED}
              onClick={onTabClickHandler(HISTORY_TAB.EXPIRED)}
            >
              {t(('expired')?.toLowerCase())}
            </StyledTabButton>
          </StyledTabLinkWrapper>
          <StyledMainFormAndInfoWrapperMobile minHeight>
            {selectedTab === HISTORY_TAB.NEW && historyData?.length ? (
              <StyledFilterWrapper>
                <StyledInputWrapper>
                  <StyledInputLabel>
                    {translations.Amount}
                  </StyledInputLabel>
                  <StyledInput
                    type="text"
                    name="search-form"
                    id="search-form"
                    className="search-input"
                    placeholder={t(('searchByAmount')?.toLowerCase())}
                    onChange={(e) => filterByAmount(e)}
                  />
                </StyledInputWrapper>
              </StyledFilterWrapper>
            ) : null}
            {filteredHistoryData?.length ? (
              <HistoryTabContentMobile
                historyData={filteredHistoryData}
                getSelectedHistoryDetails={getSelectedHistoryDetails}
                isCurrencySymbol={isCurrencySymbol}
                setCurrency={setCurrency}
                isSafetyPay={isSafetyPay}
              />
            ) : (
              <StyledNoDataMessage>
                {selectedTab === HISTORY_TAB.NEW && t('No New Barcode created')}
                {selectedTab === HISTORY_TAB.PAID && t('No Paid barcode found')}
                {selectedTab === HISTORY_TAB.EXPIRED && t('No Expired barcode found')}
              </StyledNoDataMessage>
            )}
            <StyledLinkButton
              centerAligned
              onClick={() => navigate('/paymentsList/:id')}
            >
              {t(('backToForm')?.toLowerCase())}
            </StyledLinkButton>
          </StyledMainFormAndInfoWrapperMobile>
        </StyledFormWrapperMobile>
        <MobileModal
          title={t((dataName)?.toLowerCase())}
          showInfo={showModal}
          handleCloseInfo={handleBackToDetails}
        >
          {isSafetyPay && selectedTab === HISTORY_TAB.NEW
            ? (
              <SafetyPayHistoryDetails
                paymentData={JSON.parse(historyDetailsData?.find((item) => item.dataName === 'SID')?.data ?? '{}')}
                onPayHandler={onSafetyPayHelper}
                isMobile
              />
            ) : (
              <GenerateCode
                setDataName={setDataName}
                codeDetailsData={historyDetailsData}
                translations={translations}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            )}
        </MobileModal>
      </StyledFlexContainerForPsForm>
    </StyledMainWrapper>
  );
}

HistoryMobile.defaultProps = {
  paymentControlsData: null,
  historyData: null,
  getSelectedTab: null,
  navigate: null,
  translations: null
};

HistoryMobile.propTypes = {
  paymentControlsData: PropTypes.object,
  historyData: PropTypes.array,
  getSelectedTab: PropTypes.func,
  navigate: PropTypes.func,
  translations: PropTypes.object,
  isCurrencySymbol: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired,
  onSafetyPayHelper: PropTypes.func.isRequired
};
export default HistoryMobile;
