import instance from './apiService';
import Config from '../configs/mainConfig';

export const getGeneratedActiveCodesList = async({
  paymentSystemId,
  siteId,
  userId
}) => {
  const response = await instance.get(
    `${Config.apiURLs.getPaymentSystemTransactionsHistory}/${paymentSystemId}/${siteId}/${userId}/2`,
    {
      param: {
        paymentSystemId,
        siteId,
        userId,
        tabId: 2
      }
    }
  );

  return response.data;
};
