import React, {useEffect, useState} from 'react';
import {paymentRightSideOptionsCallbacks} from '../helpers/common';
import {PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES} from '../constants/common';

export const templateTwoFormRightSideOptionsHOC = (Form) => function TemplateTwoFormRightSideOptionsHOC(props) {
  const [paymentRightSideOptions, setPaymentRightSideOptions] = useState(null);

  useEffect(() => {
    paymentRightSideOptionsCallbacks.addCallback(PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES.SET_OPTIONS, setPaymentRightSideOptions);

    return () => {
      paymentRightSideOptionsCallbacks.resetCallbacks();
    };
  }, []);

  return (
    <Form
      paymentRightSideOptions={paymentRightSideOptions}
      {...props}
    />
  );
};
