import React from 'react';
import PropTypes from 'prop-types';
import PaymentLimits from '../../paymentLimits/templateOne/paymentLimits';
import PaymentBalances from '../../paymentBalances/templateOne/paymentBalances';
import Toggle from '../../toggle/toggle';
import {OPERATION_TYPE_BY_NUMBER} from '../../../constants/common';

/* Styled Components */
import {
  StyledLinkItem,
  StyledPaymentInfoParagraph,
  StyledPaymentInfoRightSide,
  StyledPaymentInfoTitle
} from '../../paymentsForm/templateOne/web/formWeb.styled';
import StyledSwitcherContainer, {StyledSwitchLabel} from '../../toggle/toggled.styled';

function FormDetails({
  paymentLimits,
  paymentBalances,
  isDefaultAvailable,
  isDefault,
  defaultToggleHandler,
  paymentDescription,
  operationType,
  currency,
  t
}) {
  return (
    <StyledPaymentInfoRightSide>
      <>
        {paymentLimits && (
          <PaymentLimits
            currency={currency}
            playerCurrentLimit={paymentLimits?.playerCurrentLimit}
            playerLimit={paymentLimits?.playerLimit}
          />
        )}
        {paymentBalances && <PaymentBalances {...paymentBalances} />}
        {isDefaultAvailable && (
          <StyledSwitcherContainer>
            <Toggle
              handleToggle={defaultToggleHandler}
              isOn={isDefault}
            />
            <StyledSwitchLabel>{t('setAsDefault'.toLowerCase())}</StyledSwitchLabel>
          </StyledSwitcherContainer>
        )}
        {paymentDescription && (
          <>
            {paymentDescription?.desctiption && (
              <section>
                <StyledPaymentInfoTitle>{t('details'.toLowerCase())}</StyledPaymentInfoTitle>
                <StyledPaymentInfoParagraph
                  dangerouslySetInnerHTML={{__html: paymentDescription?.desctiption}}
                />
              </section>
            )}
            {paymentDescription?.howTo && (
              <section>
                <StyledPaymentInfoTitle>
                  {operationType === OPERATION_TYPE_BY_NUMBER.DEPOSIT
                    ? t('howToDeposit'.toLowerCase())
                    : t('howToWithdrawal'.toLowerCase())}
                </StyledPaymentInfoTitle>
                <StyledPaymentInfoParagraph
                  dangerouslySetInnerHTML={{__html: paymentDescription?.howTo}}
                />
              </section>
            )}
            {paymentDescription?.paymentInfoUrl && (
              <section>
                <StyledLinkItem
                  href={paymentDescription?.paymentInfoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {paymentDescription?.paymentInfoUrl}
                </StyledLinkItem>
              </section>
            )}
          </>
        )}
      </>
    </StyledPaymentInfoRightSide>
  );
}

FormDetails.propTypes = {
  paymentLimits: PropTypes.object,
  paymentBalances: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  isDefault: PropTypes.bool.isRequired,
  defaultToggleHandler: PropTypes.func.isRequired,
  paymentDescription: PropTypes.object,
  operationType: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

FormDetails.defaultProps = {
  paymentLimits: null,
  paymentBalances: null,
  isDefaultAvailable: false,
  paymentDescription: null
};

export default FormDetails;
