import React, {
  memo, useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import Select, {components} from 'react-select';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';
import {
  AddIcon, CheckBigIcon, UploadIcon
} from '../../../../assets/icons';
import {arrayBufferToBase64, paymentRightSideOptionsCallbacks} from '../../../../helpers/common';
import {templateTwoCustomStyles} from '../../../../helpers/styled';
import {getDropdownListOptions} from '../../../../services/getDropdownListOptions';
import UploadedFileFieldForTemplateTwo from '../../../uploadedFileField/templateTwo/uploadedFileField';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {UploadedFilesContext} from '../../../../contexts/uploadedFilesContext';
import {
  ADD_NEW_OPTION_VALUE,
  DROPDOWN_ICONS,
  PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES,
  CARD_TYPE_ICONS,
  EXPIRATION_DATE_FIELD_NAME
} from '../../../../constants/common';
import {deleteSingleOption} from '../../../../services/deleteSingleOption';

// styled components
import {
  StyledInputContainer,
  StyledInputError,
  StyledUploadDocumentLabel,
  StyledUploadDocumentWrapper,
  StyledUploadedDocumentList
} from '../../../../styledComponents/input/input.styled';
import StyledLabelInfoWrapper, {StyledLabelInfo} from '../../../../styledComponents/label/label.styled';
import {StyledFlexContainerDynamic} from '../../../paymentBonusesList/paymentBonusesListWeb.styled';
import {
  StyledButtonWithBorderTemplateTwo
} from '../../../../styledComponents/button/button.styled';
import {
  StyledCardIconWrapper,
  StyledIconsContainer,
  StyledSelectOptionsItem
} from '../../../../styledComponents/select/select.styled';
import {usePrevious} from '../../../../hooks/usePrevious';


/**
 * DynamicDropdown Field
 */
const NewDynamicDropdownField = memo(({
  name,
  value,
  error,
  stepId,
  stepUrl,
  isOffline,
  alertContext,
  fieldControl,
  setFieldValue,
  getControlPropertyDescriptions
  // isMobile,
  // showVerificationHistoryIcon,
  // hasVerificationHistory
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  const previousValue = usePrevious(value);

  const {
    siteId, userId, currency, country, language, token, operationType
  } = useContext(
    CashierDetailsContext
  );
  const {paymentSystemId, platformPaymentSystemId} = useContext(
    SelectedPaymentDetailsContext
  );
  const {getVerificationFormControls, getFormInitialStepControls} = useContext(
    PaymentFormCallbacksContext
  );

  const Icon = useMemo(
    () => {
      if (selected && options?.length > 0) {
        const cardScheme = options.find((option) => option.value === selected)?.cardScheme;
        const CardIcon = typeof cardScheme === 'number' ? CARD_TYPE_ICONS[cardScheme] : null;
        return CardIcon ? (
          <StyledCardIconWrapper hasMarginLeft>
            <CardIcon />
          </StyledCardIconWrapper>
        ) : null;
      }

      const dropdownType = fieldControl?.type?.value;
      const DropdownIcon = DROPDOWN_ICONS[dropdownType] || null;

      return DropdownIcon ? (
        <StyledIconsContainer hasMarginLeft>
          <DropdownIcon />
        </StyledIconsContainer>
      ) : null;
    },
    [selected, options, fieldControl]
  );

  const optionsFiltered = useMemo(() => options?.filter((option) => option.value !== ADD_NEW_OPTION_VALUE), [options]);
  const addNewOption = useMemo(() => options?.find((option) => option.value === ADD_NEW_OPTION_VALUE), [options]);

  const onAddNewHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (addNewOption?.forVerificationFormControls) {
      getVerificationFormControls();
    } else if (stepUrl && stepId) {
      getFormInitialStepControls(stepUrl, {
        siteId, paymentSystemId, paymentMethodType: operationType, stepId
      });
    }
  }, [addNewOption, stepUrl, stepId]);

  const handleSelectChange = (selectedOption) => {
    setSelected(selectedOption.value);
  };

  const customNoOptionsMessage = () => (
    <div className="custom-no-options-message">
      {getControlPropertyDescriptions.nooptions}
    </div>
  );

  const onDeleteOption = useCallback(
    (option) => {
      const url = fieldControl?.deleteactionurl?.value;
      if (url) {
        deleteSingleOption({
          url,
          siteId,
          platformPaymentSystemId,
          paymentSystemId,
          unbindProcessData: JSON.stringify(option),
          userId,
          languageId: language
        }).then(() => {
          if (fieldControl?.url?.value) {
            getDropdownListOptions({
              token,
              siteId,
              userId,
              country,
              currency,
              isOffline,
              alertContext,
              paymentSystemId,
              languageId: language,
              platformPaymentSystemId,
              url: fieldControl.url.value
            }).then((response) => {
              setOptions(response?.length ? response : []);
              setSelected(response?.length ? response[0].value : null);
              // eslint-disable-next-line no-shadow
              const rightSideOptions = response?.filter(({value}) => value !== ADD_NEW_OPTION_VALUE) || null;
              paymentRightSideOptionsCallbacks.invokeCallback(PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES.SET_OPTIONS, {
                id: name, title: name, optionsList: rightSideOptions
              });
            });
          }
        });
      }
    },
    [fieldControl]
  );

  useEffect(() => {
    const selectedOption = options?.find((option) => option.value === selected);
    setFieldValue(
      name,
      JSON.stringify(selectedOption)
    );
    if (selectedOption?.cardExpirationDate) {
      setFieldValue(EXPIRATION_DATE_FIELD_NAME, selectedOption.cardExpirationDate);
    }
  }, [options, selected]);

  /**
   * Fetch data for the first dropdown when the component mounts
   */
  useEffect(() => {
    if (fieldControl?.url?.value) {
      getDropdownListOptions({
        token,
        siteId,
        userId,
        country,
        currency,
        isOffline,
        alertContext,
        paymentSystemId,
        languageId: language,
        platformPaymentSystemId,
        url: fieldControl.url.value
      }).then((response) => {
        if (response?.length) {
          setOptions(response);
          setSelected(response[0].value);
          if (fieldControl?.deleteactionurl?.value) {
            // eslint-disable-next-line no-shadow
            const rightSideOptions = response.filter(({value}) => value !== ADD_NEW_OPTION_VALUE);
            if (rightSideOptions?.length) {
              paymentRightSideOptionsCallbacks.invokeCallback(PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES.SET_OPTIONS, {
                id: name, title: name, optionsList: rightSideOptions
              });
              paymentRightSideOptionsCallbacks.addCallback(PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES.DELETE_OPTION, onDeleteOption);
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!value && !!previousValue) setSelected('');
  }, [value, previousValue]);

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledFlexContainerDynamic
      gap="12px"
      overflow="initial"
      alignItem="center"
    >
      <StyledInputContainer>
        <Select
          id={name}
          key={name}
          name={name}
          placeholder={getControlPropertyDescriptions.placeholder}
          noOptionsMessage={customNoOptionsMessage}
          options={optionsFiltered}
          defaultValue={[optionsFiltered[0]]}
          value={optionsFiltered?.find((option) => option?.value === selected)}
          onChange={handleSelectChange}
          className={error ? 'react-select hasError' : 'react-select'}
          classNamePrefix="react-select"
          styles={templateTwoCustomStyles(theme)}
          components={{
          // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
            Control: ({children, ...props}) => (
              <components.Control {...props}>
                {Icon}
                {children}
              </components.Control>
            ),
            // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
            Option: ({
            // eslint-disable-next-line react/prop-types
              data, innerRef, innerProps
            }) => {
              // eslint-disable-next-line react/prop-types,no-shadow
              const {cardScheme, label, value} = data;
              const CardIcon = typeof cardScheme === 'number' ? CARD_TYPE_ICONS[cardScheme] : null;

              return (
                <StyledSelectOptionsItem
                  ref={innerRef}
                  {...innerProps}
                >
                  <StyledFlexContainerDynamic
                    gap="12px"
                    alignItem="center"
                  >
                    {CardIcon && (
                      <StyledCardIconWrapper>
                        <CardIcon />
                      </StyledCardIconWrapper>
                    )}
                    <span>{label}</span>
                  </StyledFlexContainerDynamic>
                  {value === selected ? (
                    <CheckBigIcon />
                  ) : null}
                </StyledSelectOptionsItem>
              );
            },
            DropdownIndicator: components.DropdownIndicator,
            IndicatorSeparator: () => null
          }}
        />
        {error ? <StyledInputError>{error}</StyledInputError> : null}
      </StyledInputContainer>
      {!!addNewOption ? (
        <StyledButtonWithBorderTemplateTwo type="button" onClick={onAddNewHandler}><AddIcon /></StyledButtonWithBorderTemplateTwo>
      ) : null}
    </StyledFlexContainerDynamic>
    );
});

NewDynamicDropdownField.propTypes = {
  error: PropTypes.string,
  stepId: PropTypes.string,
  stepUrl: PropTypes.string,
  fieldControl: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isOffline: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired
  // isMobile: PropTypes.bool.isRequired,
  // showVerificationHistoryIcon: PropTypes.bool,
  // hasVerificationHistory: PropTypes.bool
};

NewDynamicDropdownField.defaultProps = {
  error: '',
  stepId: null,
  stepUrl: null,
  fieldControl: null
  // showVerificationHistoryIcon: true,
  // hasVerificationHistory: false
};

/**
 * Document Upload Field
 */
const DocumentUploadField = memo(
  ({
    name,
    isMobile,
    alertContext,
    controlProperties
  }) => {
    const {t} = useTranslation();

    const {
      fileformat,
      filesize,
      placeholder,
      uploaddocumentcount,
      validationfileformatmessage,
      validationfilesizemessage
    } = controlProperties;

    const maxUploadCount = Number(uploaddocumentcount);

    const {uploadedFiles: files, setUploadedFiles: setFiles} = useContext(UploadedFilesContext);

    const previousUploadedFiles = usePrevious(files);

    const [uploadedFiles, setUploadedFiles] = useState(
      Array.from({length: maxUploadCount}).reduce((acc, _, index) => {
        acc[index] = null;
        return acc;
      }, {})
    );

    const uploadedFilesValues = useMemo(() => Object.values(uploadedFiles), [uploadedFiles]);
    const uploadedFileValuesFiltered = useMemo(() => uploadedFilesValues.filter((file) => file !== null), [uploadedFilesValues]);

    const onChange = async(e, index) => {
      e.preventDefault();
      const file = e.target.files[0];

      if (!file) return;

      // const firstEmptyIndex = uploadedFilesValues.indexOf(null);
      const buffer = await file?.arrayBuffer();
      const base64String = arrayBufferToBase64(buffer);

      const fileName = file?.name?.split('.')[0];
      const fileFormat = file?.name?.split('.')
        ?.pop()
        ?.toLowerCase();

      const fileSize = Number(filesize.split(/(\d+)/)
        .filter(Boolean)[0]);

      if (!!fileName && !!fileFormat) {
        if (!fileformat.includes(fileFormat)) {
          alertContext.error(validationfileformatmessage);
        } else if (file.size / 1024 / 1024 > Number(fileSize)) {
          alertContext.error(validationfilesizemessage);
        } else {
          const transformedData = {
            imageName: fileName,
            imageFormat: fileFormat,
            imageBase64Format: base64String,
            imagePreviewUrl: URL.createObjectURL(file)
          };
          setUploadedFiles((prev) => ({...prev, [/* firstEmptyIndex*/index]: transformedData}));
        }
      }
    };

    useEffect(() => {
      setFiles(uploadedFileValuesFiltered.map(({imageName, imageFormat, imageBase64Format}) => ({imageName, imageFormat, imageBase64Format})));
    }, [files, setFiles, uploadedFileValuesFiltered]);

    useEffect(() => {
      if (previousUploadedFiles?.length > 0 && files?.length === 0) {
        setUploadedFiles(Array.from({length: maxUploadCount}).reduce((acc, _, index) => {
          acc[index] = null;
          return acc;
        }, {}));
      }
    }, [previousUploadedFiles, maxUploadCount, files]);

    return (
      <StyledUploadDocumentWrapper>
        <StyledUploadDocumentLabel>
          <UploadIcon />
          {`${placeholder} ${t('format')}: ${fileformat.split('.').join('').toUpperCase()} ${t('maxsize')}: ${filesize}MB`}
        </StyledUploadDocumentLabel>
        <StyledUploadedDocumentList>
          {uploadedFilesValues?.map((file, index) => (
            <>
              <UploadedFileFieldForTemplateTwo
                // eslint-disable-next-line react/no-array-index-key
                key={`${file?.imageName}-${index}`}
                isMobile={isMobile}
                fileName={file?.imageName}
                fileIndex={index}
                fileFormat={file?.imageFormat}
                filePreviewUrl={file?.imagePreviewUrl}
                deleteFileHandler={setUploadedFiles}
                htmlFor={`upload-input-${index}`}
              />
              {uploadedFileValuesFiltered.length < maxUploadCount && !file?.imagePreviewUrl ? (
                <input
                  type="file"
                  name={`upload-input-${index}`}
                  id={`upload-input-${index}`}
                  onChange={(e) => onChange(e, index)}
                  accept={fileformat}
                  hidden
                />
              ) : null}
            </>
          ))}
        </StyledUploadedDocumentList>
      </StyledUploadDocumentWrapper>
    );
  }
);

DocumentUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired,
  controlProperties: PropTypes.object.isRequired
};

const LabelField = function({
  getControlPropertyDescriptions,
  fieldControl,
  ...props
}) {
  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledLabelInfoWrapper {...props}>
      <StyledLabelInfo
        dangerouslySetInnerHTML={{
          __html: getControlPropertyDescriptions.text
        }}
      />
    </StyledLabelInfoWrapper>
    );
};

LabelField.propTypes = {
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  fieldControl: PropTypes.object.isRequired
};

export const templateTwoSpecificFields = {
  NewDynamicDropdownField,
  DocumentUploadField,
  LabelField
};
