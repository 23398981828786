import React, {
  Fragment, useCallback, useContext, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import EnhancedFormik from '../../common/enhancedFormik';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import Slider from '../../../slider/slider';
import GenerateCode from '../../../generateCode/generateCode';
import RenderPaymentForm from '../../common/renderPaymentForm';
import HistoryNavigateButton from '../../../historyNavigateButton/historyNavigateButton';
import PaymentInfoWeb from './paymentInfoWeb';
import GeneratedActiveCodesList from '../../../generatedActiveCodesList/generatedActiveCodesList';
import {formWebHOC} from '../../../../HOC/formWebHOC';
import FormDescription from '../../../paymentsDetails/templateOne/paymentsDetails';
import PaymentBonusesListWeb from '../../../paymentBonusesList/paymentBonusesListWeb';

/* Styled Components */
import {StyledFlexContainerForPsForm, StyledMainFormAndInfoWrapper} from './formWeb.styled';
import {StyledMainWrapper} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledFormWrapper} from '../../../../styledComponents/input/input.styled';
import {StyledGenerateCodeWrapper} from '../../../paymentsHistory/templateOne/web/historyWeb.styled';

function FormWeb({
  paymentBalances,
  paymentLimits,
  paymentGeneratedCodeInfo,
  paymentDescription,
  applePaySession,
  baseUrl,
  alertContext,
  isCurrencySymbol,
  translations,
  payPalTransactionData,
  googlePayTransactionData,
  isDefaultAvailable,
  deviceType,
  paymentSources,
  paymentControlsData,
  activeCodesList,
  showActiveCodesList,
  // newly added props
  isDefault,
  defaultToggleHandler,
  showGeneratedCodeInfo,
  closeActiveCodeListHandler,
  goBackToCodeGeneratorHandler,
  templateType
}) {
  const {operationType, currency} = useContext(CashierDetailsContext);
  const {t} = useTranslation();
  const [dataName, setDataName] = useState('');
  const [showPaymentBonusesForWeb, setShowPaymentBonusesForWeb] = useState(false);

  const openPaymentFormBonusesForWeb = useCallback(() => {
    setShowPaymentBonusesForWeb(true);
  }, []);
  const onCloseAllBonusesForWebHandler = useCallback(() => {
    setShowPaymentBonusesForWeb(false);
  }, []);

  return (
    <StyledMainWrapper>
      <Slider />
      <StyledFlexContainerForPsForm>
        <StyledFormWrapper>
          <PaymentInfoWeb
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
            paymentSystemName={paymentControlsData?.paymentSystemName}
            processingTimeDetail={paymentControlsData?.processingTimeDetail}
          />
          <StyledMainFormAndInfoWrapper>
            {/* eslint-disable-next-line no-nested-ternary */}
            {paymentSources?.length ? (
              <StyledGenerateCodeWrapper>
                <GenerateCode
                  setDataName={setDataName}
                  codeDetailsData={paymentSources}
                  translations={translations}
                  alertContext={alertContext}
                  currency={currency}
                  isCurrencySymbol={isCurrencySymbol}
                  dataName={dataName}
                />
              </StyledGenerateCodeWrapper>
            // eslint-disable-next-line no-nested-ternary
            ) : showGeneratedCodeInfo ? (
              <GeneratedActiveCodesList
                translations={translations}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                codesList={[{data: paymentGeneratedCodeInfo}]}
                onGoBackHandler={goBackToCodeGeneratorHandler}
              />
            ) : showActiveCodesList && activeCodesList?.length > 0 ? (
              <GeneratedActiveCodesList
                codesList={activeCodesList}
                onGoBackHandler={closeActiveCodeListHandler}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            ) : (
              <>
                <EnhancedFormik
                  baseUrl={baseUrl}
                  applePaySession={applePaySession}
                  alertContext={alertContext}
                  payPalTransactionData={payPalTransactionData}
                  googlePayTransactionData={googlePayTransactionData}
                  formComponent={RenderPaymentForm}
                  deviceType={deviceType}
                  currency={currency}
                  isCurrencySymbol={isCurrencySymbol}
                  paymentControlsData={paymentControlsData}
                  templateType={templateType}
                  openPaymentFormBonusesForWeb={openPaymentFormBonusesForWeb}
                />
                {paymentControlsData?.hasHistory ? <HistoryNavigateButton /> : null}
              </>
            )}
          </StyledMainFormAndInfoWrapper>
        </StyledFormWrapper>
        {showPaymentBonusesForWeb ? (
          <PaymentBonusesListWeb
            onCloseListHandler={onCloseAllBonusesForWebHandler}
          />
        ) : (
          <FormDescription
            paymentLimits={paymentLimits}
            paymentBalances={paymentBalances}
            isDefaultAvailable={isDefaultAvailable}
            isDefault={isDefault}
            defaultToggleHandler={defaultToggleHandler}
            paymentDescription={paymentDescription}
            operationType={operationType}
            currency={currency}
            t={t}
          />
        )}
      </StyledFlexContainerForPsForm>
    </StyledMainWrapper>
  );
}

FormWeb.defaultProps = {
  paymentGeneratedCodeInfo: null,
  paymentDescription: null,
  // paymentSystemId: null,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  paymentBalances: null,
  paymentLimits: null,
  isDefaultAvailable: false,
  paymentSources: null,
  activeCodesList: [],
  showActiveCodesList: false,
  templateType: 1
};

FormWeb.propTypes = {
  deviceType: PropTypes.number.isRequired,
  paymentGeneratedCodeInfo: PropTypes.array,
  paymentDescription: PropTypes.array,
  // paymentSystemId: PropTypes.number,
  applePaySession: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  paymentBalances: PropTypes.object,
  paymentLimits: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  paymentSources: PropTypes.array,
  paymentControlsData: PropTypes.object.isRequired,
  activeCodesList: PropTypes.array,
  showActiveCodesList: PropTypes.bool,
  // newly added props
  isDefault: PropTypes.bool.isRequired,
  showGeneratedCodeInfo: PropTypes.bool.isRequired,
  defaultToggleHandler: PropTypes.func.isRequired,
  closeActiveCodeListHandler: PropTypes.func.isRequired,
  goBackToCodeGeneratorHandler: PropTypes.func.isRequired,
  templateType: PropTypes.number
};
export default formWebHOC(FormWeb);
