import instance from './apiService';
import Config from '../configs/mainConfig';

/**
 * Retrieves details of the active template for a specific site.
 *
 * @param {function} setSiteActiveTemplateDetail - A function to set the retrieved active template details.
 * @param {function} setCustomizationTheme - A function to set the customization theme based on the active template.
 * @param {number} siteId - The numeric identifier of the site.
 * @param {string} templateMode - The identifier of the cashier mode.
 * @param {function} setIsLoading - A function to set the loading status.
 * @param {object} alertContext - The context object for displaying alerts.
 * @param {function} alertContext.error - A function to display an error alert.
 *
 * @returns {Promise} - A promise that resolves with the retrieved active template details
 *                    or rejects with an error message.
 *
 * @throws {Error} - If the request to the API fails, an error object is thrown.
 *
 * @example
 * const setSiteActiveTemplateDetail = (templateDetails) => {
 *   // Update UI with retrieved active template details
 * };
 *
 * const setCustomizationTheme = (themeDetails) => {
 *   // Update UI with customization theme details
 * };
 *
 * const siteId = 123;
 * const setIsLoading = (isLoading) => {
 *   // Set loading status in the UI
 * };
 * const alertContext = {
 *   error: (errorMessage) => {
 *     // Display an error alert in the UI
 *     console.error(errorMessage);
 *   }
 * };
 *
 * getSiteActiveTemplateDetail(setSiteActiveTemplateDetail, setCustomizationTheme, siteId, setIsLoading, alertContext)
 *   .then((templateDetails) => {
 *     // Handle retrieved active template details
 *   })
 *   .catch((error) => {
 *     console.error('Failed to retrieve active template details:', error.message);
 *   });
 */
export const getSiteActiveTemplateDetail = (
  setSiteActiveTemplateDetail,
  setCustomizationTheme,
  siteId,
  templateMode,
  setIsLoading,
  alertContext
) => {
  setIsLoading(true);
  return instance.get(
    `${Config.apiURLs.getSiteActiveTemplateDetail}/${siteId}`,
    {
      param: {
        siteId
      }
    }
  )
    .then((response) => {
      const {data} = response;
      setSiteActiveTemplateDetail(data);

      const contentObject = JSON.parse(data.content);
      const activeMode = contentObject?.common?.defaultMode;
      const finalTheme = contentObject?.common && contentObject?.[activeMode]
        ? {...contentObject.common, ...contentObject?.[activeMode], activeMode}
        : {...contentObject};

      setCustomizationTheme(finalTheme);
      return data;
    })
    .catch((error) => {
      alertContext.error(error?.response?.data);
      throw error;
    });
};
