import instance from './apiService';

export const getFormInitialStepControls = async(url, param) => {
  const {
    siteId, paymentSystemId, paymentMethodType, stepId
  } = param;
  const res = await instance.get(
    `${url}/${siteId}/${paymentSystemId}/${paymentMethodType}/${stepId}`,
    {param}
  );

  return res;
};
