import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

/* Styled Components */
import {
  StyledBonusListItem,
  StyledCardFigure,
  StyledInfoWrapper
} from '../bonusesList/templateOne/web/bonusListWeb.styled';
import {StyledFlexContainerDynamic} from './paymentBonusesListWeb.styled';
import {StyledClaimBonusBtn} from '../../styledComponents/button/button.styled';
import {
  StyledCardFigcaptionDescriptionMobile,
  StyledCardFigcaptionTitleMobile, StyledPictureMobile
} from '../bonusesList/templateOne/mobile/bonusesListMobile.styled';

function PaymentBonusesListItem({
  imageUrl,
  name,
  description,
  isSelected,
  onSelectHandler,
  onCancelHandler
}) {
  const {t} = useTranslation();

  return (
    <StyledBonusListItem
      as="div"
      nestedFormPage
    >
      <StyledCardFigure>
        <StyledPictureMobile>
          <img src={imageUrl} alt={name} />
        </StyledPictureMobile>
      </StyledCardFigure>
      <StyledInfoWrapper>
        <StyledFlexContainerDynamic direction="column" gap="4px" overflow="hidden">
          <StyledCardFigcaptionTitleMobile>{name}</StyledCardFigcaptionTitleMobile>
          <StyledCardFigcaptionDescriptionMobile>{description}</StyledCardFigcaptionDescriptionMobile>
        </StyledFlexContainerDynamic>
        <StyledClaimBonusBtn
          isSelected={isSelected}
          type="button"
          onClick={isSelected ? onCancelHandler : onSelectHandler}
        >
          {t(isSelected ? 'Cancel' : 'Claim')}
        </StyledClaimBonusBtn>
      </StyledInfoWrapper>
    </StyledBonusListItem>
  );
}

PaymentBonusesListItem.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectHandler: PropTypes.func.isRequired,
  onCancelHandler: PropTypes.func.isRequired
};

export default memo(PaymentBonusesListItem);
