import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {OPERATION_TYPE_BY_NUMBER} from '../../../constants/common';
import {CashierDetailsContext} from '../../../contexts/cashierDetailsContext';
import PaymentLimitsTemplateTwo from '../../paymentLimits/templateTwo/paymentLimits';
import PaymentBalancesTemplateTwo from '../../paymentBalances/templateTwo/paymentBalances';

/* Styled Components */
import {
  StyledLinkItemTemplateTwo,
  StyledPaymentInfoParagraph
} from '../../paymentsForm/templateOne/web/formWeb.styled';
import {
  StyledAccordionContent,
  StyledAccordionHeader,
  StyledAccordionTitle,
  StyledPaymentDetailsItemWrapper
} from './paymentsDetails.styled';
import {StyledFlexContainerDynamic} from '../../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledIconSmall} from '../../../styledComponents/icon/icon.styled';

function FormDetailsTemplateTwo({
  paymentLimits,
  paymentBalances,
  paymentDescription,
  paymentSystemName
}) {
  const {operationType, currency} = useContext(CashierDetailsContext);
  const {t} = useTranslation();

  const accordionData = [{
    title: `${t('about').toLowerCase()} ${paymentSystemName}`,
    content: paymentDescription?.desctiption && (
      <StyledPaymentInfoParagraph
        as="p"
        dangerouslySetInnerHTML={{
          __html: paymentDescription?.desctiption
        }}
      />
    )
  },
  {
    title: operationType === OPERATION_TYPE_BY_NUMBER.DEPOSIT
      ? t('howToDeposit'.toLowerCase())
      : t('howToWithdrawal'.toLowerCase()),
    content: paymentDescription?.howTo && (
    <>
      <StyledPaymentInfoParagraph
        as="p"
        dangerouslySetInnerHTML={{
          __html: paymentDescription?.howTo
        }}
      />
      {paymentDescription?.paymentInfoUrl && (
      <StyledLinkItemTemplateTwo
        href={paymentDescription?.paymentInfoUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {paymentDescription?.paymentInfoUrl}
      </StyledLinkItemTemplateTwo>
      )}
    </>
    )
  }
  ];

  const [activeIndices, setActiveIndices] = useState(accordionData.map((_, index) => index));

  const toggleAccordion = (index) => {
    setActiveIndices((prev) => (prev.includes(index)
      ? prev.filter((i) => i !== index)
      : [...prev, index]));
  };

  return (
    <StyledFlexContainerDynamic direction="column" gap="12px">
      {paymentLimits && (
      <StyledPaymentDetailsItemWrapper>
        <PaymentLimitsTemplateTwo
          currency={currency}
          playerCurrentLimit={paymentLimits?.playerCurrentLimit}
          playerLimit={paymentLimits?.playerLimit}
        />
      </StyledPaymentDetailsItemWrapper>
      )}
      {paymentBalances && (
        <StyledPaymentDetailsItemWrapper>
          <PaymentBalancesTemplateTwo {...paymentBalances} />
        </StyledPaymentDetailsItemWrapper>
      )}
      {accordionData.map((item, index) => {
        if (!item.content) return null;
        return (
          <StyledPaymentDetailsItemWrapper>
            <StyledAccordionHeader onClick={() => toggleAccordion(index)}>
              <StyledAccordionTitle>{item.title}</StyledAccordionTitle>
              <StyledIconSmall icon={activeIndices.includes(index) ? 'arrowDown' : 'arrowRight'} fontSize="11px" />
            </StyledAccordionHeader>
            {activeIndices.includes(index) && (
              <StyledAccordionContent>{item.content}</StyledAccordionContent>
            )}
          </StyledPaymentDetailsItemWrapper>
        );
      })}
    </StyledFlexContainerDynamic>
  );
}

FormDetailsTemplateTwo.propTypes = {
  paymentLimits: PropTypes.object,
  paymentBalances: PropTypes.object,
  paymentDescription: PropTypes.object,
  paymentSystemName: PropTypes.string
};

FormDetailsTemplateTwo.defaultProps = {
  paymentLimits: null,
  paymentBalances: null,
  paymentDescription: null,
  paymentSystemName: null

};

export default FormDetailsTemplateTwo;
