import styled from 'styled-components';
import {StyledItemLabel, StyledItemValue} from '../../paymentsHistory/templateOne/web/historyWeb.styled';

const StyledItemLabelTemplateTwo = styled(StyledItemLabel)`
  color: ${({theme}) => theme.textTitleColor};
`;

const StyledItemValueTemplateTwo = styled(StyledItemValue)`
  color: ${({theme}) => theme.textContentColor};
`;

export {
  StyledItemLabelTemplateTwo,
  StyledItemValueTemplateTwo
};
