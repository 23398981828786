import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {paymentRightSideOptionsCallbacks} from '../../helpers/common';
import {CARD_TYPE_ICONS, PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES} from '../../constants/common';

// assets
import {DeleteIcon} from '../../assets/icons';

// styled
import {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';
import {
  StyledCardNumber,
  StyledFlipAlertActions,
  StyledFlipAlertMessage,
  StyledFlipAlertWrapper,
  StyledFlipCard,
  StyledFlipCardContainer,
  StyledTextWithoutBg
} from '../tabs/tabs.styled';
import {StyledFlexContainerDynamic} from '../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledPaymentCard, StyledPaymentCardPicture} from '../bonusPaymentItem/bonusPaymentItem.styled';

function RightSideDeletableOptions(props) {
  const {name, cardScheme} = props;

  const {t} = useTranslation();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const CardIcon = typeof cardScheme === 'number' ? CARD_TYPE_ICONS[cardScheme] : null;

  const onDeleteHandler = () => {
    paymentRightSideOptionsCallbacks.invokeCallback(PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES.DELETE_OPTION, props);
  };

  return (
    <StyledFlipCardContainer>
      <StyledFlipCard showConfirmation={showConfirmation}>
        <StyledPaymentCard
          as="figure"
          isFlipCard
        >
          <StyledFlexContainerDynamic
            gap="8px"
            alignItem="center"
          >
            <StyledPaymentCardPicture>
              <CardIcon />
            </StyledPaymentCardPicture>
            <figcaption>
              <StyledCardNumber>{name}</StyledCardNumber>
              {/* <StyledPaymentHint>verified labels</StyledPaymentHint> */}
            </figcaption>
          </StyledFlexContainerDynamic>
          <StyledButtonWithoutBg
            type="button"
            onClick={() => setShowConfirmation(true)}
          >
            <DeleteIcon />
          </StyledButtonWithoutBg>
        </StyledPaymentCard>

        <StyledFlipAlertWrapper
          as="figure"
          isFlipCard
        >
          <StyledFlipAlertMessage>{t('deleteConfirmationMessage'.toLowerCase())}</StyledFlipAlertMessage>
          <StyledFlipAlertActions>
            <StyledTextWithoutBg
              type="button"
              cancel
              onClick={() => setShowConfirmation(false)}
            >
              {t('cancel').toLowerCase()}
            </StyledTextWithoutBg>
            <StyledTextWithoutBg
              type="button"
              onClick={onDeleteHandler}
            >
              {t('delete').toLowerCase()}
            </StyledTextWithoutBg>
          </StyledFlipAlertActions>
        </StyledFlipAlertWrapper>
      </StyledFlipCard>
    </StyledFlipCardContainer>
  );
}

RightSideDeletableOptions.propTypes = {
  BankId: PropTypes.string,
  BankName: PropTypes.string,
  cardScheme: PropTypes.number,
  cardMethodType: PropTypes.number,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired
};

RightSideDeletableOptions.defaultProps = {
  BankId: null,
  BankName: null,
  cardScheme: 0,
  cardMethodType: null
};

export default RightSideDeletableOptions;
