import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import MobileModal from '../mobileModal/mobileModal';
import PaymentBonusesListItem from './paymentBonusesListItem';

/* Styled Components */
import {StyledFlexContainerDynamic} from './paymentBonusesListWeb.styled';

function PaymentBonusesListMobile({
  showInfo,
  bonusesList,
  selectedBonusId,
  onSelectBonusHandler,
  onCancelBonusHandler,
  onCloseListHandler
}) {
  const {t} = useTranslation();

  return (
    <MobileModal
      title={t(('bonuses').toLowerCase())}
      showInfo={showInfo}
      handleCloseInfo={onCloseListHandler}
    >
      <StyledFlexContainerDynamic direction="column" gap="8px">
        {bonusesList.map(({
          id, shortDescription, name, webImage
        }) => {
          const isSelected = selectedBonusId === id;

          return (
            <PaymentBonusesListItem
              key={id}
              name={name}
              imageUrl={webImage}
              description={shortDescription}
              isSelected={isSelected}
              onSelectHandler={onSelectBonusHandler(id)}
              onCancelHandler={onCancelBonusHandler}
            />
          );
        })}
      </StyledFlexContainerDynamic>
    </MobileModal>
  );
}

PaymentBonusesListMobile.propTypes = {
  selectedBonusId: PropTypes.number,
  showInfo: PropTypes.bool.isRequired,
  bonusesList: PropTypes.array.isRequired,
  onCloseListHandler: PropTypes.func.isRequired,
  onSelectBonusHandler: PropTypes.func.isRequired,
  onCancelBonusHandler: PropTypes.func.isRequired
};

PaymentBonusesListMobile.defaultProps = {
  selectedBonusId: null
};

export default memo(PaymentBonusesListMobile);
