import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PaymentCardWeb from '../web/paymentCardWeb';
import RenderPaymentForm from '../../common/renderPaymentForm';
import EnhancedFormik from '../../common/enhancedFormik';
import {renderProcessingTime} from '../../../../helpers/common';
import {DEFAULT_PAYMENT_ALERTS, DEVICE_TYPE} from '../../../../constants/common';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {AccountVerificationHistoryContext} from '../../../../contexts/accountVerificationHistoryContext';
import PaymentSelectDeletableOptions from '../../../paymentSelectDeletableOptions/paymentSelectDeletableOptions';
import GeneratedActiveCodesList from '../../../generatedActiveCodesList/generatedActiveCodesList';
import Tabs from '../../../tabs/tabs';
import Tab from '../../../tabs/tab';
import MobileModal from '../../../mobileModal/mobileModal';
import {templateTwoFormRightSideOptionsHOC} from '../../../../HOC/templateTwoFormRightSideOptionsHOC';
import {CardIcon, DetailsIcon} from '../../../../assets/icons';
import FormDetailsTemplateTwo from '../../../paymentsDetails/templateTwo/paymentsDetails';
import GenerateCode from '../../../generateCode/generateCode';

/* Styled Components */
import {
  StyledPaymentItemLabel,
  StyledPaymentItemValue
} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import {StyledButtonWithoutBg} from '../../../../styledComponents/button/button.styled';
import {StyledPaymentFormDefaultWrapper} from '../web/formWeb.styled';
import {StyledBackToText} from '../../../paymentsDetails/templateTwo/paymentsDetails.styled';
import StyledPaymentFormContainerMobile, {
  StyledPaymentFormInfoWrapperMobile,
  StyledSectionHeaderWrapperMobile
} from './formMobile.styled';
import {
  StyledCheckbox,
  StyledCheckboxInputLabel,
  StyledCheckboxInputWrapper,
  StyledCheckboxWrapper
} from '../../../../styledComponents/input/input.styled';
import {StyledGenerateCodeWrapper} from '../../../paymentsHistory/templateOne/web/historyWeb.styled';

function FormMobileTemplateTwo({
  paymentRightSideOptions,
  paymentGeneratedCodeInfo,
  setPaymentGeneratedCodeInfo,
  applePaySession,
  baseUrl,
  alertContext,
  isCurrencySymbol,
  payPalTransactionData,
  googlePayTransactionData,
  isDefaultAvailable,
  onPaymentDefaultStatusUpdateHandler,
  paymentControlsData,
  activeCodesList,
  showActiveCodesList,
  paymentData,
  translations,
  paymentLimits,
  paymentBalances,
  paymentDescription,
  paymentSources,
  templateType
}) {
  const {t} = useTranslation();

  const {
    operationType, currency, siteId, userId
  } = useContext(CashierDetailsContext);
  const {isDefault: initialIsDefault, paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {backToListHandler, openActiveCodesList, getVerificationHistory} = useContext(PaymentFormCallbacksContext);
  const [dataName, setDataName] = useState('');

  const [showGeneratedCodeInfo, setShowGeneratedCodeInfo] = useState(false);
  const [verificationHistory, setVerificationHistory] = useState([]);
  const [isDefault, setIsDefault] = useState(initialIsDefault || false);
  const [openModal, setOpenModal] = useState(false);

  const verificationHistoryContextMemoized = useMemo(() => ({
    getVerificationHistoryHandler: () => {
      getVerificationHistory({
        siteId,
        userId,
        paymentSystemId,
        setVerificationHistory
      });
    },
    clearVerificationHistoryHandler: () => {
      setVerificationHistory([]);
    },
    verificationHistory
  }), [verificationHistory]);

  const tabData = useMemo(() => {
    const defaultTabs = [
      {
        id: 'details',
        title: t(('details').toLowerCase()), // You can use a translation function here
        icon: <DetailsIcon />, // Icon component from react-icons
        content: <FormDetailsTemplateTwo
          paymentLimits={paymentLimits}
          paymentBalances={paymentBalances}
          paymentDescription={paymentDescription}
          paymentSystemName={paymentControlsData?.paymentSystemName}
        />
      }
    ];

    if (paymentRightSideOptions) {
      const {id, title, optionsList} = paymentRightSideOptions;
      defaultTabs.push({
        id,
        title,
        icon: <CardIcon />,
        content: optionsList?.length ? <PaymentSelectDeletableOptions optionsList={optionsList} /> : null
      });
    }
    return defaultTabs;
  }, [paymentRightSideOptions, paymentBalances, paymentLimits, paymentDescription, paymentControlsData]);

  const closeActiveCodeListHandler = useCallback(() => {
    openActiveCodesList(false);
  }, [openActiveCodesList]);

  const backToGeneratorHandler = useCallback(() => {
    if (typeof setPaymentGeneratedCodeInfo === 'function') {
      setPaymentGeneratedCodeInfo([]);
    }
    setShowGeneratedCodeInfo(false);
  }, [setPaymentGeneratedCodeInfo]);

  const defaultToggleHandler = useCallback(() => {
    onPaymentDefaultStatusUpdateHandler({
      isDefault: !isDefault,
      callback: (res) => {
        if (res.data) {
          alertContext?.success(t((DEFAULT_PAYMENT_ALERTS[operationType][isDefault ? 'unSet' : 'set']).toLowerCase()));
          setIsDefault((prev) => !prev);
        } else {
          alertContext?.error(t((DEFAULT_PAYMENT_ALERTS[operationType].error).toLowerCase()));
        }
      }
    });
  }, [isDefault, alertContext]);

  /**
   * Effect for handling initialIsDefault change in case of payment details change
   */
  useEffect(() => {
    setIsDefault(initialIsDefault);
  }, [initialIsDefault, paymentSystemId]);

  /**
   * Effect to handle changes in paymentGeneratedCodeInfo fields length
   */
  useEffect(() => {
    const hasGeneratedInfo = paymentGeneratedCodeInfo?.length;
    setShowGeneratedCodeInfo(!!hasGeneratedInfo);
  }, [paymentGeneratedCodeInfo?.length]);

  return (
    <AccountVerificationHistoryContext.Provider value={verificationHistoryContextMemoized}>
      <StyledPaymentFormContainerMobile>
        <StyledSectionHeaderWrapperMobile>
          <StyledButtonWithoutBg
            key="backToList"
            type="button"
            onClick={backToListHandler}
          >
            <StyledIconSmall icon="arrowLeft" fontSize="11px" />
          </StyledButtonWithoutBg>
          <StyledBackToText>{t(('backToList')?.toLowerCase())}</StyledBackToText>
          <StyledButtonWithoutBg
            key="openModal"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenModal(true);
            }}
          >
            <DetailsIcon />
          </StyledButtonWithoutBg>
        </StyledSectionHeaderWrapperMobile>
        {/* For Opening Details Modal */}
        <StyledPaymentFormInfoWrapperMobile>
          <PaymentCardWeb
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
            expand={() => {}}
            paymentInfo={{
              imagePath: paymentControlsData?.imagePath,
              paymentSystemName: paymentControlsData?.paymentSystemName
            }}
            paymentData={paymentData}
            translations={translations}
          />
          {/* eslint-disable-next-line no-nested-ternary */}
          {paymentSources?.length ? (
            <StyledGenerateCodeWrapper>
              <GenerateCode
                setDataName={setDataName}
                codeDetailsData={paymentSources}
                translations={translations}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                dataName={dataName}
              />
            </StyledGenerateCodeWrapper>
            // eslint-disable-next-line no-nested-ternary
          ) : showGeneratedCodeInfo ? (
            <GeneratedActiveCodesList
              isMobile
              codesList={[{data: paymentGeneratedCodeInfo}]}
              onGoBackHandler={backToGeneratorHandler}
              alertContext={alertContext}
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
            />
          ) : showActiveCodesList && activeCodesList.length > 0 ? (
            <GeneratedActiveCodesList
              isMobile
              codesList={activeCodesList}
              onGoBackHandler={closeActiveCodeListHandler}
              alertContext={alertContext}
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
            />
          ) : (
            <>
              <EnhancedFormik
                baseUrl={baseUrl}
                applePaySession={applePaySession}
                alertContext={alertContext}
                payPalTransactionData={payPalTransactionData}
                googlePayTransactionData={googlePayTransactionData}
                formComponent={RenderPaymentForm}
                deviceType={DEVICE_TYPE.MOBILE}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                paymentControlsData={paymentControlsData}
                templateType={templateType}
              />
              <StyledPaymentFormDefaultWrapper>
                {isDefaultAvailable && (
                  <StyledCheckboxInputWrapper>
                    <StyledCheckboxWrapper>
                      <StyledCheckbox
                        id="label"
                        className="text-input"
                        type="checkbox"
                        onChange={defaultToggleHandler}
                        checked={isDefault}
                      />
                    </StyledCheckboxWrapper>
                    <StyledCheckboxInputLabel htmlFor="label">
                      {t(('setAsDefault')?.toLowerCase())}
                    </StyledCheckboxInputLabel>
                  </StyledCheckboxInputWrapper>
                )}
                {paymentControlsData?.processingTimeDetail && renderProcessingTime(paymentControlsData?.processingTimeDetail, t) !== null ? (
                  <div>
                    <StyledPaymentItemLabel>
                      {t(('transactionTime')?.toLowerCase())}
                      :
                      <StyledPaymentItemValue>
                        {renderProcessingTime(paymentControlsData?.processingTimeDetail, t)}
                      </StyledPaymentItemValue>
                    </StyledPaymentItemLabel>
                  </div>
                ) : null}
              </StyledPaymentFormDefaultWrapper>
            </>
          )}
        </StyledPaymentFormInfoWrapperMobile>
        <MobileModal
          showInfo={openModal}
          handleCloseInfo={() => setOpenModal(false)}
          title={t(('information')?.toLowerCase())}
          templateType={templateType}
        >
          <Tabs>
            {tabData.map((tab) => (
              <Tab key={tab.id} id={tab.id} title={tab.title} icon={tab.icon}>
                {tab.content}
              </Tab>
            ))}
          </Tabs>
        </MobileModal>
      </StyledPaymentFormContainerMobile>
    </AccountVerificationHistoryContext.Provider>
  );
}

FormMobileTemplateTwo.defaultProps = {
  paymentRightSideOptions: null,
  paymentGeneratedCodeInfo: null,
  setPaymentGeneratedCodeInfo: null,
  paymentDescription: null,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  paymentBalances: null,
  paymentLimits: null,
  isDefaultAvailable: false,
  onPaymentDefaultStatusUpdateHandler: null,
  activeCodesList: [],
  showActiveCodesList: false,
  paymentData: null,
  paymentSources: null,
  templateType: null
};

FormMobileTemplateTwo.propTypes = {
  paymentRightSideOptions: PropTypes.object,
  paymentGeneratedCodeInfo: PropTypes.array,
  setPaymentGeneratedCodeInfo: PropTypes.func,
  paymentDescription: PropTypes.array,
  applePaySession: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  paymentBalances: PropTypes.object,
  paymentLimits: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  onPaymentDefaultStatusUpdateHandler: PropTypes.func,
  paymentControlsData: PropTypes.object.isRequired,
  activeCodesList: PropTypes.array,
  showActiveCodesList: PropTypes.bool,
  paymentData: PropTypes.array,
  // newly added props
  // showGeneratedCodeInfo: PropTypes.bool.isRequired,
  // closeActiveCodeListHandler: PropTypes.func.isRequired,
  // goBackToCodeGeneratorHandler: PropTypes.func.isRequired
  paymentSources: PropTypes.array,
  templateType: PropTypes.number
};
export default templateTwoFormRightSideOptionsHOC(FormMobileTemplateTwo);
