import styled from 'styled-components';
import {flexContainer, fontStyle, truncate} from '../../helpers/styled';

const StyledBannerWrapper = styled.section`
  ${flexContainer({
    justifyContent: 'flex-start'
  })};
  min-height: 78px;
  grid-gap: 8px;
  padding: 8px 12px 8px 16px;
  margin-bottom: 8px;
  border-radius: 12px;
  background: ${({theme}) => `linear-gradient(89.27deg, ${theme.surface.surfaceTertiary || '#0B6095'} -1.61%, ${theme.surface.surfaceQuaternary || '#2299E4'} 100%)`};
  border: 1px solid ${({theme}) => theme.border.border};
  cursor: pointer;
  transition: 500ms;

  &:hover {
    padding-left: 20px;
  }
`;

const StyledBannerImageWrapper = styled.picture`
  img {
    max-width: 80px;
    height: 62px;
  }
`;
const StyledBannerInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  
  i {
    color: ${({theme}) => theme.surface.surfaceIcon};
  }

  @media screen and (max-width: 800px) {
    i {
      display: none;
    }
  }
`;

const StyledBannerInfoTitle = styled.h2`
  ${fontStyle('semiBold')};
  font-size: 24px;
  color: ${({theme}) => theme.text.text};
`;

const StyledBannerInfoTitleKey = styled.span`
  ${truncate()};
  color: ${({theme}) => theme.textButtonBackgroundColor};
`;

const StyledCardInfoDescription = styled.p`
  ${truncate()};
  ${fontStyle('regular')};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  color: ${({theme}) => theme.text.textSecondary};
`;

export default StyledBannerWrapper;
export {
  StyledBannerInfoWrapper,
  StyledBannerImageWrapper,
  StyledBannerInfoTitle,
  StyledBannerInfoTitleKey,
  StyledCardInfoDescription
};
