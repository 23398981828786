import {ACCOUNT_VERIFICATION_STATUSES} from './common';
import {defaultTheme} from '../theme/theme';

export const PayPalButtonStyle = {
  layout: 'horizontal',
  color: 'gold',
  label: 'paypal',
  tagline: 'false',
  height: 36
};

export const ACCOUNT_VERIFICATION_STATUSES_COLORS = {
  [ACCOUNT_VERIFICATION_STATUSES.VERIFIED]: {
    backgroundColor: defaultTheme.cardNewLabelBackgroundColor,
    color: defaultTheme.cardNewLabelTextColor
  },
  [ACCOUNT_VERIFICATION_STATUSES.IN_REVIEW]: {
    backgroundColor: defaultTheme.infoColor,
    color: defaultTheme.cardNewLabelTextColor
  },
  [ACCOUNT_VERIFICATION_STATUSES.DECLINED]: {
    backgroundColor: defaultTheme.cardUnderMaintenanceLabelBackgroundColor,
    color: defaultTheme.cardUnderMaintenanceLabelTextColor
  }
};