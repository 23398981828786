import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PaymentItemMobile from './paymentItemMobile';
// import Search from '../../../search/search';
import NavigationTabsMobile from '../../../navigationTabs/navigationTabsMobile/navigationTabsMobile';
import {groupNameMapping} from '../../../../constants/common';
import BonusBanner from '../../../bonusBanner/bonusBanner';
import UpdateProfileNote from '../../../updateProfileNote/updateProfileNote';

/* Styled Components */
import {
  StyledGroupName,
  StyledGroupNameWrapperMobile,
  StyledPaymentItemSection
} from '../web/paymentItemWeb.styled';
import {
  StyledMobileMainWrapper,
  StyledPsMobileWrapper
} from './listMobile.styled';
import {StyledDashedHr} from '../../../../styledComponents/button/button.styled';

function ListMobile({
  paymentData,
  handlePaymentSystemDetailsPage,
  currency,
  isCurrencySymbol,
  // enableSearch,
  customizationTheme,
  deviceType,
  isFavoriteGroup,
  onFavoriteClickHandler,
  hasTabs,
  activeTab,
  setActiveTab,
  translations,
  isBonusBanner,
  bonusBannerUrl,
  goToBonusHandler,
  userAccountNotification
}) {
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isVisible, setIsVisible] = useState(true);
  const [tabVersion, setTabVersion] = useState(customizationTheme?.navigationTabVersion?.value || 'versionOne');

  useEffect(() => {
    if (customizationTheme?.navigationTabVersion?.value) {
      setTabVersion(customizationTheme.navigationTabVersion.value);
    }
  }, [customizationTheme?.navigationTabVersion?.value]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrolledDown = currentScrollPos > prevScrollPos;
      setIsVisible(!isScrolledDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [prevScrollPos]);

  const handleTabClick = (event) => {
    const {name} = event.currentTarget;
    setActiveTab(name);
  };

  return (
    <StyledMobileMainWrapper>
      {isBonusBanner ? (
        <BonusBanner bannerUrl={bonusBannerUrl} goToBonusHandler={goToBonusHandler} />
      ) : null}
      {/* {enableSearch ? <Search /> : null} */}
      {userAccountNotification ? <UpdateProfileNote message={userAccountNotification} isMobile /> : null}
      {paymentData?.map((pasItem, indexOne) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledPaymentItemSection key={`${pasItem.groupId}:${indexOne}`}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {pasItem.groupId !== 0 ? (
            <StyledGroupNameWrapperMobile>
              <StyledGroupName>
                {translations[groupNameMapping[pasItem?.groupId]?.toLowerCase()]}
                <span>{pasItem?.cashierPaymentSystem?.length}</span>
              </StyledGroupName>
            </StyledGroupNameWrapperMobile>
          ) : paymentData.length > 1 ? <StyledDashedHr hasMarginBottom /> : null}
          <StyledPsMobileWrapper>
            {pasItem?.cashierPaymentSystem?.map((paymentInfo, indexTwo) => (
              <PaymentItemMobile
                isCurrencySymbol={isCurrencySymbol}
                /* eslint-disable-next-line react/no-array-index-key */
                key={`${paymentInfo?.paymentSystemId}:${indexTwo}`}
                paymentInfo={paymentInfo}
                handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                isFavoriteGroup={isFavoriteGroup}
                onFavoriteClickHandler={onFavoriteClickHandler}
                currency={currency}
              />
            ))}
          </StyledPsMobileWrapper>
        </StyledPaymentItemSection>
      ))}
      {hasTabs && paymentData?.length ? (
        <NavigationTabsMobile
          tabVersion={tabVersion}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          deviceType={deviceType}
          isVisible={isVisible}
        />
      ) : null}
    </StyledMobileMainWrapper>
  );
}

ListMobile.defaultProps = {
  paymentData: null,
  handlePaymentSystemDetailsPage: null,
  deviceType: null,
  onFavoriteClickHandler: null,
  currency: null,
  // enableSearch: false,
  isFavoriteGroup: false,
  isBonusBanner: false,
  bonusBannerUrl: null,
  userAccountNotification: null
};

ListMobile.propTypes = {
  paymentData: PropTypes.array,
  handlePaymentSystemDetailsPage: PropTypes.func,
  currency: PropTypes.string,
  // enableSearch: PropTypes.bool,
  customizationTheme: PropTypes.object.isRequired,
  deviceType: PropTypes.number,
  isFavoriteGroup: PropTypes.bool,
  onFavoriteClickHandler: PropTypes.func,
  isCurrencySymbol: PropTypes.bool.isRequired,
  hasTabs: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  isBonusBanner: PropTypes.bool,
  bonusBannerUrl: PropTypes.string,
  goToBonusHandler: PropTypes.func.isRequired,
  userAccountNotification: PropTypes.func
};

export default ListMobile;
