import React, {
  memo, useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import MobileModal from '../../mobileModal/mobileModal';
import {CashierDetailsContext} from '../../../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../../../contexts/selectedPaymentDetailsContext';
import {getDropdownOptions} from '../../../services/apiService';
import {
  ADD_NEW_OPTION_VALUE,
  BASE_REQUEST,
  CARD_TYPE_ICONS,
  DROPDOWN_ICONS,
  EXPIRATION_DATE_FIELD_NAME,
  EXPIRATION_DATE_LENGTH,
  FIELDS_CUSTOM_ERROR_NAMES,
  INTENT,
  PAYPAL_CLIENT_ID,
  TRANSACTION_STATUSES
} from '../../../constants/common';
import {
  formatCardNumberString,
  formatDate,
  getCardTypeIcon,
  getCurrencySymbolOrCode, isValidExpirationDate,
  sortArrayByKeyValue,
  timeoutDebounce,
  validateCardNumberInput,
  validateExpirationDateSymbols,
  validateNumericInput
} from '../../../helpers/common';
import {getDropdownListOptions} from '../../../services/getDropdownListOptions';
import {componentMapping, customStyles, generateStatusImage} from '../../../helpers/styled';
import {useOutsideClick} from '../../../hooks/useOutSideClick';
import {AccountVerificationHistoryContext} from '../../../contexts/accountVerificationHistoryContext';
import {PaymentFormCallbacksContext} from '../../../contexts/paymentFormCallbacksContext';
import {SafetyPayPaymentContext} from '../../../contexts/safetyPayPaymentContext';
import {ACCOUNT_VERIFICATION_STATUSES_COLORS, PayPalButtonStyle} from '../../../constants/styled';

/* Styled Components*/
import StyledInputWrapper, {
  StyledCheckbox,
  StyledCheckboxInputLabel,
  StyledCheckboxInputWrapper,
  StyledCheckboxWrapper,
  StyledCountryCodeWrapper,
  StyledFlagContainer,
  StyledIconWrapper,
  StyledInputContainer,
  StyledInputError,
  StyledInputIconWrapper,
  StyledInputLabel,
  StyledInputTemplateOne,
  StyledMessageText,
  StyledStatusMessageWrapper,
  StyledWrapper
} from '../../../styledComponents/input/input.styled';
import StyledButton, {
  StyledButtonWithBorder,
  StyledButtonWithoutBg,
  StyledFavoriteAmountButton,
  StyledFavoriteAmountButtonWrapper,
  StyledFlexContainer,
  StyledGooglePayWrapper,
  StyledLinkButton,
  StyledPayPalButtonWrapper
} from '../../../styledComponents/button/button.styled';
import StyledLabelInfoWrapper, {
  StyledLabelInfo,
  StyledLabelInfoForDynamicLabel
} from '../../../styledComponents/label/label.styled';
import {
  StyledCardIconWrapper,
  StyledCardInfoWrapper,
  StyledCardPositionIconWrapper,
  StyledSelect,
  StyledSelectAccountStatusLabel,
  StyledSelectOption,
  StyledSelectOptionsContainer,
  StyledSelectOptionsHeader,
  StyledSelectOptionsWrapper,
  StyledSelectText,
  StyledSelectWrapper
} from '../../../styledComponents/select/select.styled';
import {StyledIconSmall} from '../../../styledComponents/icon/icon.styled';
import {
  StyledCardHeaderTitle,
  StyledFlexContainerColumn,
  StyledLinkItem
} from '../templateOne/web/formWeb.styled';
import {
  StyledPaymentItemLabel,
  StyledPaymentItemValue,
  StyledQrCodeWrapper
} from '../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {
  StyledCopyContent,
  StyledHistoryDetailsDataWrapper,
  StyledItemLabel,
  StyledItemValue
} from '../../paymentsHistory/templateOne/web/historyWeb.styled';
import {StyledStatusMessageWrapperMobile} from '../templateOne/mobile/formMobile.styled';
import {
  StyledHistoryUploadedWrapper,
  StyledListContent,
  StyledListTitle,
  StyledListWrapper,
  StyledUploadedList
} from '../../../styledComponents/list/list.styled';

/* Assets */
import {CopyIcon, DeleteIcon, VerificationHistoryIcon} from '../../../assets/icons';
import {getTaxes} from '../../../services/getTaxes';
import TaxAndCommissions from '../../TaxAndCommissions/TaxAndCommissions';

const {INVALID_CARD_NUMBER, INVALID_EXPIRATION_DATE} = FIELDS_CUSTOM_ERROR_NAMES;

/**
 * TextBoxField
 */

// Get the components based on the templateType
const generateMappedComponent = (templateType) => componentMapping[templateType]?.StyledInput || StyledInputTemplateOne;

const TextBoxField = memo(
  ({
    id,
    name,
    error,
    value,
    onChange,
    fieldControl,
    getControlPropertyDescriptions,
    favoriteAmounts,
    minMax,
    isMobile,
    currency,
    isCurrencySymbol,
    hasVerificationHistory,
    alertContext,
    templateType,
    ...props
  }) => {
    const theme = useTheme();
    const StyledInput = generateMappedComponent(templateType);
    const {t} = useTranslation();
    const timeoutRef = useRef(null);
    const isAmountInput = useMemo(() => name === 'amount', [name]);
    const [taxInfo, setTaxInfo] = useState({amountWithoutFee: 0, feeAmount: 0});

    const {
      siteId, userId, operationType, language, token
    } = useContext(CashierDetailsContext);
    const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);

    const debouncedTax = timeoutDebounce(getTaxes, timeoutRef, 300);
    const {
      getVerificationHistoryHandler,
      clearVerificationHistoryHandler,
      verificationHistory
    } = useContext(AccountVerificationHistoryContext);

    const handleAmountChange = (selectedValue) => {
      onChange({
        target: {
          name,
          value: selectedValue
        }
      });
    };

    const minMaxError = useMemo(() => {
      const {min, max} = minMax ?? {};
      if (name === 'amount' && min && max && value) {
        if (+value < +min) return 'MinAmountError';
        if (+value > +max) return 'MaxAmountError';
      }
      return null;
    }, [name, value, minMax]);

    const leftSideComponent = useMemo(
      // eslint-disable-next-line no-nested-ternary
      () => (name === 'accountnumber' && hasVerificationHistory ? (
        isMobile ? (
          <VerificationHistoryContainerMobile
            historyData={verificationHistory}
            onClose={clearVerificationHistoryHandler}
          />
        ) : (
          verificationHistory?.length > 0 && (
          <VerificationHistoryContainerWeb
            historyData={verificationHistory}
            onClose={clearVerificationHistoryHandler}
          />
          )
        )
      ) : null),
      [
        name,
        hasVerificationHistory,
        isMobile,
        verificationHistory,
        clearVerificationHistoryHandler
      ]
    );

    useEffect(() => {
      if (value && isAmountInput && !minMaxError && !error) {
        const requestBody = {
          siteId,
          paymentSystemId,
          userId,
          operationType,
          value,
          language,
          token,
          setTaxInfo,
          alertContext
        };
        // debouncedTax({requestBody});
      }
    }, [isAmountInput, value]);

    return fieldControl?.hidden?.value
      && fieldControl?.hidden?.value.toString() === 'true' ? null : (
        <StyledInputWrapper isNotRelative>
          {theme.inputHasLabel ? (
            <StyledInputLabel htmlFor={id} error={error || minMaxError}>
              {getControlPropertyDescriptions.name}
            </StyledInputLabel>
          ) : null}
          <StyledFlexContainer hasGap>
            <StyledInputContainer>
              <StyledInput
                name={name}
                id={id}
                key={id}
                className="text-input"
                value={value}
                type={
                  fieldControl?.hidden?.value
                  && fieldControl?.hidden?.value.toString() === 'true'
                    ? 'hidden'
                    : 'text'
                }
                placeholder={getControlPropertyDescriptions.placeholder}
                onChange={onChange}
                {...props}
                hasError={error || minMaxError}
                hasIcon={name === 'amount'}
              />
              {name === 'amount' ? (
                <StyledInputIconWrapper>
                  {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
                </StyledInputIconWrapper>
              ) : null}
            </StyledInputContainer>
            {name === 'accountnumber' && hasVerificationHistory ? (
              <StyledButtonWithBorder
                type="button"
                onClick={getVerificationHistoryHandler}
              >
                <VerificationHistoryIcon />
              </StyledButtonWithBorder>
            ) : null}
          </StyledFlexContainer>
          {error ? <StyledInputError>{error}</StyledInputError> : null}
          {minMaxError && <StyledInputError>{t((minMaxError)?.toLowerCase())}</StyledInputError>}
          {name === 'amount' && favoriteAmounts?.length ? (
            <StyledFavoriteAmountButtonWrapper>
              {favoriteAmounts?.map((el) => (
                <StyledFavoriteAmountButton
                  type="button"
                  key={el}
                  onClick={() => handleAmountChange(el)}
                >
                  {el}
                </StyledFavoriteAmountButton>
              ))}
            </StyledFavoriteAmountButtonWrapper>
          ) : null}
          {leftSideComponent}
          {/* {isAmountInput && value ? ( */}
          {/*   <TaxAndCommissions */}
          {/*     amount={taxInfo.amountWithoutFee} */}
          {/*     tax={taxInfo.feeAmount} */}
          {/*     currency={currency} */}
          {/*     isCurrencySymbol={isCurrencySymbol} */}
          {/*     isMobile={isMobile} */}
          {/*   /> */}
          {/* ) : null} */}
        </StyledInputWrapper>
      );
  }
);

TextBoxField.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  fieldControl: PropTypes.object,
  favoriteAmounts: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  minMax: PropTypes.object,
  currency: PropTypes.string.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  hasVerificationHistory: PropTypes.bool,
  alertContext: PropTypes.object.isRequired,
  templateType: PropTypes.string.isRequired
};

TextBoxField.defaultProps = {
  error: '',
  fieldControl: null,
  minMax: null,
  hasVerificationHistory: false
};

const CardNumberInputField = memo(({
  id,
  name,
  error,
  value,
  onChange,
  templateType,
  fieldControl,
  showValidationError,
  setShowValidationError,
  getControlPropertyDescriptions,
  ...props
}) => {
  const ref = useRef();
  const theme = useTheme();
  const StyledInput = generateMappedComponent(templateType);
  const {t} = useTranslation();

  const CardIcon = useMemo(() => getCardTypeIcon(value), [value]);

  const onChangeHandler = (e) => {
    if (value && showValidationError) setShowValidationError((prev) => ({...prev, [INVALID_CARD_NUMBER]: false}));
    if (validateNumericInput(e.target.value, false)) {
      e.target.value = formatCardNumberString(e.target.value);
      onChange(e);
    } else {
      e.preventDefault();
    }
  };

  const validate = () => {
    if (value && !validateCardNumberInput(value)) {
      setShowValidationError((prev) => ({...prev, [INVALID_CARD_NUMBER]: true}));
    } else {
      setShowValidationError((prev) => ({...prev, [INVALID_CARD_NUMBER]: false}));
    }
  };

  useOutsideClick(validate, [ref], [value]);

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <div ref={ref}>
      <StyledInputWrapper isNotRelative>
        {theme.inputHasLabel ? (
          <StyledInputLabel htmlFor={id} error={error}>
            {getControlPropertyDescriptions.name}
          </StyledInputLabel>
        ) : null}
        <StyledFlexContainer hasGap>
          <StyledInputContainer>
            <StyledInput
              name={name}
              id={id}
              key={id}
              className="text-input"
              value={value}
              type="text"
              placeholder={getControlPropertyDescriptions.placeholder}
              onChange={onChangeHandler}
              {...props}
              hasError={error || showValidationError}
            />
            {CardIcon ? (
              <StyledCardPositionIconWrapper>
                <CardIcon />
              </StyledCardPositionIconWrapper>
            ) : null}
          </StyledInputContainer>
        </StyledFlexContainer>
        {error ? <StyledInputError>{error}</StyledInputError> : null}
        {showValidationError ? <StyledInputError>{t('invalidcardnumber')}</StyledInputError> : null}
      </StyledInputWrapper>
    </div>
    );
});

CardNumberInputField.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showValidationError: PropTypes.bool.isRequired,
  setShowValidationError: PropTypes.func.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  error: PropTypes.string,
  fieldControl: PropTypes.object,
  templateType: PropTypes.number
};

CardNumberInputField.defaultProps = {
  error: '',
  fieldControl: null,
  templateType: 1
};

const ExpirationDateField = memo(({
  id,
  name,
  error,
  value,
  onChange,
  templateType,
  fieldControl,
  showValidationError,
  setShowValidationError,
  getControlPropertyDescriptions,
  ...props
}) => {
  const ref = useRef();
  const theme = useTheme();
  const StyledInput = generateMappedComponent(templateType);
  const prevLength = useMemo(() => value?.length || 0, [value]);
  const {t} = useTranslation();

  const onChangeHandler = (e) => {
    if (showValidationError) setShowValidationError((prev) => ({...prev, [INVALID_EXPIRATION_DATE]: false}));
    const valueLength = e.target.value?.length;

    if (valueLength === 6 || validateExpirationDateSymbols(e.target.value)) {
      e.preventDefault();
    } else {
      if (valueLength === 2 && valueLength > prevLength) {
        e.target.value += '/';
      } else if (valueLength < prevLength && valueLength === 2) {
        e.target.value = e.target.value.slice(0, -1);
      }
      onChange(e);
    }
  };

  const validate = () => {
    if (value) setShowValidationError((prev) => ({...prev, [INVALID_EXPIRATION_DATE]: !isValidExpirationDate(value)}));
  };

  useOutsideClick(validate, [ref], [value]);

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <div ref={ref}>
      <StyledInputWrapper isNotRelative>
        {theme.inputHasLabel ? (
          <StyledInputLabel htmlFor={id} error={error}>
            {getControlPropertyDescriptions.name}
          </StyledInputLabel>
        ) : null}
        <StyledFlexContainer hasGap>
          <StyledInputContainer>
            <StyledInput
              name={name}
              id={id}
              key={id}
              className="text-input"
              value={value}
              type="text"
              placeholder={getControlPropertyDescriptions.placeholder}
              onChange={onChangeHandler}
              {...props}
              hasError={error || showValidationError}
            />
          </StyledInputContainer>
        </StyledFlexContainer>
        {error ? <StyledInputError>{error}</StyledInputError> : null}
        {showValidationError ? <StyledInputError>{t('invalidexpirationdate')}</StyledInputError> : null}
      </StyledInputWrapper>
    </div>
    );
});

ExpirationDateField.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showValidationError: PropTypes.bool.isRequired,
  setShowValidationError: PropTypes.func.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  error: PropTypes.string,
  fieldControl: PropTypes.object,
  templateType: PropTypes.number
};

ExpirationDateField.defaultProps = {
  error: '',
  fieldControl: null,
  templateType: 1
};

const CVVField = memo(({
  id,
  name,
  error,
  value,
  onChange,
  fieldControl,
  getControlPropertyDescriptions,
  templateType,
  ...props
}) => {
  const theme = useTheme();
  const StyledInput = generateMappedComponent(templateType);

  const onChangeHandler = (e) => {
    if (e.target.value?.length < EXPIRATION_DATE_LENGTH && validateNumericInput(e.target.value)) {
      e.target.value = e.target.value.trim();
      onChange(e);
    } else {
      e.preventDefault();
    }
  };

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledInputWrapper isNotRelative>
      {theme.inputHasLabel ? (
        <StyledInputLabel htmlFor={id} error={error}>
          {getControlPropertyDescriptions.name}
        </StyledInputLabel>
      ) : null}
      <StyledFlexContainer hasGap>
        <StyledInputContainer>
          <StyledInput
            name={name}
            id={id}
            key={id}
            className="text-input"
            value={value}
            type="text"
            placeholder={getControlPropertyDescriptions.placeholder}
            onChange={onChangeHandler}
            // onKeyDown={onKeyDownHandler}
            {...props}
            hasError={error}
          />
        </StyledInputContainer>
      </StyledFlexContainer>
      {error ? <StyledInputError>{error}</StyledInputError> : null}
    </StyledInputWrapper>
    );
});

CVVField.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  error: PropTypes.string,
  fieldControl: PropTypes.object,
  templateType: PropTypes.number
};

CVVField.defaultProps = {
  error: '',
  fieldControl: null,
  templateType: 1
};

const LabelField = function({
  getControlPropertyDescriptions,
  fieldControl,
  ...props
}) {
  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledLabelInfoWrapper {...props}>
        <StyledLabelInfo
          dangerouslySetInnerHTML={{
            __html: getControlPropertyDescriptions.text
          }}
        />
      </StyledLabelInfoWrapper>
    );
};

LabelField.propTypes = {
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  fieldControl: PropTypes.object.isRequired
};

const DynamicLabelField = function({
  getControlPropertyDescriptions,
  fieldControl,
  ...props
}) {
  const data = localStorage.getItem('transactionData');
  const parsedData = JSON.parse(data);

  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledLabelInfoWrapper {...props}>
        <StyledLabelInfo withoutMargin>
          {getControlPropertyDescriptions.name}
        </StyledLabelInfo>
        <StyledLabelInfoForDynamicLabel>
          {parsedData?.paymentSources?.map((el) => (el?.dataType.toString() === fieldControl?.sourcedatatype?.value
            ? el?.data
            : null))}
        </StyledLabelInfoForDynamicLabel>
      </StyledLabelInfoWrapper>
    );
};

DynamicLabelField.propTypes = {
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  fieldControl: PropTypes.object.isRequired
};

/**
 * Select Single Option
 */
export const SelectSingleOption = memo(({
  label,
  value,
  iconId,
  isSelected,
  forVerificationFormControls,
  onSelect,
  onDelete
}) => {
  const isNew = useMemo(() => value === ADD_NEW_OPTION_VALUE, [value]);
  const CardIcon = typeof iconId === 'number' ? CARD_TYPE_ICONS[iconId] : null;

  return (
    <StyledSelectOption
      hasNoCardIcon={!CardIcon}
      isSelected={isSelected}
      isNew={isNew}
      disabled={isNew && !forVerificationFormControls}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        // eslint-disable-next-line no-unused-expressions
        isSelected || onSelect(value, forVerificationFormControls);
      }}
    >
      <StyledCardInfoWrapper>
        {!isNew && CardIcon && (
          <StyledCardIconWrapper>
            <CardIcon />
          </StyledCardIconWrapper>
        )}
        <StyledSelectText>{label}</StyledSelectText>
      </StyledCardInfoWrapper>
      {!isNew && typeof onDelete === 'function' && (
        <StyledButtonWithoutBg
          type="button"
          onClick={onDelete}
        >
          <DeleteIcon />
        </StyledButtonWithoutBg>
      )}
    </StyledSelectOption>
  );
});

SelectSingleOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  isSelected: PropTypes.bool.isRequired,
  forVerificationFormControls: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  iconId: PropTypes.number
};

SelectSingleOption.defaultProps = {
  onDelete: null,
  iconId: undefined,
  forVerificationFormControls: false
};

/**
 * Select Options Container
 */
export const SelectOptionsContainer = memo(({
  optionsType,
  children,
  componentRef,
  onClose,
  showVerificationHistoryIcon,
  hasVerificationHistory
}) => {
  const {getVerificationHistoryHandler} = useContext(
    AccountVerificationHistoryContext
  );

  return (
    <StyledSelectOptionsContainer ref={componentRef}>
      <StyledSelectOptionsHeader>
        <StyledButtonWithoutBg type="button" onClick={onClose} isPositionLeft>
          <StyledIconSmall icon="close" fontSize="11px" />
        </StyledButtonWithoutBg>
        <StyledCardHeaderTitle>{optionsType}</StyledCardHeaderTitle>
        {hasVerificationHistory && showVerificationHistoryIcon ? (
          <StyledButtonWithoutBg
            type="button"
            onClick={getVerificationHistoryHandler}
            isPositionRight
          >
            <VerificationHistoryIcon />
          </StyledButtonWithoutBg>
        ) : null}
      </StyledSelectOptionsHeader>
      {children}
    </StyledSelectOptionsContainer>
  );
});

SelectOptionsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  componentRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  optionsType: PropTypes.string.isRequired,
  showVerificationHistoryIcon: PropTypes.bool.isRequired,
  hasVerificationHistory: PropTypes.bool
};

SelectOptionsContainer.defaultProps = {
  hasVerificationHistory: false
};

/**
 * Select Options Container For Mobile
 */
export const SelectOptionsContainerMobile = memo(({
  showInfo,
  optionsType,
  children,
  componentRef,
  onClose,
  showVerificationHistoryIcon,
  hasVerificationHistory
}) => {
  const {getVerificationHistoryHandler} = useContext(
    AccountVerificationHistoryContext
  );

  const verificationHistoryIcon = useMemo(
    () => (hasVerificationHistory && showVerificationHistoryIcon ? (
      <StyledButtonWithoutBg
        isPositionLeft
        type="button"
        onClick={getVerificationHistoryHandler}
      >
        <VerificationHistoryIcon />
      </StyledButtonWithoutBg>
    ) : null),
    [
      hasVerificationHistory,
      getVerificationHistoryHandler,
      showVerificationHistoryIcon
    ]
  );

  return (
    <MobileModal
      title={optionsType}
      showInfo={showInfo}
      handleCloseInfo={onClose}
      ref={componentRef}
      additionalIcon={verificationHistoryIcon}
    >
      {children}
    </MobileModal>
  );
});

SelectOptionsContainerMobile.propTypes = {
  showInfo: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  componentRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  optionsType: PropTypes.string.isRequired,
  showVerificationHistoryIcon: PropTypes.bool.isRequired,
  hasVerificationHistory: PropTypes.bool
};

SelectOptionsContainerMobile.defaultProps = {
  hasVerificationHistory: false
};

/**
 * Verification History Container For Web
 */
export const VerificationHistoryContainerWeb = memo(({
  historyData,
  onClose,
  iconType
}) => {
  const {t} = useTranslation();

  return (
    <StyledSelectOptionsContainer>
      <StyledSelectOptionsHeader>
        <StyledButtonWithoutBg type="button" onClick={onClose} closeButton>
          <StyledIconSmall icon={iconType} />
        </StyledButtonWithoutBg>
        <StyledCardHeaderTitle>{t(('verificationHistory')?.toLowerCase())}</StyledCardHeaderTitle>
      </StyledSelectOptionsHeader>
      <StyledSelectOptionsWrapper>
        {historyData.map(({uploadedDate, merchantUserAccountNumberStatus}) => (
          <StyledUploadedList key={uploadedDate}>
            <StyledPaymentItemLabel>
              {t(('uploadedDate')?.toLowerCase())}
              :
              <StyledPaymentItemValue>{uploadedDate}</StyledPaymentItemValue>
            </StyledPaymentItemLabel>
            <StyledHistoryUploadedWrapper>
              {merchantUserAccountNumberStatus?.map(({accountNumber, accountStatus}) => (
                <StyledSelectOption hasNoCardIcon key={accountNumber}>
                  <StyledSelectText>{accountNumber}</StyledSelectText>
                  <StyledSelectAccountStatusLabel
                    backgroundColor={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.backgroundColor}
                    color={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.color}
                  >
                    {t((accountStatus)?.toLowerCase())}
                  </StyledSelectAccountStatusLabel>
                </StyledSelectOption>
              ))}
            </StyledHistoryUploadedWrapper>
          </StyledUploadedList>
        ))}
      </StyledSelectOptionsWrapper>
    </StyledSelectOptionsContainer>
  );
});

VerificationHistoryContainerWeb.propTypes = {
  historyData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  iconType: PropTypes.string
};

VerificationHistoryContainerWeb.defaultProps = {
  iconType: 'close'
};

/**
 * Verification History Container For Mobile
 */
export const VerificationHistoryContainerMobile = memo(({
  historyData,
  onClose,
  onGoBack
}) => {
  const {t} = useTranslation();

  return (
    <MobileModal
      title={t(('verificationHistory')?.toLowerCase())}
      showInfo={historyData?.length > 0}
      handleCloseInfo={onClose}
      handleGoBack={onGoBack}
    >
      {historyData.map(({uploadedDate, merchantUserAccountNumberStatus}) => (
        <StyledUploadedList key={uploadedDate}>
          <StyledPaymentItemLabel>
            {t(('uploadedDate')?.toLowerCase())}
            :
            <StyledPaymentItemValue>{uploadedDate}</StyledPaymentItemValue>
          </StyledPaymentItemLabel>
          <StyledHistoryUploadedWrapper className="withoutPaddingLeft">
            {merchantUserAccountNumberStatus?.map(({accountNumber, accountStatus}) => (
              <StyledSelectOption hasNoCardIcon key={accountNumber}>
                <StyledSelectText>{accountNumber}</StyledSelectText>
                <StyledSelectAccountStatusLabel
                  backgroundColor={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.backgroundColor}
                  color={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.color}
                >
                  {t((accountStatus)?.toLowerCase())}
                </StyledSelectAccountStatusLabel>
              </StyledSelectOption>
            ))}
          </StyledHistoryUploadedWrapper>
        </StyledUploadedList>
      ))}
    </MobileModal>
  );
});

VerificationHistoryContainerMobile.propTypes = {
  historyData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onGoBack: PropTypes.func
};

VerificationHistoryContainerMobile.defaultProps = {
  onGoBack: null
};

/**
 * DynamicDropdown Field
 */
const DynamicDropdownField = function({
  id,
  name,
  keyName,
  value,
  error,
  fieldControl,
  setFieldValue,
  getControlPropertyDescriptions,
  alertContext,
  isOffline,
  ...props
}) {
  const theme = useTheme();
  const [options, setOptions] = useState([]);

  const amountValue = localStorage.getItem('amountValue');

  const {
    siteId, userId, currency, country, language, token
  } = useContext(
    CashierDetailsContext
  );
  const {paymentSystemId, platformPaymentSystemId} = useContext(
    SelectedPaymentDetailsContext
  );

  const selectValue = useMemo(() => {
    if (!!value && options?.length > 0) {
      const parsedValue = JSON.parse(value);
      return options.find((option) => option.value === parsedValue.code);
    }

    return null;
  }, [value, options]);

  /**
   * Fetch data for the first dropdown when the component mounts
   */
  useEffect(() => {
    if (fieldControl?.url?.value) {
      getDropdownListOptions({
        token,
        siteId,
        userId,
        country,
        currency,
        isOffline,
        setOptions,
        alertContext,
        paymentSystemId,
        amount: amountValue,
        languageId: language,
        platformPaymentSystemId,
        url: fieldControl.url.value
      }).then((response) => {
        if (response?.length) {
          setOptions(response);
        }
      });
    }
  }, [siteId]);

  const handleSelectChange = (selectedOption) => {
    let sanitizedOption;
    if (fieldControl?.identifiedbyfiledkey?.value === 'true') {
      sanitizedOption = {
        [keyName]: selectedOption.code
      };
    } else {
      sanitizedOption = {...selectedOption};

      delete sanitizedOption.value;
      delete sanitizedOption.label;
    }

    // Set the field value with the sanitized option
    setFieldValue(name, JSON.stringify(sanitizedOption));

    if (sanitizedOption?.cardExpirationDate) {
      setFieldValue(EXPIRATION_DATE_FIELD_NAME, sanitizedOption.cardExpirationDate);
    }
  };

  const customNoOptionsMessage = () => (
    <div className="custom-no-options-message">
      {getControlPropertyDescriptions.nooptions}
    </div>
  );

  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledInputWrapper>
        {theme.inputHasLabel ? (
          <StyledInputLabel htmlFor={id}>
            {getControlPropertyDescriptions.name}
          </StyledInputLabel>
        ) : null}
        <Select
          id={id}
          key={id}
          name={name}
          placeholder={getControlPropertyDescriptions.placeholder}
          noOptionsMessage={customNoOptionsMessage}
          options={options}
          value={selectValue}
          onChange={handleSelectChange}
          className={error ? 'react-select hasError' : 'react-select'}
          classNamePrefix="react-select"
          {...props}
          styles={customStyles(theme)}
          components={{
            IndicatorSeparator: () => null
          }}
        />
        {error ? <StyledInputError>{error}</StyledInputError> : null}
      </StyledInputWrapper>
    );
};

DynamicDropdownField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string.isRequired,
  fieldControl: PropTypes.object,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  isOffline: PropTypes.bool.isRequired
};

DynamicDropdownField.defaultProps = {
  error: '',
  fieldControl: null
};

/**
 * BankAmountList Field
 */
const BankAmountListField = function({
  id,
  fieldControl,
  validationError,
  value,
  name,
  onChange,
  getControlPropertyDescriptions,
  alertContext,
  isCurrencySymbol,
  isMobile
}) {
  const theme = useTheme();
  const [bankOptions, setBankOptions] = useState([]);
  const [amountOptions, setAmountOptions] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const isAmountInput = useMemo(() => name === 'amount', [name]);
  const [taxInfo, setTaxInfo] = useState({amountWithoutFee: 0, feeAmount: 0});
  const {
    siteId, userId, country, currency, operationType, language, token
  } = useContext(
    CashierDetailsContext
  );
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);

  /**
   * Fetch Bank List data for the first dropdown when the component mounts
   */
  useEffect(() => {
    const bankUrl = `${fieldControl?.banklistgettingurl?.value}?siteId=${siteId}&paymentSystem=${paymentSystemId}&userId=${userId}&countryCode=${country}&cy=${currency}`;
    if (fieldControl?.url?.value) {
      getDropdownOptions(
        bankUrl,
        setBankOptions,
        siteId,
        userId,
        paymentSystemId,
        currency,
        country,
        alertContext
      );
    }
  }, [fieldControl, siteId, userId, paymentSystemId, currency, country]);

  /**
   * Event Amount List Depend on selected bank
   * handler for selecting a bank from the first dropdown
   */
  const handleBankSelect = (selectedOption) => {
    const amountUrl = `${fieldControl?.amountlistgettingurl?.value}?method=getamounts&isDynamic=1&sp=${siteId}&userid=${userId}&bankId=${selectedOption?.value}&ps=${paymentSystemId}&currency=${currency}&country=${country}`;
    onChange({
      target: {
        name,
        value: selectedOption ? selectedOption?.value : ''
      }
    });
    if (selectedOption) {
      getDropdownOptions(
        amountUrl,
        selectedOption?.value,
        setAmountOptions,
        setSelectedAmount,
        siteId,
        userId,
        paymentSystemId,
        currency,
        country,
        alertContext
      );
    } else {
      setAmountOptions([]);
      setSelectedAmount(null);
    }
  };

  /**
   *  Event handler for selecting an amount from the second dropdown
   */
  const handleAmountSelect = (selectedOption) => {
    // eslint-disable-next-line no-param-reassign
    value = selectedOption;
    setSelectedAmount(selectedOption);
  };

  const customNoOptionsMessage = () => (
    <div className="custom-no-options-message">
      {getControlPropertyDescriptions.nooptions}
    </div>
  );

  useEffect(() => {
    if (value && isAmountInput) {
      const requestBody = {
        siteId,
        paymentSystemId,
        userId,
        operationType,
        value,
        language,
        token,
        setTaxInfo,
        alertContext
      };
      // getTaxes({requestBody});
    }
  }, [isAmountInput, value]);

  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledInputWrapper>
        {theme.inputHasLabel ? (
          <StyledInputLabel htmlFor={id} error={validationError}>
            {getControlPropertyDescriptions.name}
          </StyledInputLabel>
        ) : null}
        <StyledInputWrapper>
          <Select
            id={id}
            key={id}
            name={name}
            defaultValue={[bankOptions[0]]}
            selectedValue={bankOptions[0]}
            options={bankOptions}
            onChange={handleBankSelect}
            value={bankOptions?.find((option) => option?.value === value)} // Set the value using the formatted options
            placeholder={getControlPropertyDescriptions.placeholder}
            noOptionsMessage={customNoOptionsMessage}
            className="react-select-container"
            classNamePrefix="react-select"
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null
            }}
          />
          {validationError ? (
            <StyledInputError>{validationError}</StyledInputError>
          ) : null}
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Select
            id={id}
            key={id}
            name={name}
            defaultValue={[amountOptions[0]]}
            options={amountOptions}
            value={selectedAmount}
            onChange={handleAmountSelect}
            placeholder={getControlPropertyDescriptions.placeholder}
            noOptionsMessage={customNoOptionsMessage}
            className="react-select-container"
            classNamePrefix="react-select"
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </StyledInputWrapper>
        {/* {isAmountInput && value ? ( */}
        {/*   <TaxAndCommissions */}
        {/*     amount={taxInfo.amountWithoutFee} */}
        {/*     tax={taxInfo.feeAmount} */}
        {/*     currency={currency} */}
        {/*     isCurrencySymbol={isCurrencySymbol} */}
        {/*     isMobile={isMobile} */}
        {/*   /> */}
        {/* ) : null} */}
      </StyledInputWrapper>
    );
};

BankAmountListField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  validationError: PropTypes.string,
  fieldControl: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired
};

BankAmountListField.defaultProps = {
  validationError: '',
  fieldControl: null
};

const SelectField = function({
  id,
  name,
  keyName,
  error,
  value,
  setFieldValue,
  fieldControl,
  getControlPropertyDescriptions,
  selectedValue,
  getSelectedValue,
  currency,
  isCurrencySymbol,
  alertContext,
  isMobile
}) {
  const theme = useTheme();
  const isAmountInput = useMemo(() => name === 'amount', [name]);
  const [taxInfo, setTaxInfo] = useState({amountWithoutFee: 0, feeAmount: 0});

  const {
    siteId, userId, operationType, language, token
  } = useContext(CashierDetailsContext);
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);

  const formattedOptions = JSON.parse(fieldControl?.value?.value)?.map(
    (option) => ({
      value: option?.value,
      label: name === 'amount'
        ? `${option?.value} ${getCurrencySymbolOrCode(currency, isCurrencySymbol)}` // Add currency symbol if name is 'amount'
        : option?.name // Keep the default name for other fields
    })
  );

  const selectValue = useMemo(() => (value && formattedOptions?.length ? formattedOptions.find((option) => option.value === JSON.parse(value).value) : null), [value, formattedOptions]);

  // const handleSelectChange = (selectedOption) => {
  //   setFieldValue(name, JSON.stringify(selectedOption));
  //   getSelectedValue(selectedOption);
  // };

  const handleSelectChange = (selectedOption) => {
    const isIdentifiedByFieldKey = fieldControl?.identifiedbyfiledkey?.value === 'true';

    const sanitizedOption = isIdentifiedByFieldKey
      ? {[keyName]: selectedOption.value}
      : {...selectedOption};

    setFieldValue(name, JSON.stringify(sanitizedOption));
    getSelectedValue(sanitizedOption, selectedOption);
  };

  const customNoOptionsMessage = () => (
    <div className="custom-no-options-message">
      {getControlPropertyDescriptions.nooptions}
    </div>
  );

  useEffect(() => {
    if (value && isAmountInput) {
      const requestBody = {
        siteId,
        paymentSystemId,
        userId,
        operationType,
        value,
        language,
        token,
        setTaxInfo,
        alertContext
      };
      // getTaxes({requestBody});
    }
  }, [isAmountInput, value]);

  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledInputWrapper>
        {theme.inputHasLabel ? (
          <StyledInputLabel>
            {getControlPropertyDescriptions.name}
          </StyledInputLabel>
        ) : null}
        <Select
          id={id}
          key={id}
          name={name}
          options={formattedOptions}
          value={selectValue}
          defaultValue={selectedValue}
          onChange={handleSelectChange}
          placeholder={getControlPropertyDescriptions.placeholder}
          noOptionsMessage={customNoOptionsMessage}
          className={error ? 'react-select hasError' : 'react-select'}
          classNamePrefix="react-select"
          styles={customStyles(theme)}
          components={{
            IndicatorSeparator: () => null
          }}
        />
        {error ? <StyledInputError>{error}</StyledInputError> : null}
        {/* {isAmountInput && value ? ( */}
        {/*   <TaxAndCommissions */}
        {/*     amount={taxInfo.amountWithoutFee} */}
        {/*     tax={taxInfo.feeAmount} */}
        {/*     currency={currency} */}
        {/*     isCurrencySymbol={isCurrencySymbol} */}
        {/*     isMobile={isMobile} */}
        {/*   /> */}
        {/* ) : null} */}
      </StyledInputWrapper>
    );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  error: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  fieldControl: PropTypes.object,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  getSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

SelectField.defaultProps = {
  error: '',
  fieldControl: null
};

const NewSelectField = function({
  name,
  keyName,
  error,
  setFieldValue,
  fieldControl,
  getControlPropertyDescriptions,
  getSelectedValue,
  selectedValue,
  isMobile,
  showVerificationHistoryIcon,
  hasVerificationHistory
}) {
  const optionsRef = useRef();
  const showOptionsBtnRef = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const formattedOptions = JSON.parse(fieldControl?.value?.value)?.map(
    (option) => ({
      value: option?.value,
      label: option.name
    })
  );

  // const handleSelectChange = (selectedOption) => {
  //   getSelectedValue(selectedOption);
  //   setFieldValue(name, JSON.stringify(selectedOption));
  // };

  const handleSelectChange = (selectedOption) => {
    let sanitizedOption;

    if (fieldControl?.identifiedbyfiledkey?.value === 'true') {
      sanitizedOption = {
        [keyName]: selectedOption.value
      };
    } else {
      sanitizedOption = {...selectedOption};
    }

    getSelectedValue(sanitizedOption, selectedOption);
    setFieldValue(name, JSON.stringify(sanitizedOption));
  };

  useOutsideClick(() => setShowOptions(false), [optionsRef, showOptionsBtnRef]);

  const onOptionsClose = useCallback(() => setShowOptions(false), []);

  const Icon = useMemo(
    () => DROPDOWN_ICONS[fieldControl?.icon] ?? null,
    [fieldControl]
  );

  if (fieldControl?.hidden?.value?.toString() === 'true') return null;

  const renderOptions = () => {
    const sortedOptions = sortArrayByKeyValue({
      array: formattedOptions,
      key: 'value',
      value: selectedValue
    }) || [];

    return sortedOptions.map(({label, value}) => (
      <SelectSingleOption
        key={value}
        label={label}
        value={value}
        isSelected={value === selectedValue}
        onSelect={handleSelectChange}
      />
    ));
  };

  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledInputWrapper isNotRelative>
        <StyledSelectWrapper spaceBetween>
          <StyledSelect>
            <StyledSelectText>{selectedValue}</StyledSelectText>
          </StyledSelect>
          <StyledButtonWithBorder
            type="button"
            ref={showOptionsBtnRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowOptions((prev) => !prev);
            }}
          >
            {Icon ? <Icon /> : 'I'}
          </StyledButtonWithBorder>
          {isMobile ? (
            <SelectOptionsContainerMobile
              showInfo={showOptions}
              optionsType={getControlPropertyDescriptions.name}
              componentRef={optionsRef}
              onClose={onOptionsClose}
              showVerificationHistoryIcon={showVerificationHistoryIcon}
              hasVerificationHistory={hasVerificationHistory}
            >
              {renderOptions()}
            </SelectOptionsContainerMobile>
          ) : (
            showOptions && (
            <SelectOptionsContainer
              optionsType={getControlPropertyDescriptions.name}
              componentRef={optionsRef}
              onClose={onOptionsClose}
              showVerificationHistoryIcon={showVerificationHistoryIcon}
            >
              <StyledSelectOptionsWrapper>
                {renderOptions()}
              </StyledSelectOptionsWrapper>
            </SelectOptionsContainer>
            )
          )}
        </StyledSelectWrapper>
        {error ? <StyledInputError>{error}</StyledInputError> : null}
      </StyledInputWrapper>
    );
};

NewSelectField.propTypes = {
  // id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  error: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  fieldControl: PropTypes.object,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  getSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showVerificationHistoryIcon: PropTypes.bool,
  hasVerificationHistory: PropTypes.bool
};

NewSelectField.defaultProps = {
  error: '',
  fieldControl: null,
  showVerificationHistoryIcon: false,
  hasVerificationHistory: false
};

const ButtonOptionsDropdown = memo(({
  name,
  error,
  value,
  currency,
  fieldControl,
  setFieldValue,
  alertContext,
  isCurrencySymbol,
  isMobile
}) => {
  const {t} = useTranslation();
  const moreOptionRef = useRef();
  const moreOptionsListRef = useRef();
  const isAmountInput = useMemo(() => name === 'amount', [name]);
  const [taxInfo, setTaxInfo] = useState({amountWithoutFee: 0, feeAmount: 0});

  const {
    siteId, userId, operationType, language, token
  } = useContext(CashierDetailsContext);
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);

  const [openMoreOptions, setOpenMoreOptions] = useState(false);

  useOutsideClick(() => {
    setOpenMoreOptions(false);
  }, [moreOptionRef, moreOptionsListRef]);

  const formattedOptions = useMemo(() => JSON.parse(fieldControl?.value?.value)?.map(
    (option) => ({
      value: option?.value,
      label: `${option?.value} ${getCurrencySymbolOrCode(currency, true)}`
    })
  ), [fieldControl?.value?.value]);

  const {firstOptions, moreOptions} = useMemo(() => {
    if (formattedOptions?.length > 0) {
      if (formattedOptions.length > 8) {
        return {firstOptions: formattedOptions.slice(0, 7), moreOptions: formattedOptions.slice(7)};
      }

      return {firstOptions: formattedOptions, moreOptions: []};
    }

    return {firstOptions: [], moreOptions: []};
  }, [formattedOptions]);

  const handleSelectChange = (selectedOptionStringify, closeMoreAfterSelect = false) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFieldValue(name, selectedOptionStringify);
    if (closeMoreAfterSelect) {
      setOpenMoreOptions(false);
    }
  };

  const onMoreClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setOpenMoreOptions((prev) => !prev);
  };

  useEffect(() => {
    if (value && isAmountInput) {
      const requestBody = {
        siteId,
        paymentSystemId,
        userId,
        operationType,
        value,
        language,
        token,
        setTaxInfo,
        alertContext
      };
      // getTaxes({requestBody});
    }
  }, [isAmountInput, value]);

  return (
    <div>
      {value}
      <div>
        {firstOptions.map((option) => {
          const optionStringify = JSON.stringify(option);
          const isSelected = value === optionStringify;

          return (
            <span
              key={option.value}
              onClick={handleSelectChange(optionStringify)}
              style={{color: isSelected ? 'green' : 'black'}}
            >
              {option.label}
            </span>
          );
        })}
        {moreOptions.length > 0 ? (
          <div
            ref={moreOptionRef}
            style={{
              width: '99px',
              height: '36px',
              position: 'relative',
              color: moreOptions.find((option) => option.value === JSON.parse(value || '{}').value) ? 'green' : 'black'
            }}
          >
            <span
              style={{
                width: '100%',
                height: '100%',
                textAlign: 'center'
              }}
              onClick={onMoreClickHandler}
            >
              {t('more')}
            </span>
            <div
              ref={moreOptionsListRef}
              style={{
                width: '100%',
                height: openMoreOptions ? 'fit-content' : '0',
                position: 'absolute',
                left: '0',
                top: '100%',
                zIndex: '100',
                overflow: 'hidden'
              }}
            >
              {moreOptions.map((option) => {
                const optionStringify = JSON.stringify(option);
                const isSelected = value === optionStringify;

                return (
                  <span
                    key={option.value}
                    onClick={handleSelectChange(optionStringify, true)}
                    style={{color: isSelected ? 'green' : 'black'}}
                  >
                    {option.label}
                  </span>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      {error ? <StyledInputError>{error}</StyledInputError> : null}
      {/* {isAmountInput && value ? ( */}
      {/*   <TaxAndCommissions */}
      {/*     amount={taxInfo.amountWithoutFee} */}
      {/*     tax={taxInfo.feeAmount} */}
      {/*     currency={currency} */}
      {/*     isCurrencySymbol={isCurrencySymbol} */}
      {/*     isMobile={isMobile} */}
      {/*   /> */}
      {/* ) : null} */}
    </div>
  );
});

ButtonOptionsDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  fieldControl: PropTypes.object,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired
};

ButtonOptionsDropdown.defaultProps = {
  error: '',
  fieldControl: null
};

const ButtonField = function({
  id,
  disabled,
  fieldControl,
  getControlPropertyDescriptions
}) {
  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledButton type="submit" id={id} disabled={disabled}>
        {getControlPropertyDescriptions.name}
      </StyledButton>
    );
};
ButtonField.propTypes = {
  id: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldControl: PropTypes.object.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired
};

const ButtonWithMultiUrl = function({
  fieldControl,
  getControlPropertyDescriptions,
  disabled,
  name,
  merchantUserMultistepControls,
  setPaymentControlsData,
  setIsLoading,
  alertContext
}) {
  const {
    siteId,
    userId,
    currency,
    language,
    operationType
  } = useContext(CashierDetailsContext);
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const backToForm = name === 'BackToForm';

  // Conditionally check for the name "backToForm" and call the function
  const handleButtonClick = (event) => {
    if (backToForm) {
      // Prevent form submission
      event.preventDefault();
      merchantUserMultistepControls(
        setPaymentControlsData,
        siteId,
        paymentSystemId,
        operationType,
        userId,
        currency,
        language,
        setIsLoading,
        alertContext
      );
    }
  };

  // eslint-disable-next-line no-nested-ternary
  return fieldControl?.hidden?.value && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    backToForm ? (
      <StyledFlexContainer>
        <StyledLinkButton
          type="button"
          disabled={false}
          onClick={handleButtonClick}
          centerAligned
          withoutMarginTop
        >
          {getControlPropertyDescriptions.name}
        </StyledLinkButton>
      </StyledFlexContainer>
    ) : (
      <StyledButton
        type="submit"
        disabled={disabled}
      >
        {getControlPropertyDescriptions.name}
      </StyledButton>
    )
  );
};

ButtonWithMultiUrl.propTypes = {
  fieldControl: PropTypes.object.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  alertContext: PropTypes.object.isRequired,
  setPaymentControlsData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  merchantUserMultistepControls: PropTypes.func.isRequired
};

const ApplePayButtonField = function({fieldControl, handleApplePayClick}) {
  return fieldControl?.hidden?.value
    && fieldControl?.hidden?.value.toString() === 'true' ? null : (
      <StyledFlexContainer>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="submit"
          id="apple-pay-button"
          trxAmount=""
          className="apple-pay-button apple-pay-button-black apple-pay-button-pay"
          onClick={handleApplePayClick}
        />
      </StyledFlexContainer>
    );
};

ApplePayButtonField.propTypes = {
  fieldControl: PropTypes.object.isRequired,
  // getControlPropertyDescriptions: PropTypes.object.isRequired,
  handleApplePayClick: PropTypes.func.isRequired
};

const PayPalButtonField = function({
  resetForm,
  fieldControl,
  amountValue,
  alertContext,
  payPalTransactionData,
  merchantUserMultistepControls,
  setPaymentControlsData,
  setIsLoading
}) {
  const {
    siteId, userId, currency, language, operationType
  } = useContext(
    CashierDetailsContext
  );
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {t} = useTranslation();
  const successUrlRef = useRef('');
  const failUrlRef = useRef('');
  successUrlRef.current = payPalTransactionData?.CallbackSuccessUrl;
  failUrlRef.current = payPalTransactionData?.CallbackFailUrl;

  /**
   * Gets order id
   */
  const createOrder = () => payPalTransactionData?.PSTransactionId;

  /**
   * Calls success Url
   */
  const onApprove = () => {
    const url = successUrlRef.current;
    fetch(url, {
      method: 'GET'
    });
    resetForm();
    merchantUserMultistepControls(
      setPaymentControlsData,
      siteId,
      paymentSystemId,
      operationType,
      userId,
      currency,
      language,
      setIsLoading,
      alertContext
    );
    alertContext?.success(t(('successTransaction').toLowerCase()));
  };

  /**
   * Calls fail Url
   */
  const onError = (err) => {
    const url = failUrlRef.current;
    fetch(url, {
      method: 'GET'
    });
    alertContext?.error(err);
  };

  return !fieldControl?.hidden?.value
    || fieldControl?.hidden?.value.toString() !== 'true' ? (
      <StyledFlexContainer>
        <PayPalScriptProvider
          options={{
            'client-id': PAYPAL_CLIENT_ID,
            intent: INTENT
          }}
        >
          <StyledPayPalButtonWrapper>
            <PayPalButtons
              key={amountValue}
              style={PayPalButtonStyle}
              createOrder={createOrder}
              onApprove={onApprove}
              onError={onError}
            />
          </StyledPayPalButtonWrapper>
        </PayPalScriptProvider>
      </StyledFlexContainer>
    ) : null;
};

PayPalButtonField.propTypes = {
  fieldControl: PropTypes.object.isRequired,
  amountValue: PropTypes.number.isRequired,
  alertContext: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  payPalTransactionData: PropTypes.object.isRequired,
  merchantUserMultistepControls: PropTypes.func.isRequired,
  setPaymentControlsData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired
};

// const DebitOrCreditCardButtonField = function({
//   fieldControl,
//   amountValue,
//   cashierDetails,
//   alertContext
// }) {
//   const successUrlRef = useRef('');
//   const failUrlRef = useRef('');
//   const {t} = useTranslation();
//   const cardNameContainerRef = useRef();
//   const cardNumberContainerRef = useRef();
//   const cardExpiryContainerRef = useRef();
//   const cardCvvContainerRef = useRef();
//   const multiCardFieldButtonRef = useRef();
//   const amountValueRef = useRef(amountValue);
//   amountValueRef.current = amountValue;
//
//   useEffect(() => {
//     const cardField = window?.paypal?.CardFields({
//       style: PayPalInputStyles,
//       createOrder: () => new Promise((resolve, reject) => {
//         createTransaction(cashierDetails, amountValueRef.current)
//           .then(({successUrl, failUrl, orderId}) => {
//             successUrlRef.current = successUrl;
//             failUrlRef.current = failUrl;
//             resolve(orderId);
//           })
//           .catch((error) => {
//             console.log('error', error);
//             reject(error);
//           });
//       }),
//
//       onApprove() {
//         const url = successUrlRef.current;
//         fetch(url, {
//           method: 'GET'
//         });
//         alertContext.success(t('cashier.transactionSuccessful'));
//       }
//     });
//
//     if (cardField.isEligible()) {
//       cardField.NameField().render(cardNameContainerRef.current);
//       cardField.NumberField().render(cardNumberContainerRef.current);
//       cardField.CVVField().render(cardCvvContainerRef.current);
//       cardField.ExpiryField().render(cardExpiryContainerRef.current);
//
//       multiCardFieldButtonRef.current.addEventListener('click', () => {
//         cardField
//           .submit()
//           .then(() => {
//             // Handle a successful payment
//           })
//           .catch((err) => {
//             const url = failUrlRef.current;
//             fetch(url, {
//               method: 'GET'
//             });
//             console.log('err'.err);
//             alertContext?.error(err);
//           });
//       });
//     }
//   }, []);
//
//   return (
//     (!fieldControl?.hidden?.value || fieldControl?.hidden?.value.toString() !== 'true') ? (
//       <div id="checkout-form">
//         <div ref={cardNameContainerRef} />
//         <div ref={cardNumberContainerRef} />
//         <div ref={cardExpiryContainerRef} />
//         <div ref={cardCvvContainerRef} />
//         <StyledFlexContainer>
//           <button ref={multiCardFieldButtonRef} type="button">
//             {t(('cashier.payNowWithCard')}
//           </button>
//         </StyledFlexContainer>
//       </div>
//     ) : null
//   );
// };
//
// DebitOrCreditCardButtonField.propTypes = {
//   fieldControl: PropTypes.object.isRequired,
//   amountValue: PropTypes.number.isRequired,
//   cashierDetails: PropTypes.object.isRequired,
//   alertContext: PropTypes.object.isRequired
// };

/* eslint-disable */
const GooglePayButtonField = function ({
  fieldControl,
  alertContext,
  googlePayTransactionData,
  merchantUserMultistepControls,
  setPaymentControlsData,
  setIsLoading
}) {
  const {
    siteId,
    userId,
    currency: cashierCurrency,
    language,
    operationType,
  } = useContext(CashierDetailsContext);
  const { paymentSystemId } = useContext(SelectedPaymentDetailsContext);
  const { t } = useTranslation();
  const googlePayButtonRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  let paymentToken;
  let paymentsClient = null;
  const {
    allowCreditCards,
    allowPrepaidCards,
    allowedCardAuthMethods,
    allowedCardNetworks,
    assuranceDetailsRequired,
    billingAddressParameters,
    billingAddressRequired,
    checkoutOption,
    gateway,
    gatewayMerchantId,
    googleMerchantId,
    merchantName,
    tokenizationType,
    totalPriceStatus,
    googlePayEnv,
    country,
    currency,
    amount
  } = googlePayTransactionData?.paymentSettings || {};
  const {SafeCharge} = window;

  useEffect(() => {
    // eslint-disable-next-line new-cap
    var sfc = SafeCharge({
      env: 'int',
      merchantId: googlePayTransactionData?.merchantId,
      merchantSiteId: googlePayTransactionData?.merchantSiteId,
      sessionToken: googlePayTransactionData?.sessionToken
    });
    window.sfc = sfc;
  }, []);

  /**
   * Describe site's support for the CARD payment method and its required fields
   */
  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks,
      allowCreditCards,
      allowPrepaidCards,
      assuranceDetailsRequired,
      billingAddressRequired,
      billingAddressParameters
    }
  };

  /**
   * Identify gateway and site's gateway merchant identifier
   */
  const tokenizationSpecification = {
    type: tokenizationType,
    parameters: {
      gateway: gateway,
      gatewayMerchantId: gatewayMerchantId
    }
  };

  /**
   * Define the version of the Google Pay API referenced when creating your configuration
   */
  const baseRequest = {
    apiVersion: BASE_REQUEST.API_VERSION,
    apiVersionMinor: BASE_REQUEST.API_VERSION_MINOR
  };

  /**
   * Describe your site's support for the CARD payment method including optional fields
   */
  const cardPaymentMethod = Object.assign({}, baseCardPaymentMethod, {
    tokenizationSpecification: tokenizationSpecification
  });

  /**
   * Provide Google Pay API with a payment amount, currency, and amount status
   */
  const getGoogleTransactionInfo = function() {
    return {
      countryCode: country,
      currencyCode: currency,
      totalPriceStatus,
      totalPrice: amount,
      checkoutOption
    };
  };

  /**
   * Configure support for the Google Pay API
   */
  const getGooglePaymentDataRequest = function() {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = {
      merchantId: googleMerchantId,
      merchantName
    };
    return paymentDataRequest;
  };

  /**
   * Configure site's support for payment methods supported by the Google Pay API.
   */
  const getGoogleIsReadyToPayRequest = function() {
    return Object.assign({}, baseRequest, {
      allowedPaymentMethods: [baseCardPaymentMethod]
    });
  };

  /**
   * Return an active PaymentsClient or initialize
   */
  const getGooglePaymentsClient = function() {
    if (paymentsClient === null) {
      paymentsClient = new google.payments.api.PaymentsClient({
        environment: googlePayEnv
      });
    }
    return paymentsClient;
  };

  /**
   * create a payment using Google Pay.
   */
  const payWithGooglePay = function(paymentToken) {
    window.sfc.createPayment(
      {
        sessionToken: googlePayTransactionData?.sessionToken,
        merchantId: googlePayTransactionData?.merchantId,
        merchantSiteId: googlePayTransactionData?.merchantSiteId,
        cardHolderName: 'CL-BRW1',
        paymentOption: {
          card: {
            externalToken: {
              externalTokenProvider: 'GooglePay',
              mobileToken: JSON.stringify(paymentToken.paymentMethodData)
            }
          }
        }
      },
      function(crRes) {
        if (crRes?.transactionStatus === TRANSACTION_STATUSES.APPROVED) {
          alertContext.success(t(('successTransaction').toLowerCase()));
          merchantUserMultistepControls(
            setPaymentControlsData,
            siteId,
            paymentSystemId,
            operationType,
            userId,
            cashierCurrency,
            language,
            setIsLoading,
            alertContext
          );
        } else if (
          crRes?.transactionStatus === TRANSACTION_STATUSES.ERROR ||
          crRes?.transactionStatus === TRANSACTION_STATUSES.DECLINED
        ) {
          alertContext.error(crRes?.errorDescription);
        } else {
          alertContext.error(crRes?.error);
        }
      }
    );
  };

  /**
   * Process payment data returned by the Google Pay API
   */
  const processPayment = function(paymentData) {
    payWithGooglePay(paymentData);
    paymentToken = paymentData.paymentMethodData.tokenizationData.token;
  };

  /**
   * Show Google Pay payment sheet when Google Pay payment button is clicked
   */
  const onGooglePaymentButtonClicked = function() {
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then(function(paymentData) {
        processPayment(paymentData);
      })
      .catch(function(err) {
        alertContext.error(err);
      });
  };

  /**
   * Add a Google Pay purchase button alongside an existing checkout button
   */
  const addGooglePayButton = function() {
    if (!googlePayButtonRef.current) {
      const paymentsClient = getGooglePaymentsClient();
      const button = paymentsClient.createButton({
        onClick: onGooglePaymentButtonClicked,
        // buttonSizeMode: 'fill',
        /* add if height: 36px */

        buttonType: 'pay'
        // buttonType: 'plain'
        /* plain if mast shown only Gpay and without animation */
      });
      googlePayButtonRef.current = button;
      const googlePayContainer = document.getElementById('googlePayContainer');
      if (googlePayContainer) {
        googlePayContainer.appendChild(button);
      }
    }
  };

  /**
   * Display a Google Pay payment button after confirmation of the viewer's ability to pay.
   */
  const onGooglePayLoaded = function() {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(function(response) {
        if (response.result) {
          addGooglePayButton();
        }
      })
      .catch(function(err) {
        alertContext.error(err);
      });
  };

  useEffect(() => {
    if (window?.google?.payments?.api?.PaymentsClient) {
      onGooglePayLoaded();
    }
  }, [window?.google?.payments?.api?.PaymentsClient]);
  return !fieldControl?.hidden?.value ||
  fieldControl?.hidden?.value.toString() !== 'true' ? (
    <StyledGooglePayWrapper id="googlePayContainer"></StyledGooglePayWrapper>
  ) : null;
};

GooglePayButtonField.propTypes = {
  fieldControl: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  googlePayTransactionData: PropTypes.object.isRequired,
  merchantUserMultistepControls: PropTypes.func.isRequired,
  setPaymentControlsData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired
};
/* eslint-enable */

const StatusMessageField = function({
  name,
  fieldControl,
  getControlPropertyDescriptions,
  isMobile,
  ...props
}) {
  const WrapperComponent = isMobile
    ? StyledStatusMessageWrapperMobile
    : StyledStatusMessageWrapper;

  return fieldControl?.hidden?.value === 'true' ? null : (
    <StyledWrapper>
      <WrapperComponent
        {...props}
        name={name}
        state={Number(fieldControl.key.value)}
      >
        <StyledIconWrapper largsSize>
          <StyledIconSmall
            fontSize="24px"
            icon={generateStatusImage(Number(fieldControl.key.value))}
          />
        </StyledIconWrapper>
        <StyledMessageText>
          {getControlPropertyDescriptions.text}
        </StyledMessageText>
      </WrapperComponent>
    </StyledWrapper>
  );
};

StatusMessageField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldControl: PropTypes.object.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

/**
 * Checkbox Field
 */
const CheckboxField = function({
  id,
  name,
  error,
  value,
  onChange,
  getControlPropertyDescriptions,
  fieldControl,
  ...props
}) {
  // const theme = useTheme();

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledCheckboxInputWrapper>
      <StyledCheckboxWrapper>
        <StyledCheckbox
          id={id}
          name={name}
          className="text-input"
          type="checkbox"
          value={value}
          onChange={onChange}
          {...props}
        />
      </StyledCheckboxWrapper>
      <StyledCheckboxInputLabel
        htmlFor={id}
        error={error}
        dangerouslySetInnerHTML={{
          __html: getControlPropertyDescriptions.placeholder
        }}
      />
      {error ? <div className="input-feedback">{error}</div> : null}
    </StyledCheckboxInputWrapper>
    );
};

const QrCodeField = function({
  id,
  fieldControl
}) {
  const data = localStorage.getItem('transactionData');
  const parsedData = JSON.parse(data);

  const src = fieldControl?.url?.value
    ?? parsedData?.paymentSources
      ?.filter(
        (el) => el?.dataType.toString() === fieldControl?.sourcedatatype?.value
      )
      ?.map((el) => el?.data);

  return fieldControl?.hidden?.value
  && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledQrCodeWrapper id={id}>
      <img src={src} alt="QrCode" />
    </StyledQrCodeWrapper>
    );
};

QrCodeField.propTypes = {
  id: PropTypes.number.isRequired,
  fieldControl: PropTypes.object.isRequired
};

CheckboxField.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  fieldControl: PropTypes.object,
  getControlPropertyDescriptions: PropTypes.object.isRequired
};

CheckboxField.defaultProps = {
  name: '',
  label: '',
  error: '',
  fieldControl: null
};

const CopyField = memo(({
  copyValue,
  dataName,
  alertHandler
}) => {
  const {t} = useTranslation();
  const copyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    alertHandler();
    navigator.clipboard.writeText(copyValue);
  };

  return (
    <>
      <StyledItemLabel>
        {t((dataName)?.toLowerCase())}
      </StyledItemLabel>
      <StyledCopyContent>
        <StyledItemValue isCopy>{copyValue}</StyledItemValue>
        <StyledButtonWithoutBg type="button" onClick={copyHandler}>
          <CopyIcon />
        </StyledButtonWithoutBg>
      </StyledCopyContent>
    </>
  );
});

CopyField.propTypes = {
  alertHandler: PropTypes.func.isRequired,
  dataName: PropTypes.string.isRequired,
  copyValue: PropTypes.string.isRequired
};

const HyperLinkField = memo(({href}) => {
  const {t} = useTranslation();

  return (
    <StyledHistoryDetailsDataWrapper>
      <StyledItemLabel>
        {t(('docName').toLowerCase())}
        :
      </StyledItemLabel>
      <StyledLinkItem href={href} target="_blank" rel="noreferrer">
        {t(('seePDF').toLowerCase())}
      </StyledLinkItem>
    </StyledHistoryDetailsDataWrapper>
  );
});

HyperLinkField.propTypes = {
  href: PropTypes.string.isRequired
};

const BackToFormButton = memo(({additionalCallback}) => {
  const {t} = useTranslation();

  const {handlePaymentSystemDetailsPage} = useContext(
    PaymentFormCallbacksContext
  );
  const {
    paymentSystemId,
    platformPsId
  } = useContext(SelectedPaymentDetailsContext);

  const onClick = () => {
    handlePaymentSystemDetailsPage({
      paymentSystemId,
      platformPsId,
      disableMethod: false,
      unavailable: false,
      hasRedirect: false,
      hasVerifiedAccount: true,
      fetchOnlyFormControls: true
    });
    if (typeof additionalCallback === 'function') {
      additionalCallback();
    }
  };

  return (
    <StyledLinkButton
      type="button"
      onClick={onClick}
      centeredButton
    >
      {t(('backToForm')?.toLowerCase())}
    </StyledLinkButton>
  );
});

BackToFormButton.propTypes = {
  additionalCallback: PropTypes.func
};
BackToFormButton.defaultProps = {
  additionalCallback: null
};

function HiddenField({
  fieldControl,
  handleSubmit
}) {
  useEffect(() => {
    if (fieldControl?.url?.value) {
      handleSubmit();
    }
  }, [fieldControl?.url?.value, handleSubmit]);
}

HiddenField.propTypes = {
  fieldControl: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired
};

const SafetyPayGuideAndButtonField = memo(({
  name,
  getControlPropertyDescriptions,
  alertContext,
  isMobile
}) => {
  const {t} = useTranslation();

  const {
    transactionData,
    onPayHandler
  } = useContext(SafetyPayPaymentContext);
  const {
    operation_id: orderId,
    payment_expiration_datetime_utc: expirationDate,
    amount,
    currencyId,
    howto_pay_steps: guide,
    location_url: redirectUrl
  } = transactionData || {};

  const qrCode = useMemo(() => transactionData?.payment_instructions?.find((item) => item?.name === 'QRCodeImageBase64')?.value, [transactionData]);

  return (
    <div
      style={{position: 'relative'}}
    >
      {!isMobile && qrCode ? (
        <div
          style={{
            width: '66.5%',
            height: '100%',
            position: 'absolute',
            top: '0',
            right: '-100%'
          }}
        >
          <p>{t('qrcode')}</p>
          <p>{t('scantopay')}</p>
          <img src={qrCode} alt="" />
        </div>
      ) : null}
      <StyledFlexContainerColumn>
        <StyledHistoryDetailsDataWrapper>
          <CopyField copyValue={orderId} dataName="orderId" alertHandler={() => { alertContext.success(t('copied')); }} />
        </StyledHistoryDetailsDataWrapper>
        <StyledHistoryDetailsDataWrapper>
          <StyledItemLabel>
            {t('exprirationDate')}
            :
          </StyledItemLabel>
          {expirationDate ? (
            <StyledItemValue>
              {formatDate(expirationDate)}
            </StyledItemValue>
          ) : null}
        </StyledHistoryDetailsDataWrapper>
        <StyledHistoryDetailsDataWrapper>
          <StyledItemLabel>
            {t('amount')}
            :
          </StyledItemLabel>
          <StyledItemValue>{`${amount} ${currencyId}`}</StyledItemValue>
        </StyledHistoryDetailsDataWrapper>
      </StyledFlexContainerColumn>
      <StyledListWrapper isMobile={isMobile}>
        <StyledListTitle>{t(('guideTitle')?.toLowerCase())}</StyledListTitle>
        <StyledListContent>
          {guide?.map((item) => (
            <li key={item.step_number}>{item.step_instruction}</li>
          ))}
        </StyledListContent>
      </StyledListWrapper>
      {redirectUrl ? (
        <StyledButton
          type="button"
          onClick={onPayHandler(redirectUrl)}
        >
          {getControlPropertyDescriptions.name ?? name}
        </StyledButton>
      ) : null}
    </div>
  );
});

SafetyPayGuideAndButtonField.propTypes = {
  name: PropTypes.string.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

const DoubleField = function({
  id,
  name,
  error,
  value,
  onChange,
  fieldControl,
  getControlPropertyDescriptions,
  templateType,
  ...props
}) {
  const theme = useTheme();
  const StyledInput = generateMappedComponent(templateType);
  // Maintain local state for the phone number input
  const [phoneNumber, setPhoneNumber] = useState(value || '');

  // Combine the prefix code with the phone number
  const prefix = fieldControl?.countrycode?.value || '';

  return fieldControl?.hidden?.value && fieldControl?.hidden?.value.toString() === 'true' ? null : (
    <StyledInputWrapper isNotRelative>
      {theme.inputHasLabel ? (
        <StyledInputLabel htmlFor={id} error={error}>
          {getControlPropertyDescriptions.name}
        </StyledInputLabel>
      ) : null}
      <StyledFlexContainer hasSmallGap>
        <StyledCountryCodeWrapper>
          <StyledFlagContainer>
            <img src={fieldControl?.countrycodecdn?.value} alt={fieldControl?.countrycode?.value} />
          </StyledFlagContainer>
          {fieldControl?.countrycode?.value}
        </StyledCountryCodeWrapper>
        <StyledInputContainer>
          <StyledInput
            name={name}
            id={id}
            key={id}
            className="text-input"
            value={phoneNumber}
            type={
              fieldControl?.hidden?.value
              && fieldControl?.hidden?.value.toString() === 'true'
                ? 'hidden'
                : 'text'
            }
            placeholder={getControlPropertyDescriptions.placeholder}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              onChange({
                target: {
                  name,
                  value: `${prefix}${e.target.value}`
                }
              });
            }}
            {...props}
            hasError={error}
          />
        </StyledInputContainer>
      </StyledFlexContainer>
      {error ? <StyledInputError>{error}</StyledInputError> : null}
    </StyledInputWrapper>
  );
};

DoubleField.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getControlPropertyDescriptions: PropTypes.object.isRequired,
  fieldControl: PropTypes.object,
  templateType: PropTypes.number
};

DoubleField.defaultProps = {
  error: '',
  fieldControl: null,
  templateType: 1
};

const GeneratedCodesField = memo(() => {
  const {t} = useTranslation();

  const {siteId, userId} = useContext(CashierDetailsContext);
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {
    setIsLoading,
    getGeneratedActiveCodesList,
    setGeneratedActiveCodesList,
    openActiveCodesList,
    getActiveCodesCount
  } = useContext(PaymentFormCallbacksContext);

  const listLength = getActiveCodesCount();

  useEffect(() => {
    setIsLoading(true);
    getGeneratedActiveCodesList({
      paymentSystemId,
      siteId,
      userId
    }).then((response) => {
      setGeneratedActiveCodesList(response);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  return listLength === 0 ? null : (
    <div>
      <div>
        <span>Icon</span>
        <span>{`${listLength} ${t(listLength === 1 ? 'activebarcode' : 'activebarcodes')}`}</span>
      </div>
      {listLength > 0 ? <button type="button" onClick={() => openActiveCodesList(true)}>{t('open')}</button> : null}
    </div>
  );
});

export {
  TextBoxField,
  LabelField,
  DynamicLabelField,
  DynamicDropdownField,
  BankAmountListField,
  SelectField,
  NewSelectField,
  ButtonOptionsDropdown,
  ButtonField,
  ApplePayButtonField,
  ButtonWithMultiUrl,
  StatusMessageField,
  QrCodeField,
  CheckboxField,
  PayPalButtonField,
  CopyField,
  // DebitOrCreditCardButtonField
  GooglePayButtonField,
  HiddenField,
  BackToFormButton,
  HyperLinkField,
  SafetyPayGuideAndButtonField,
  DoubleField,
  CardNumberInputField,
  GeneratedCodesField,
  ExpirationDateField,
  CVVField
};
