import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import BonusItem from './bonusItem';
import {generatePaymentDataForBonusList} from '../../../../helpers/common';

/* Styled Components */
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import {
  StyledBackButtonWrapper,
  StyledButtonWithoutBgWithMessage
} from '../../../../styledComponents/button/button.styled';
import StyledBonusMainWrapper, {
  StyledBonusList,
  StyledFlexContainerColumnForBonus
} from './bonusListWeb.styled';

function BonusesListWeb({
  bonusesList,
  simplifiedData,
  goToPaymentsList,
  setSelectedBonusHandler,
  navigateToBonusInfo,
  handlePaymentSystemDetailsPage
}) {
  const {t} = useTranslation();

  return (
    <StyledFlexContainerColumnForBonus>
      <StyledBackButtonWrapper>
        <StyledButtonWithoutBgWithMessage
          type="button"
          onClick={goToPaymentsList}
        >
          <StyledIconSmall icon="arrowLeft" fontSize="11px" />
          {t(('backToList')?.toLowerCase())}
        </StyledButtonWithoutBgWithMessage>
      </StyledBackButtonWrapper>
      <StyledBonusMainWrapper>
        <StyledBonusList>
          {bonusesList.map(({
            id, webImage, shortDescription, name, payments
          }) => (
            <BonusItem
              key={id}
              id={id}
              imageUrl={webImage}
              name={name}
              shortDescription={shortDescription}
              payments={generatePaymentDataForBonusList(payments, simplifiedData)}
              setSelectedBonusHandler={setSelectedBonusHandler}
              navigateToBonusInfo={navigateToBonusInfo}
              handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
            />
          ))}
        </StyledBonusList>
      </StyledBonusMainWrapper>
    </StyledFlexContainerColumnForBonus>
  );
}

BonusesListWeb.propTypes = {
  bonusesList: PropTypes.array.isRequired,
  simplifiedData: PropTypes.array.isRequired,
  goToPaymentsList: PropTypes.func.isRequired,
  setSelectedBonusHandler: PropTypes.func.isRequired,
  navigateToBonusInfo: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default BonusesListWeb;
