import React, {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Config from '../../../configs/mainConfig';
import {
  controlValidationScheme,
  formattedControls,
  initialValues,
  validateCardNumberFromPayloadValues
} from '../../../helpers/common';
import {buttonControls} from '../../../constants/common';
import {CashierDetailsContext} from '../../../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../../../contexts/selectedPaymentDetailsContext';
import {PaymentFormCallbacksContext} from '../../../contexts/paymentFormCallbacksContext';
import ConektaCardPaymentForm from '../../conektaCardPaymentForm/conektaCardPaymentForm';
import RedirectToAML from '../../redirectToAML/redirectToAML';
import {usePrevious} from '../../../hooks/usePrevious';
import {PAYMENT_CONTROL_TYPE} from '../../../constants/formControlType';
import {PaymentBonusesContext} from '../../../contexts/paymentBonusesContext';
import {UploadedFilesContext} from '../../../contexts/uploadedFilesContext';

function EnhancedFormik({
  baseUrl,
  applePaySession,
  alertContext,
  autoGeneratedForm,
  payPalTransactionData,
  googlePayTransactionData,
  formComponent: FormComponent,
  deviceType,
  isCurrencySymbol,
  paymentControlsData,
  templateType,
  openPaymentFormBonusesForWeb
}) {
  const {
    siteId,
    userId,
    operationType,
    currency,
    language,
    token
  } = useContext(CashierDetailsContext);
  const {
    platformPaymentSystemId, isOffline, multistep: isMultiStep, paymentSystemId
  } = useContext(SelectedPaymentDetailsContext);
  const {handleSubmitForm} = useContext(PaymentFormCallbacksContext);
  const {selectedBonusId} = useContext(PaymentBonusesContext);

  const [url, setUrl] = useState(null);
  const [secondaryUrl, setSecondaryUrl] = useState('');
  const [mergedControls, setMergedControls] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dynamicUrlProps, setDynamicUrlProps] = useState({});
  const [showBonusCancellationWarning, setShowBonusCancellationWarning] = useState(false);
  const [conektaTransactionData, setConektaTransactionData] = useState(null);
  const [formValuesBackup, setFormValuesBackup] = useState(null);
  const [AMLRedirectionMessage, setAMLRedirectionMessage] = useState(null);

  const previousPaymentSystemId = usePrevious(paymentSystemId);

  const formikRef = useRef({});

  const controlsFields = paymentControlsData?.fields;
  const foundDynamicControlGroupItems = controlsFields?.find((field) => field.dynamicControlGroupItems);

  useEffect(() => {
    if (!!paymentSystemId && !!previousPaymentSystemId && paymentSystemId !== previousPaymentSystemId) {
      setShowBonusCancellationWarning(false);
      setConektaTransactionData(null);
      setFormValuesBackup(null);
      setAMLRedirectionMessage(null);
    }
  }, [paymentSystemId, previousPaymentSystemId]);

  /**
   *   Search for the "ButtonWithUrl && ButtonWithMultiUrl" control in the response
   *    Use optional chaining to check if buttonWithUrlControl is defined
   *    Set the URL state with dynamicUrl or a static URL if not found
   */
  const findFieldControlValue = (buttonWithUrlControl, controlPropertyTypeName) => buttonWithUrlControl?.fieldControls?.find(
    (controlProperty) => controlProperty.controlPropertyTypeName === controlPropertyTypeName
  )?.value;
  const ref = useRef({uploadedFiles});

  ref.current.uploadedFiles = uploadedFiles;

  const handleUrlCheck = () => {
    const buttonWithUrlControl = paymentControlsData?.fields?.find((field) => buttonControls.includes(field.controlTypeId));

    const dynamicUrl = findFieldControlValue(buttonWithUrlControl, 'url');
    const secondUrl = findFieldControlValue(buttonWithUrlControl, 'secondaryurl');

    const step = findFieldControlValue(buttonWithUrlControl, 'step');
    const withParams = findFieldControlValue(buttonWithUrlControl, 'withparams');
    const httpMethodType = findFieldControlValue(buttonWithUrlControl, 'httpmethodtype');

    setUrl(dynamicUrl || `${baseUrl}${Config.apiURLs.transactionCreate}`);
    setSecondaryUrl(secondUrl || '');
    setDynamicUrlProps({
      step,
      withParams,
      httpMethodType
    });
  };

  useEffect(() => {
    handleUrlCheck();
  }, [paymentControlsData]);

  const handlePreviousValue = (newVal) => {
    formikRef.current = {
      ...formikRef.current,
      ...newVal
    };
  };

  const formikEnhancer = withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object()
      .shape(
        controlValidationScheme(
          mergedControls?.filter(({name}) => name !== 'UploadDocument '),
          formattedControls(mergedControls?.filter(({name}) => name !== 'UploadDocument ')),
          {},
          language
        )
      ),

    mapPropsToValues: ({initialProps}) => ({
      ...initialProps
    }),

    handleSubmit: (payload, {setSubmitting, resetForm}) => {
      const resetFormHandler = () => {
        formikRef.current = {};
        setUploadedFiles([]);
        localStorage.setItem('amountValue', '');
        resetForm();
      };

      setSubmitting(false);
      const payloadValues = Object.keys(payload)
        .reduce((acc, key) => {
          if (payload[key] !== '') {
            acc[key] = payload[key];
          }
          return acc;
        }, {});

      const {amount} = payload;

      /**
       * It's added for move from first step to second step info
       */
      if (dynamicUrlProps.withParams === 'true') {
        localStorage.setItem('amountValue', amount);
      }

      if (controlsFields?.find((field) => field?.controlTypeId === PAYMENT_CONTROL_TYPE.CARD_NUMBER_INPUT) && !validateCardNumberFromPayloadValues(payloadValues)) {
        return;
      }
      handleSubmitForm({
        url,
        userId,
        paymentSystemId: paymentControlsData?.paymentSystemId,
        platformPaymentSystemId,
        payload,
        siteId,
        currency,
        operationType,
        payloadValues: {...payloadValues, proofImageInfo: ref.current.uploadedFiles},
        token,
        isMultiStep,
        secondaryUrl,
        dynamicUrlProps,
        setConektaTransactionData,
        setAMLRedirectionMessage,
        selectedBonusId,
        resetFormHandler
      })
        .then((isSelectedBonusCanceled) => {
          setFormValuesBackup(isSelectedBonusCanceled ? payloadValues : null);
          setShowBonusCancellationWarning(isSelectedBonusCanceled);
        });
      handlePreviousValue({});
    }
  });

  const EnhancedForm = useMemo(() => formikEnhancer(FormComponent), [FormComponent, url, mergedControls, selectedBonusId]);

  const closeConektaPaymentHandler = useCallback(() => {
    setConektaTransactionData(null);
  }, []);

  const cancelBonusAndProceedWithdrawalHandler = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    handleSubmitForm({
      url,
      userId,
      paymentSystemId: paymentControlsData?.paymentSystemId,
      platformPaymentSystemId,
      siteId,
      currency,
      operationType,
      payloadValues: {...formValuesBackup, proofImageInfo: ref.current.uploadedFiles},
      token,
      isMultiStep,
      secondaryUrl,
      dynamicUrlProps,
      cancelActiveBonus: true,
      setAMLRedirectionMessage
    })
      .then(() => {
        setFormValuesBackup(null);
        setShowBonusCancellationWarning(false);
      });
  }, [url, formValuesBackup]);

  useEffect(() => {
    if (!mergedControls?.length) {
      setMergedControls(paymentControlsData?.fields);
    }
  }, [paymentControlsData, selectedValue]);

  useEffect(() => {
    if (foundDynamicControlGroupItems && selectedValue) {
      setMergedControls([]);
      const {dynamicControlGroupItems} = foundDynamicControlGroupItems;
      const foundFields = dynamicControlGroupItems?.find((group) => group.groupId === +selectedValue.value);
      const penultimateIndex = controlsFields.length - 1;
      const updatedControls = [...controlsFields];
      if (foundFields?.fields) {
        updatedControls.splice(penultimateIndex, 0, ...foundFields.fields);
        setMergedControls(updatedControls);
      } else {
        setMergedControls(updatedControls);
      }

      setSelectedValue(selectedValue);
    } else {
      setMergedControls(controlsFields);
    }
  }, [selectedValue, controlsFields]);

  useEffect(() => {
    formikRef.current = {};
    setSelectedValue(null);
  }, [paymentControlsData?.paymentSystemId]);

  const onCancelWithdrawalHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowBonusCancellationWarning(false);
  }, []);

  // eslint-disable-next-line no-nested-ternary
  return conektaTransactionData?.CheckoutRequestId ? (
    <ConektaCardPaymentForm
      checkoutRequestId={conektaTransactionData.CheckoutRequestId}
      closePaymentHandler={closeConektaPaymentHandler}
      alertContext={alertContext}
    />
  ) : AMLRedirectionMessage ? (
    <RedirectToAML message={AMLRedirectionMessage} />
  ) : (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UploadedFilesContext.Provider value={{uploadedFiles, setUploadedFiles}}>
      <EnhancedForm
        formattedControls={formattedControls(mergedControls)}
        controls={mergedControls}
        initialProps={{...initialValues(mergedControls), ...formikRef.current}}
        paymentSystemId={paymentControlsData?.paymentSystemId}
        applePaySession={applePaySession}
        alertContext={alertContext}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        handlePreviousValue={handlePreviousValue}
        favoriteAmounts={paymentControlsData?.favAmounts}
        autoGeneratedForm={autoGeneratedForm}
        isOffline={isOffline}
        payPalTransactionData={payPalTransactionData}
        googlePayTransactionData={googlePayTransactionData}
        isFileUploaded={ref.current.uploadedFiles.length > 0}
        deviceType={deviceType}
        foundDynamicControlGroupItems={foundDynamicControlGroupItems}
        currency={currency}
        isCurrencySymbol={isCurrencySymbol}
        hasVerificationHistory={paymentControlsData.hasVerificationHistory}
        showBonusCancellationWarning={showBonusCancellationWarning}
        onCancelWithdrawalHandler={showBonusCancellationWarning ? onCancelWithdrawalHandler : null}
        cancelBonusAndProceedWithdrawalHandler={showBonusCancellationWarning ? cancelBonusAndProceedWithdrawalHandler : null}
        templateType={templateType}
        openPaymentFormBonusesForWeb={openPaymentFormBonusesForWeb}
      />
    </UploadedFilesContext.Provider>
  );
}

EnhancedFormik.defaultProps = {
  baseUrl: null,
  autoGeneratedForm: false,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  openPaymentFormBonusesForWeb: null
};

EnhancedFormik.propTypes = {
  baseUrl: PropTypes.string,
  openPaymentFormBonusesForWeb: PropTypes.func,
  applePaySession: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired,
  autoGeneratedForm: PropTypes.bool,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  formComponent: PropTypes.element.isRequired,
  deviceType: PropTypes.number.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  paymentControlsData: PropTypes.object.isRequired,
  templateType: PropTypes.number.isRequired
};
export default memo(EnhancedFormik);
