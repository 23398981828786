import styled from 'styled-components';

const StyledMainWrapperWeb = styled.main`
  width: 100%;
  padding: 24px 24px 28px 24px;
  background-color: ${(props) => props.theme.generalBackgroundColor};
  
  i {
    color: ${(props) => props.theme.generalIconsColor};
  }
`;

const StyledMainWrapperMobile = styled(StyledMainWrapperWeb)`
  padding: 24px 8px 64px;
`;

export {
  StyledMainWrapperWeb,
  StyledMainWrapperMobile
};
