import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import BonusPaymentItem from '../../../bonusPaymentItem/bonusPaymentItem';
import {decodeHTML, findMinMaxAmountsForBonus} from '../../../../helpers/common';

/* Styled Components */
import {
  StyledAddButtonWrapper,
  StyledBackButtonWrapper,
  StyledButtonWithoutBgWithMessage
} from '../../../../styledComponents/button/button.styled';
import StyledBonusMainWrapper, {
  StyledBonusPaymentsList,
  StyledCardFigcaption,
  StyledCardFigcaptionDescription,
  StyledCardFigcaptionTitle,
  StyledCardFigure,
  StyledFlexContainerColumnForBonus,
  StyledLineSpan,
  StyledPictureForNestedPage
} from '../../../bonusesList/templateOne/web/bonusListWeb.styled';
import StyledBonusInfoWrapper, {
  StyledBonusInfoContent,
  StyledBonusInfoDescription,
  StyledBonusInfoList,
  StyledBonusInfoListItem,
  StyledBonusInfoListItemKey,
  StyledBonusInfoListItemValue
} from './bonusInfoWeb.styled';

/* Assets */
import {
  Date, Id, MinDepositAmount, Type
} from '../../../../assets/icons';

function BonusInfoWeb({
  currency,
  bonusInfo,
  navigateToBonusesList,
  handlePaymentSystemDetailsPage
}) {
  const {t} = useTranslation();
  const [seeAllPayments, setSeeAllPayments] = useState(false);

  const {
    id,
    webImage,
    name,
    conditions,
    payments,
    shortDescription,
    description,
    bonusType,
    expirationPeriod
  } = bonusInfo;
  const [minAmount] = findMinMaxAmountsForBonus(conditions);

  const onSeeAllPaymentsToggleHandler = () => {
    setSeeAllPayments((prev) => !prev);
  };

  const onPaymentClickHandler = useCallback((e, {
    disableMethod,
    paymentSystemId,
    unavailable,
    hasRedirect,
    hasVerifiedAccount
  }) => {
    e.preventDefault();
    e.stopPropagation();
    handlePaymentSystemDetailsPage({
      disableMethod,
      paymentSystemId,
      unavailable,
      hasRedirect,
      hasVerifiedAccount
    });
  }, []);

  return (
    <StyledFlexContainerColumnForBonus>
      <StyledBackButtonWrapper>
        <StyledButtonWithoutBgWithMessage type="button" onClick={navigateToBonusesList}>
          <StyledIconSmall icon="arrowLeft" fontSize="11px" />
          {t(('backToList')?.toLowerCase())}
        </StyledButtonWithoutBgWithMessage>
      </StyledBackButtonWrapper>
      <StyledBonusMainWrapper minSpaces>
        <StyledCardFigure>
          <StyledPictureForNestedPage>
            <img src={webImage} alt="" />
          </StyledPictureForNestedPage>
          <StyledCardFigcaption>
            <StyledCardFigcaptionTitle
              bigSize
            >
              {name}
            </StyledCardFigcaptionTitle>
            <StyledCardFigcaptionDescription>
              {shortDescription}
            </StyledCardFigcaptionDescription>
          </StyledCardFigcaption>
          <>
            <StyledBonusPaymentsList
              seeAllPayments={seeAllPayments || payments?.length < 6}
            >
              {payments?.map((payment) => <BonusPaymentItem key={payment.id} {...payment} onPaymentClickHandler={onPaymentClickHandler} />)}
            </StyledBonusPaymentsList>
            {payments?.length > 5 ? (
              <StyledAddButtonWrapper>
                <StyledButtonWithoutBgWithMessage
                  type="button"
                  centerAlignedWithinHorizontalLines
                  onClick={onSeeAllPaymentsToggleHandler}
                >
                  <StyledIconSmall icon={seeAllPayments ? 'arrowUp' : 'arrowDown'} fontSize="11px" />
                  {t(seeAllPayments ? 'seelesspayments' : 'seeallpayments')}
                </StyledButtonWithoutBgWithMessage>
                <StyledLineSpan />
              </StyledAddButtonWrapper>
            ) : null}
          </>
          <StyledBonusInfoWrapper>
            <StyledBonusInfoContent>
              <StyledBonusInfoDescription
                dangerouslySetInnerHTML={{
                  __html: decodeHTML(description)
                }}
              />
            </StyledBonusInfoContent>
            <StyledBonusInfoList>
              <StyledBonusInfoListItem>
                <MinDepositAmount />
                <StyledBonusInfoListItemKey>
                  {t('mindepositamount')}
                  :
                  <StyledBonusInfoListItemValue>
                    {`${minAmount} ${currency}`}
                  </StyledBonusInfoListItemValue>
                </StyledBonusInfoListItemKey>
              </StyledBonusInfoListItem>
              <StyledBonusInfoListItem>
                <Id />
                <StyledBonusInfoListItemKey>
                  {t('id')}
                  :
                  <StyledBonusInfoListItemValue>
                    {id}
                  </StyledBonusInfoListItemValue>
                </StyledBonusInfoListItemKey>
              </StyledBonusInfoListItem>
              <StyledBonusInfoListItem>
                <Type />
                <StyledBonusInfoListItemKey>
                  {t('type')}
                  :
                  <StyledBonusInfoListItemValue>
                    {bonusType}
                  </StyledBonusInfoListItemValue>
                </StyledBonusInfoListItemKey>
              </StyledBonusInfoListItem>
              <StyledBonusInfoListItem>
                <Date />
                <StyledBonusInfoListItemKey>
                  {t('expiration')}
                  :
                  <StyledBonusInfoListItemValue>
                    {expirationPeriod}
                  </StyledBonusInfoListItemValue>
                </StyledBonusInfoListItemKey>
              </StyledBonusInfoListItem>
            </StyledBonusInfoList>
          </StyledBonusInfoWrapper>
        </StyledCardFigure>
      </StyledBonusMainWrapper>
    </StyledFlexContainerColumnForBonus>
  );
}

BonusInfoWeb.propTypes = {
  currency: PropTypes.string.isRequired,
  bonusInfo: PropTypes.object.isRequired,
  navigateToBonusesList: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default BonusInfoWeb;
