import styled from 'styled-components';
import {StyledMainFormAndInfoWrapper, StyledPaymentDetailWrapper} from '../web/formWeb.styled';

const StyledPaymentDetailWrapperMobile = styled(StyledPaymentDetailWrapper)`
  margin: 0 -16px 12px;
  padding: 16px 12px;
  border-bottom: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
`;

const StyledPaymentInfoWrapperMobile = styled.div`
  padding: 0 16px 16px;
`;

const StyledMainFormAndInfoWrapperMobile = styled(StyledMainFormAndInfoWrapper)`
  padding: 24px 16px 16px;
`;

export {
  StyledPaymentDetailWrapperMobile,
  StyledPaymentInfoWrapperMobile,
  StyledMainFormAndInfoWrapperMobile
};
