import React, {memo} from 'react';
import PropTypes from 'prop-types';
import RightSideDeletableOptions from './rightSideDeletableOption';

// styled
import {StyledCardTab} from '../tabs/tabs.styled';

function PaymentSelectDeletableOptions({optionsList}) {
  return (
    <StyledCardTab>
      {optionsList.map((option) => (
        <RightSideDeletableOptions key={option.name} {...option} />
      ))}
    </StyledCardTab>
  );
}

PaymentSelectDeletableOptions.propTypes = {
  optionsList: PropTypes.array.isRequired
};

export default memo(PaymentSelectDeletableOptions);
