import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode} from '../../helpers/common';
import {
  StyledHistoryDetailsDataWrapper,
  StyledItemLabel,
  StyledItemValue,
  StyledTaxAncCommissionsWrapper
} from '../paymentsHistory/templateOne/web/historyWeb.styled';

function TaxAndCommissions({
  amount,
  tax,
  currency,
  isCurrencySymbol,
  isMobile
}) {
  const {t} = useTranslation();
  return (
    <StyledTaxAncCommissionsWrapper isMobile={isMobile}>
      <StyledHistoryDetailsDataWrapper>
        <StyledItemLabel>{t('depositamount')}</StyledItemLabel>
        <StyledItemValue>
          {amount}
          {' '}
          {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
        </StyledItemValue>
      </StyledHistoryDetailsDataWrapper>
      <StyledHistoryDetailsDataWrapper>
        <StyledItemLabel>{t('taxamount')}</StyledItemLabel>
        <StyledItemValue>
          {tax}
          {' '}
          {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
        </StyledItemValue>
      </StyledHistoryDetailsDataWrapper>
    </StyledTaxAncCommissionsWrapper>
  );
}

TaxAndCommissions.propTypes = {
  amount: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired
};
export default TaxAndCommissions;
