import styled, {css} from 'styled-components';
import {fontStyle, truncate} from '../../../../helpers/styled';
import {StyledMainWrapper} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';

const StyledBonusMainWrapper = styled(StyledMainWrapper)`
  background-color: ${({theme}) => theme.surface.surface};
  padding: ${({minSpaces}) => (minSpaces ? '12px 16px' : '24px 16px')};
`;

const StyledBonusList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 4px)); /* 4px is half of the 8px gap */
  grid-gap: 8px;
`;

const StyledBonusListItem = styled.li`
  background-color: ${({theme}) => theme.surface.surfaceTertiary};
  border: 1px solid ${({theme}) => theme.border.borderSecondary};
  border-radius: 8px;
  cursor: pointer;
  
  ${(props) => props.nestedFormPage && css`
    background-color: ${({theme}) => `${theme.textContentColor}30`};
    border: 1px solid ${({theme}) => `${theme.textContentColor}30`};
  `}
    
  &:hover {
    .bonusLargeBanner {
      transform: scale(1.1);
    }
  }
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  grid-gap: 8px;
`;

const StyledCardFigure = styled.figure`
  border-radius: inherit
`;

const StyledPicture = styled.picture`
  height: 164px;
  max-height: 164px;
  width: 100%;
  display: block;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms;
  }
`;

const StyledPictureForNestedPage = styled(StyledPicture)`
  height: 321px;
  max-height: 321px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 4px;
`;

const StyledCardFigcaption = styled.figcaption`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  padding: 8px 8px 16px;
  width: 100%;
`;

const StyledLineSpan = styled.span`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.border.borderSecondary}
`;

const StyledBonusPaymentsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 8px;
  height: ${(props) => (props.seeAllPayments ? 'fit-content' : '172px')};
  overflow: hidden;
  transition: all 0.5s ease;
`;

const StyledCardFigcaptionTitle = styled.h3`
  ${fontStyle('semiBold')};
  font-size: ${(props) => (props.bigSize ? '24px' : props.theme.typographyFontSizeLarge)};
  line-height: 24px;
  color: ${({theme}) => theme.text.text};
  ${truncate};
`;

const StyledCardFigcaptionDescription = styled.p`
  ${fontStyle('regular')};
  font-size: ${({theme}) => theme.typographyFontSizeMiddle};
  line-height: 24px;
  color: ${({theme}) => theme.text.textSecondary};
  ${truncate};
`;

const StyledFlexContainerColumnForBonus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;
  background-color: ${({theme}) => theme.surface.surface};
`;

export default StyledBonusMainWrapper;
export {
  StyledBonusList,
  StyledBonusListItem,
  StyledCardFigure,
  StyledPicture,
  StyledPictureForNestedPage,
  StyledCardFigcaption,
  StyledCardFigcaptionTitle,
  StyledCardFigcaptionDescription,
  StyledLineSpan,
  StyledBonusPaymentsList,
  StyledFlexContainerColumnForBonus,
  StyledInfoWrapper
};
