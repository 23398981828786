import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';
import {
  DEFAULT_LANGUAGE,
  CONTROL_STATE,
  DEVICE_SIZE,
  DEVICE_TYPE,
  PAYMENTS_IDS, CASHIER_MODE, OPERATION_TYPE_BY_NUMBER
} from '../constants/common';

/* Assets */
import inProcess from '../assets/images/inProcess.svg';
import successIcon from '../assets/images/success.svg';
import failIcon from '../assets/images/failIcon.svg';

/**
 * Sets value to local storage
 *
 * @param {string} key
 * @param {*} value
 */
export const localStorageSet = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Gets value from local storage
 *
 * @param {string} key
 */
export const localStorageGet = (key) => JSON.parse(localStorage.getItem(key));


/**
 * Checks form's field required validation
 *
 * @param {string|array} value
 * @param {boolean} isRequired
 *
 * @return {boolean}
 */
export const checkRequired = (value, isRequired) => {
  if (Array.isArray(value)) {
    return !value.length && isRequired;
  }
  return !value && isRequired;
};


/**
 * Checks form's field regex validation
 *
 * @param {string} value
 * @param {string} regex
 * @param {boolean} [trimValue]
 *
 * @return {boolean} isValid
 */
export const checkRegexp = (value, regex, trimValue) => {
  const fieldRegex = new RegExp(regex);
  if (trimValue) {
    return value && !fieldRegex.test(value.trim());
  }
  return value && !fieldRegex.test(value);
};


/**
 * Checks form's field minimum length validation
 *
 * @param {string} value
 * @param {string} minLength
 * @param {string} [regex]
 *
 * @return {boolean} isValid
 */
export const checkMinLength = (value, minLength, regex) => {
  const trimedValue = value?.toString()?.trim();
  if (regex) {
    const fieldRegex = new RegExp(regex);
    return trimedValue && fieldRegex.test(trimedValue) && trimedValue.length < minLength;
  }
  return trimedValue && trimedValue.length < minLength;
};

/**
 * Checks form's field maximum length validation
 *
 * @param {string} value
 * @param {string} maxLength
 * @param {string} [regex]
 *
 * @return {boolean} isValid
 */
export const checkMaxLength = (value, maxLength, regex) => {
  const trimedValue = value?.toString()?.trim();
  if (regex) {
    const fieldRegex = new RegExp(regex);
    return trimedValue && fieldRegex.test(trimedValue) && trimedValue.length > maxLength;
  }
  return trimedValue && trimedValue.length > maxLength;
};

/**
 * Checks form's fields values equality
 *
 * @param {string} value
 * @param {string} repeatValue
 *
 * @return {boolean} isValid
 */
export const checkMatching = (value, repeatValue) => value && repeatValue && value !== repeatValue;

export const getLocalizedValue = (propertyDescriptions, language) => (propertyDescriptions[language]
  ? propertyDescriptions[language] : propertyDescriptions[DEFAULT_LANGUAGE]);

export const getControlPropertyDescriptions = (fieldControls, language) => {
  const controlProperties = {};
  fieldControls.forEach((control) => {
    const propertyName = control.controlPropertyTypeName;
    const {propertyDescriptions} = control;
    controlProperties[propertyName] = getLocalizedValue(propertyDescriptions, language || DEFAULT_LANGUAGE);
  });

  return controlProperties;
};

export const controlValidationScheme = (
  paymentInfo,
  formattedControls,
  validationSchemas,
  lang
) => {
  paymentInfo?.fields.forEach((curr) => {
    const controlProperties = getControlPropertyDescriptions(curr.fieldControls, lang);
    const isRequired = formattedControls[curr.key]?.required?.value;
    const isRegexpRequired = formattedControls[curr.key]?.regexp?.value;

    let schema = Yup.string();

    if (isRequired) {
      schema = schema.required(controlProperties.required);
    }

    if (isRegexpRequired) {
      schema = schema.matches(
        new RegExp(formattedControls[curr.key]?.regexp?.value),
        controlProperties.regexp
      );
    }

    validationSchemas[curr.key] = schema;
  });

  return validationSchemas;
};

export const generateStatusImage = (state) => {
  switch (state) {
    case CONTROL_STATE.IN_PROCESS:
      return `<img src=${inProcess} alt="In Process">`;
    case CONTROL_STATE.FAIL:
      return `<img src=${failIcon} alt="Fail">`;
    case CONTROL_STATE.SUCCESS:
      return `<img src=${successIcon} alt="Success">`;
    default:
      return null;
  }
};

/**
 * Computes the SHA256 hash from a given input string.
 *
 * @param {string} input - The input string to compute the hash from.
 * @returns {string} The SHA256 hash in hexadecimal format.
 */
export const computeSha256HashFromString = (input) => {
  const hash = sha256(input);
  return hash.toString(CryptoJS.enc.Hex);
};

/**
 * Check param is Object
 * @param {object | null} value
 * @returns {boolean}
 */
export const isObject = (value) => typeof value === 'object' && value !== null && !Array.isArray(value);


/**
 * DON'T KILL ME,
 * I DON'T HAVE ANY CHANCE
 * BACK-END THREATENED ME
 * IT WILL BE REMOVED, AFTER LUNCH
 * */
export const flattenObject = (obj) => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string') {
      if (key === 'CardNumber') obj[key] = obj[key].split(' ').join('');
      try {
        const parsedValue = JSON.parse(obj[key]);
        if (typeof parsedValue === 'object' && parsedValue !== null) {
          Object.assign(result, flattenObject(parsedValue, key));
        } else {
          result[key] = obj[key];
        }
      } catch (e) {
        // Property is not a JSON string, add it to the result
        result[key] = obj[key];
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(result, flattenObject(obj[key], key));
    } else {
      // Property is not a string or object, add it to the result
      result[key] = obj[key];
    }
  });

  return result;
};

/** Check if client is PWApp application
* @returns {bool} True if the client is PWApp*/
export const isPWApp = () => {
  const PWAPP_IDENTIFICATOR = 'MobApp';
  const userAgentString = navigator.userAgent;
  return !!userAgentString?.includes(PWAPP_IDENTIFICATOR);
};

/** Check if client is Mobile Native Application
* @returns {bool} True if the client is Mobile Native Application*/
export const isMobNativeApp = () => {
  const NATIVE_APP_IDENTIFICATOR = 'MobNative';
  const userAgentString = navigator.userAgent;
  return !!userAgentString?.includes(NATIVE_APP_IDENTIFICATOR);
};

/** Check if client is web view application
* @returns {bool} True if the client is Mobile alternative Application (telegram)*/
export const isMobAltApp = () => {
  const ALT_APP_IDENTIFICATOR = 'wv';
  const userAgentString = navigator.userAgent;
  return !!userAgentString?.includes(ALT_APP_IDENTIFICATOR);
};

export const isIOSWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

  const isWebViewUA = !window.MSStream && isIOS && (/CriOS/i.test(userAgent) || /FxiOS/i.test(userAgent) || /Safari/i.test(userAgent) === false);

  const isStandalone = window.navigator.standalone;
  const isSafari = /Safari/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent);
  const isWebViewFD = !isSafari && !isStandalone && isIOS;

  const isWebViewJS = window.webkit && window.webkit.messageHandlers && typeof window.webkit.messageHandlers.someHandler !== 'undefined';

  return isWebViewUA || isWebViewFD || isWebViewJS;
};

const isWindowsWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (window.process && window.process.versions && window.process.versions.electron) {
    return true;
  }

  if (/Electron|Win32|Windows NT/.test(userAgent)) {
    return true;
  }

  if (window.external && typeof window.external.notify === 'function') {
    return true;
  }
  return false;
};

export const isMobileApp = () => isPWApp() || isMobNativeApp() || isMobAltApp() || isWindowsWebView();

/** Updates Transactions data
 * @returns {object}*/
export function updateTransactionsData(paymentSystemId, {data}, transactionDataSetters, additionalKeysForSafetyPay = {}) {
  if (PAYMENTS_IDS.SAFETY_PAY_IDS.indexOf(paymentSystemId) !== -1) {
    return transactionDataSetters.safetyPay({...JSON.parse(data?.customScript), ...additionalKeysForSafetyPay});
  }
  switch (paymentSystemId) {
    case PAYMENTS_IDS.APPLE_PAY_ID:
      return transactionDataSetters.applePay(data);
    case PAYMENTS_IDS.PAYPAL_ID:
      return transactionDataSetters.payPal(JSON.parse(data?.customScript));
    case PAYMENTS_IDS.GOOGLE_PAY_ID:
      return transactionDataSetters.googlePay(JSON.parse(data?.customScript));
    default:
      return null;
  }
}


/**
 * Get token string and check is valid JWT token
 * @param {string} token
 * @returns {boolean}
 */
export const isJWT = (token) => {
  const parts = token.split('.');

  if (parts.length !== 3) {
    return false;
  }

  const header = JSON.parse(atob(parts[0]));
  const payload = JSON.parse(atob(parts[1]));

  if (!header || !payload) {
    return false;
  }

  return 'exp' in payload;
};


/**
 * Determines the device type based on the current window width.
 * @returns {string} The device type, either DEVICE_TYPE.MOBILE or DEVICE_TYPE.WEB.
 */
export const getInitialDeviceType = () => (window.innerWidth < DEVICE_SIZE.MOBILE ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.WEB);

export const gettingOperationType = (cashierMode, activeTab) => {
  switch (cashierMode) {
    case CASHIER_MODE.WITHDRAWAL:
      return OPERATION_TYPE_BY_NUMBER.WITHDRAWAL;
    case CASHIER_MODE.DEPOSIT:
    case CASHIER_MODE.QUICK_FORM:
      return OPERATION_TYPE_BY_NUMBER.DEPOSIT;
    case CASHIER_MODE.BOTH:
      return Number(activeTab) === OPERATION_TYPE_BY_NUMBER.WITHDRAWAL
        ? OPERATION_TYPE_BY_NUMBER.WITHDRAWAL
        : OPERATION_TYPE_BY_NUMBER.DEPOSIT;
    default:
      return 0;
  }
};

export const objectHasNonNullValue = (object) => {
  if (typeof object === 'object' && object !== null) {
    return Object.keys(object).some((key) => {
      const value = object[key];
      if (value !== null && typeof value === 'object') {
        return objectHasNonNullValue(value);
      }
      return value !== null;
    });
  }
  return false;
};

export const onSafetyPayHelper = (redirectUrl) => () => {
  if (isIOSWebView()) {
    setTimeout(() => {
      window.open(redirectUrl, '_blank');
    }, 100);
  } else {
    window.open(redirectUrl, '_blank');
  }
};

export const groupBonusesByPaymentsId = (bonuses) => {
  const groupedByPaymentsId = {};

  bonuses.forEach((bonus) => {
    bonus?.payments?.forEach((paymentSystem) => {
      const paymentSystemId = paymentSystem.id;
      if (!groupedByPaymentsId[paymentSystemId]) {
        groupedByPaymentsId[paymentSystemId] = [];
      }
      const copy = {...bonus};
      delete copy?.payments;
      groupedByPaymentsId[paymentSystemId].push(copy);
    });
  });

  return groupedByPaymentsId;
};
