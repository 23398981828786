import React from 'react';
import PropTypes from 'prop-types';

function Tab({
  id, title, children
}) {
  return (
    <div id={id} title={title}>
      {children}
    </div>
  );
}

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Tab;
